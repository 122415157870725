import {
  type CustomCell,
  type CustomRenderer,
  GridCellKind,
  measureTextCached,
  ProvideEditorComponent,
} from "@glideapps/glide-data-grid";
import * as React from "react";
import colors from "tailwindcss/colors";
import { FilterBadgeTag } from "../filter/filterPanel/tags/FilterBadgeTag";
import { Tag } from "@/types/filters";
import { getTailwindColorWithOpacity } from "@/utils/tailwind";
import { getTagTypeDefinition } from "@/utils/constants/tags";
interface TagCellProps {
  readonly kind: "tag-cell";
  readonly tags: Tag[];
}

export type TagCell = CustomCell<TagCellProps>;

const TAG_HEIGHT = 24;
const TAG_PADDING = 8;
const BORDER_RADIUS = 4;

const TagEditor: ProvideEditorComponent<TagCell> = (props) => {
  const { value, onFinishedEditing } = props;
  const { tags } = value.data;

  // Add a useEffect to handle clicking outside
  React.useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      if (!target.closest(".tag-editor-overlay")) {
        onFinishedEditing();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [onFinishedEditing]);

  return (
    <div
      className="gdg-style gdg-readonly gdg-pad relative flex h-fit max-h-48 w-56 flex-wrap gap-2"
      data-targetx={300}
      data-targety={222}
    >
      {tags.map((tag, index) => {
        return (
          <FilterBadgeTag
            key={index}
            tag={tag}
            displayOnly={true}
            showIcon={false}
          />
        );
      })}
    </div>
  );
};

const TagCell: CustomRenderer<TagCell> = {
  kind: GridCellKind.Custom,
  isMatch: (c): c is TagCell => (c.data as any).kind === "tag-cell",
  draw: (args, cell) => {
    const { ctx, theme, rect } = args;
    const { tags } = cell.data;

    ctx.save();
    ctx.font = `12px ${theme.fontFamily}`;

    let currentX = rect.x + theme.cellHorizontalPadding;
    const centerY = rect.y + rect.height / 2;

    tags.forEach((tag) => {
      const tagTypeDefinition = getTagTypeDefinition(tag.tag_type);
      const baseColor = tagTypeDefinition.baseColor;
      const color = colors[baseColor][200];
      const labelWidth = measureTextCached(
        tag.value,
        ctx,
        `12px ${theme.fontFamily}`,
      ).width;

      const totalContentWidth = labelWidth;
      const tagWidth = totalContentWidth + TAG_PADDING * 2;

      ctx.beginPath();
      ctx.strokeStyle = color
        ? getTailwindColorWithOpacity(baseColor, "400", 0.2)
        : getTailwindColorWithOpacity("neutral", "400", 0.2);
      ctx.lineWidth = 1;
      ctx.roundRect(
        currentX,
        centerY - TAG_HEIGHT / 2,
        tagWidth,
        TAG_HEIGHT,
        BORDER_RADIUS,
      );
      ctx.stroke();

      const elementX = currentX + TAG_PADDING;

      ctx.fillStyle = color ? colors[baseColor][200] : theme.textDark;
      ctx.textBaseline = "middle";
      ctx.fillText(tag.value, elementX, centerY);

      currentX += tagWidth + theme.cellHorizontalPadding;
    });

    ctx.restore();
    return true;
  },
  provideEditor: () => TagEditor,
  onPaste: (_, d) => d,
};

export default TagCell;
