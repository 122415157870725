import { useEffect, useState } from "react";

export type OperatingSystem = "macOS" | "windows" | "linux" | "unknown";

const guessClientOs = (): OperatingSystem => {
  let os: OperatingSystem = "unknown";
  if (navigator.userAgent.indexOf("Win") != -1) os = "windows";
  if (navigator.userAgent.indexOf("Mac") != -1) os = "macOS";
  if (
    navigator.userAgent.indexOf("X11") != -1 ||
    navigator.userAgent.indexOf("Linux") != -1
  )
    os = "linux";

  return os;
};
const useClientOs = (): OperatingSystem => {
  const [clientOs, setClientOs] = useState<OperatingSystem>("unknown");
  const clientOsGuess = guessClientOs();

  useEffect(() => {
    // Use the guessed OS directly since we're in a pure web browser environment
    setClientOs(clientOsGuess);
  }, [clientOsGuess]);

  if (clientOs == "unknown" && clientOsGuess != "unknown") return clientOsGuess;
  return clientOs;
};

export default useClientOs;
