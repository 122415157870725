import React from "react";
import useClientOs from "../utils/useOperatingSystem";

interface KeyboardShortcutItemProps {
  keys: string[];
}
const KeyboardShortcutItem: React.FC<KeyboardShortcutItemProps> = ({
  keys,
}) => {
  const keyboardItemClass = `flex-1 px-1 py-0.5 text-xs font-sans text-gray-600 bg-gray-200 border border-gray-200 rounded-md`;
  const clientOs = useClientOs();

  const renderKey = (key: string) => {
    // TODO: make it so that width is the same no matter what the key is. Currently it's wider for "cmd" than for others
    if (key == "shift") {
      return <kbd className={keyboardItemClass}>⇧</kbd>;
    }
    if (key === "cmd") {
      return clientOs === "macOS" ? (
        <kbd className={keyboardItemClass}>⌘</kbd>
      ) : (
        <kbd className={keyboardItemClass}>Ctrl</kbd>
      );
    }

    return <kbd className={keyboardItemClass}>{key.toUpperCase()}</kbd>;
  };

  return (
    <div className="flex space-x-[0.125rem] ">
      {keys.map((key, index) => (
        <div key={index} className="flex-initial">
          {renderKey(key)}
        </div>
      ))}
    </div>
  );
};

export default KeyboardShortcutItem;
