import { forwardRef } from "react";

const WindowHandleLeftIcon = forwardRef<
  SVGSVGElement,
  React.SVGAttributes<SVGElement>
>((props, forwardedRef) => (
  <svg viewBox="0 0 4 10" fill="none" {...props} ref={forwardedRef}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.9646 0H2.9646V3.07502L0.0785203 5L2.96875 6.92V10H3.96875V6.4L1.85898 5L3.9646 3.59782V0Z"
      fill="currentColor"
    />
    <path d="M1.85937 5.00007L3.96875 3.60007L3.96875 6.40015L1.85937 5.00007Z" />
  </svg>
));

export default WindowHandleLeftIcon;
