import OmniSearchCardSectionEmptyState from "@components/omniSearch/cardModules/OmniSearchCardSectionEmptyState";

interface OmniSearchCardTranscriptProps {
  text?: string;
  speakerId?: string;
  speakerImgSrc?: string;
  speakerName?: string;
}

const OmniSearchCardTranscript = ({
  text,
  speakerId,
  speakerImgSrc,
  speakerName,
}: OmniSearchCardTranscriptProps) => {
  if (!text) {
    return <OmniSearchCardSectionEmptyState message="No transcript" />;
  }

  return (
    <div className="flex flex-col gap-2 text-xs">
      {speakerId && speakerName && (
        <div
          key={speakerId}
          className="flex h-2 cursor-default select-none items-center gap-1"
        >
          <div className="flex h-3 w-3 items-center justify-center overflow-hidden rounded-full">
            {speakerImgSrc ? (
              <img
                src={speakerImgSrc}
                width={16}
                height={16}
                alt={`Speaker ${speakerId}`}
                className="object-cover"
              />
            ) : (
              <div className="h-3 w-3 rounded-full bg-neutral-700" />
            )}
          </div>
          <p className="truncate text-ellipsis font-medium text-gray-600">
            {speakerName}
          </p>
        </div>
      )}
      <p
        className={`font-light text-neutral-400 ${speakerId ? "line-clamp-2" : "line-clamp-3"}`}
      >
        {text}
      </p>
    </div>
  );
};

export default OmniSearchCardTranscript;
