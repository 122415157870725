import React from "react";
import { Select, SelectContent, SelectItem, SelectTrigger } from "@kino/ui";
import { components } from "@/openapi-bindings/v1";
import { aggregationKeyToLabel } from "@/utils/search";
import {
  AggregationKey,
  useSearchDisplayStore,
} from "@/store/search/searchDisplayStore";

// TODO: Support more aggregation keys
const supportedAggregationKeys: AggregationKey[] = [
  "none",
  "by_video",
  // "by_user_defined",
  // "by_people",
];

export const GroupBySelect = () => {
  const { aggregationKey, setAggregationKey } = useSearchDisplayStore();

  const handleValueChange = (newValue: string) => {
    setAggregationKey(newValue as AggregationKey);
  };

  return (
    <Select value={aggregationKey} onValueChange={handleValueChange}>
      <SelectTrigger className="h-7 w-32">
        {aggregationKey === "none"
          ? "None"
          : aggregationKeyToLabel(
              aggregationKey as keyof components["schemas"]["Aggregations"],
            )}
      </SelectTrigger>
      <SelectContent>
        {supportedAggregationKeys.map((option) => (
          <SelectItem key={option} value={option}>
            {option === "none"
              ? "None"
              : aggregationKeyToLabel(
                  option as keyof components["schemas"]["Aggregations"],
                )}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};

export default GroupBySelect;
