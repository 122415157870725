import { cn } from "../../utils/cn";
import { Thumbnail, ThumbnailProps } from "@vidstack/react";

export const Thumbnails = ({ src, time = 0, ...props }: ThumbnailProps) => (
  <Thumbnail.Root
    src={src}
    time={time}
    className={cn(
      "h-[var(--thumbnail-height)] max-h-[160px] min-h-[80px] w-[var(--thumbnail-width)] min-w-[120px] max-w-[180px] overflow-hidden border border-white bg-black data-[hidden]:hidden",
    )}
    {...props}
  >
    <Thumbnail.Img />
  </Thumbnail.Root>
);
