import { useMediaState } from "@vidstack/react";

export const usePlayerIsReady = (): boolean => {
  const intrinsicDuration = useMediaState("intrinsicDuration");

  return intrinsicDuration > 0;
};

export const WaitForPlayerReady = ({
  placeholder = null,
  children,
}: React.PropsWithChildren<{ placeholder?: React.ReactNode }>) => {
  const isPlayerReady = usePlayerIsReady();

  return isPlayerReady ? children : placeholder;
};
