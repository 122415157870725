import { components } from "@/openapi-bindings/v2";

type OriginalSegment = components["schemas"]["Segment"];

export interface ProcessedSegment extends OriginalSegment {
  isFiller: boolean;
}

function processSegmentsWithGaps(
  segments: OriginalSegment[],
  duration?: number,
): ProcessedSegment[] {
  if (!duration) {
    return segments.map((segment) => ({
      ...segment,
      isFiller: false,
    }));
  }

  const processedSegments: ProcessedSegment[] = [];

  segments.forEach((segment, index) => {
    // Add filler segment if there's a gap before the current segment
    if (index === 0 && segment.start > 0) {
      processedSegments.push({
        ...segment,
        id: `filler-${segment.id}-start`,
        start: 0,
        end: segment.start,
        text: "...",
        isFiller: true,
      });
    } else if (index > 0) {
      const prevSegment = segments[index - 1];
      if (prevSegment && segment.start > prevSegment.end) {
        processedSegments.push({
          ...segment,
          id: `filler-${prevSegment.id}-${segment.id}`,
          start: prevSegment.end,
          end: segment.start,
          text: "...",
          isFiller: true,
        });
      }
    }

    processedSegments.push({
      ...segment,
      isFiller: false,
    });
  });

  const lastSegment = segments[segments.length - 1];
  if (lastSegment && lastSegment.end < duration) {
    processedSegments.push({
      ...lastSegment,
      id: `filler-${lastSegment.id}-end`,
      start: lastSegment.end,
      end: duration,
      text: "...",
      isFiller: true,
    });
  }

  return processedSegments;
}

export default processSegmentsWithGaps;
