import React from "react";
import {
  NavigationMenu,
  NavigationMenuList,
  NavigationMenuItem,
  cn,
  ClickableIcon,
  Separator,
} from "@kino/ui";
import { useLayoutStore } from "@/store/layoutStore";
import { PanelRightClose, PanelRightOpen } from "lucide-react";
import { OrganizationSwitcher } from "@/components/auth/OrganizationSwitcher";
import { useOrganization, useUser } from "@clerk/clerk-react";
import AVBImportPopover from "@/components/popover/AVBImportPopover";
import SeasonStatsPopover from "@/components/popover/SeasonStatsPopover";
import { SquareCode } from "lucide-react";
import { Tab } from "@/components/shared/Tab";
import ActiveServerPopover from "@/components/popover/ActiveServerPopover";
interface NavItemProps {
  icon: React.ReactNode;
  label?: string;
  value: string;
  onClick?: () => void;
  isActive?: boolean;
}

export const NavItem: React.FC<NavItemProps> = ({
  icon,
  label,
  onClick,
  isActive = false,
}) => {
  return (
    <NavigationMenuItem
      onClick={onClick}
      className={cn(
        "group flex h-5 cursor-pointer items-center justify-start gap-1 text-xs transition-all",
        isActive ? "text-white" : "text-neutral-600 hover:text-white",
      )}
    >
      <div
        className={cn(
          "flex h-4 w-4 items-center",
          isActive
            ? "text-indigo-500"
            : "text-neutral-500 group-hover:text-indigo-500",
        )}
      >
        {icon}
      </div>
      {label && <div>{label}</div>}
    </NavigationMenuItem>
  );
};

const Navbar: React.FC = () => {
  const { isInspectorOpen, isDebuggerPanelOpen, set } = useLayoutStore();
  const toggleDebuggerPanel = () =>
    set("isDebuggerPanelOpen", !isDebuggerPanelOpen);
  const { organization } = useOrganization();
  const { user } = useUser();

  return (
    <nav className="flex w-full border-b-2 border-black px-2 py-1">
      <NavigationMenu className="flex w-full max-w-none items-center justify-between">
        <NavigationMenuList className="flex items-center justify-start gap-1">
          <OrganizationSwitcher isMinified={true} />
          <ActiveServerPopover />
        </NavigationMenuList>
        <div className="flex items-center justify-end gap-2">
          <div className="flex items-center gap-1">
            <AVBImportPopover />
            <SeasonStatsPopover />
            {(import.meta.env.DEV ||
              import.meta.env.VITE_VERCEL_ENV === "preview" ||
              organization?.slug === "kino-ai" ||
              user?.emailAddresses.some((email) =>
                email.emailAddress.toLowerCase().endsWith("@trykino.com"),
              )) && (
              <ClickableIcon
                Icon={SquareCode}
                name="debug"
                tooltip="Debug"
                onClick={toggleDebuggerPanel}
                className={cn(
                  "rounded-md p-1 text-neutral-500 hover:bg-neutral-800",
                  isDebuggerPanelOpen ? "bg-neutral-800" : "",
                )}
              />
            )}
          </div>
          <Separator orientation="vertical" className="h-3 bg-neutral-700" />
          <NavigationMenuItem asChild>
            <Tab
              icon={isInspectorOpen ? <PanelRightClose /> : <PanelRightOpen />}
              value="inspector"
              isActive={isInspectorOpen}
              onClick={() => set("isInspectorOpen", !isInspectorOpen)}
            />
          </NavigationMenuItem>
        </div>
      </NavigationMenu>
    </nav>
  );
};

export default Navbar;
