import {
  BaseFilterStateKeys,
  FilterToUrlParamMap,
  SearchResultsFilterState,
} from "@/types/filters";
import { useSearchParams } from "react-router-dom";
import { EMPTY_FILTER_STATE } from "@/types/filters";
import { components } from "@/openapi-bindings/v2";

export type SearchState = {
  search: string;
  filterState: SearchResultsFilterState;
  searchMode: components["schemas"]["SearchMode"];
};

export const useURLSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const encodeSearchToURLSearchParams = (
    searchText: string | undefined,
    filterState: Partial<SearchResultsFilterState>,
    searchMode: components["schemas"]["SearchMode"] = "omni",
  ) => {
    const params = new URLSearchParams();

    if (searchText) {
      params.set("search", searchText);
    }

    params.set("tab_type", searchMode);

    // Handle array-based filters using the param map
    Object.entries(filterState).forEach(([key, value]) => {
      const urlParamKey = FilterToUrlParamMap[key as BaseFilterStateKeys];

      if (!value) return;

      if (
        key === FilterToUrlParamMap.timecode_range &&
        value.length === 2 &&
        (value[0] || value[1])
      ) {
        params.set(urlParamKey, `${value[0]},${value[1]}`);
      } else if (Array.isArray(value) && value.length > 0) {
        params.set(urlParamKey, value.join(","));
      }
    });

    return params;
  };

  const decodeURLSearchParams = (params: URLSearchParams): SearchState => {
    const searchMode = (params.get("tab_type") ||
      "omni") as components["schemas"]["SearchMode"];
    const filterState = { ...EMPTY_FILTER_STATE };

    // Create a reverse mapping for easier lookup
    const reverseParamMap = Object.entries(FilterToUrlParamMap).reduce<
      Record<string, BaseFilterStateKeys>
    >((acc, [key, value]) => {
      acc[value] = key as BaseFilterStateKeys;
      return acc;
    }, {});

    // Process each parameter using the reverse map
    params.forEach((value, paramKey) => {
      const filterKey = reverseParamMap[paramKey];

      if (filterKey) {
        if (filterKey === "timecode_range") {
          filterState[filterKey] = value.split(",") as [string, string];
        } else {
          filterState[filterKey] = value.split(",").filter(Boolean);
        }
      }
    });

    return {
      search: params.get("search") || "",
      searchMode,
      filterState,
    };
  };

  const updateFilters = (
    searchText: string | undefined,
    filters: Partial<SearchResultsFilterState>,
    searchMode?: components["schemas"]["SearchMode"],
  ) => {
    setSearchParams(
      encodeSearchToURLSearchParams(searchText, filters, searchMode),
      {
        replace: true,
      },
    );
  };

  const getFilters = (): SearchState => {
    return decodeURLSearchParams(searchParams);
  };

  return {
    updateFilters,
    getFilters,
    encodeSearchToURLSearchParams,
    decodeURLSearchParams,
  };
};
