import { components } from "@/openapi-bindings/v2";
import { create } from "zustand";

export type SelectedMediaMoment = {
  moment: components["schemas"]["Moment"];
  searchMode: components["schemas"]["SearchMode"];
};

export type SelectedMediaItem = {
  mediaItem: components["schemas"]["MediaItem"];
  activeInspectorMoment?: SelectedMediaMoment;
  id: string; // combined id of mediaItem and moment
};

interface SelectedMediaStoreState {
  currentSelection: SelectedMediaItem[] | undefined;
  setCurrentSelection: (media: SelectedMediaItem[]) => void;
}

const useSelectedMediaStore = create<SelectedMediaStoreState>((set) => ({
  currentSelection: undefined,
  setCurrentSelection: (media) => set({ currentSelection: media }),
}));

export { useSelectedMediaStore };
