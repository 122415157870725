import { useSearchDisplayStore } from "@/store/search/searchDisplayStore";
import { ViewType } from "@/types";
import { Tabs, TabsList, TabsTrigger } from "@kino/ui";
import { GridIcon, ListBulletIcon } from "@radix-ui/react-icons";

const ViewTypeToggles = () => {
  const { viewType, setViewType } = useSearchDisplayStore();
  const handleOnValueChange = (value: string) => {
    setViewType(value as ViewType);
  };
  return (
    <Tabs value={viewType} onValueChange={handleOnValueChange}>
      <TabsList className="flex gap-1">
        <TabsTrigger
          value={ViewType.Grid}
          className="flex items-center gap-1 rounded p-1 px-2 text-xs data-[state=active]:bg-neutral-800"
        >
          <GridIcon className="h-4 w-4" />
          Grid
        </TabsTrigger>
        <TabsTrigger
          disabled
          value={ViewType.List}
          className="flex items-center gap-1 rounded p-1 px-2 text-xs data-[state=active]:bg-neutral-800"
        >
          <ListBulletIcon className="h-4 w-4" />
          List
        </TabsTrigger>
      </TabsList>
    </Tabs>
  );
};

export default ViewTypeToggles;
