import type { FuzzyFilenameParams } from "@/types/oldBindings";
import {
  type ArrayFilterKeys,
  arrayFilterKeys,
  type DateRange,
} from "@/types/filters";
import { components } from "@/openapi-bindings/v2";

export function isStringArray(value: unknown): value is string[] {
  return (
    Array.isArray(value) && value.every((item) => typeof item === "string")
  );
}

export function isDateRange(value: unknown): value is DateRange {
  return (
    Array.isArray(value) &&
    value.length === 2 &&
    value.every((item) => typeof item === "number")
  );
}

export function isBoolean(value: unknown): value is boolean {
  return typeof value === "boolean";
}

export function isFuzzyFilenameParams(
  value: unknown,
): value is FuzzyFilenameParams {
  if (typeof value === "object" && value !== null) {
    return "query" in value && "use_regex" in value;
  }
  return false;
}

export function isTags(value: unknown): value is components["schemas"]["Tag"] {
  if (typeof value === "object" && value !== null) {
    return "tag_type" in value && "name" in value;
  }
  return false;
}

//eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isArrayFilterKey = (key: any): key is ArrayFilterKeys => {
  return arrayFilterKeys.includes(key);
};
