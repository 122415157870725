import React from 'react';
import { cn } from "@kino/ui";

interface IconWithIndicatorProps {
  icon: React.ElementType;
  showIndicator?: boolean;
  indicatorColor?: string;
  className?: string;
}

const IconWithIndicator: React.FC<IconWithIndicatorProps> = ({
  icon: Icon,
  showIndicator = false,
  indicatorColor = 'bg-orange-500',
  className
}) => {
  return (
    <div className={cn("relative", className)}>
      <Icon className="h-3.5 w-3.5 text-neutral-300" />
      {showIndicator && (
        <div className={cn("absolute left-[9px] top-[-1px] h-1.5 w-1.5 rounded-full border border-neutral-900", indicatorColor)}></div>
      )}
    </div>
  );
};

export default IconWithIndicator;