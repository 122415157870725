import React from "react";
import { components } from "@/openapi-bindings/v2";
import ResultsSection from "@/components/omniSearch/ResultsSection";
import OmniSearchCard from "@/components/omniSearch/OmniSearchCard";
import {
  getHitsBySearchMode,
  getHydratedBucketsByAggregationKey,
  getMomentsBySearchMode,
} from "@/utils/search";
import VideoGroupHeader from "./aggregation/VideoGroupHeader";
import TagGroupHeader from "./aggregation/TagGroupHeader";
import {
  AggregationKey,
  useSearchDisplayStore,
} from "@/store/search/searchDisplayStore";
import { useActiveBucketStore } from "@/store/search/activeBucketStore";
import ResultsCarousel from "../ResultsCarousel";
import ResultsGrid from "../ResultsGrid";
import PeopleGroupHeader from "./aggregation/PeopleGroupHeader";
import useGetSearchMode from "@/hooks/useGetSearchMode";
import { useOmniSearch } from "@/hooks/useOmniSearch";
import { getInspectorItemId } from "@/utils/stringUtils";

const getGroupHeader = (
  searchResponse: components["schemas"]["SearchResponse"],
  searchMode: components["schemas"]["SearchMode"],
  aggregationKey: AggregationKey,
  bucketId: string,
) => {
  const hydratedBuckets = getHydratedBucketsByAggregationKey(
    searchResponse,
    aggregationKey,
    searchMode,
  );
  switch (aggregationKey) {
    case "by_video": {
      const hydratedBucket = hydratedBuckets?.find(
        (bucket) => bucket.key === bucketId,
      );
      if (hydratedBucket) {
        return <VideoGroupHeader hydratedBucket={hydratedBucket} />;
      }
      break;
    }
    // case "by_user_defined": {
    //   return (
    //     <TagGroupHeader searchResults={searchResults} bucketId={bucketId} />
    //   );
    // }
    // case "by_people": {
    //   return (
    //     <PeopleGroupHeader searchResults={searchResults} bucketId={bucketId} />
    //   );
    // }
    default:
      return null;
  }
};

interface GroupedBucketResultViewProps {
  aggregationKey: AggregationKey;
}

const GroupedBucketResultView = ({
  aggregationKey,
}: GroupedBucketResultViewProps) => {
  const { searchResponse } = useOmniSearch();
  const tabType = useGetSearchMode();

  const { activeMarkerId, setActiveMarkerId } = useActiveBucketStore();
  if (!searchResponse) return null;
  const buckets = getHydratedBucketsByAggregationKey(
    searchResponse,
    aggregationKey,
    tabType,
  );

  return (
    <div className="flex w-full flex-col px-4 *:my-2 first:*:mt-4 last:*:mb-4">
      {buckets?.map((bucket) => {
        return (
          <ResultsSection
            key={bucket.key}
            header={getGroupHeader(
              searchResponse,
              tabType,
              aggregationKey,
              bucket.key,
            )}
            className="mx-0 w-full max-w-[1500px] rounded border p-4"
          >
            <ResultsCarousel id={bucket.key}>
              {bucket.moments
                .sort((a, b) => (a.start ?? 0) - (b.end ?? 0))
                .map((moment) => {
                  const inspectorItemId = getInspectorItemId(
                    moment.media_item_id,
                    moment.id,
                  );
                  return (
                    <OmniSearchCard
                      key={moment.id}
                      moment={moment}
                      isHighlighted={activeMarkerId === inspectorItemId}
                      onMouseEnter={() => setActiveMarkerId(inspectorItemId)}
                      onMouseLeave={() => setActiveMarkerId(null)}
                      searchResponse={searchResponse}
                    />
                  );
                })}
            </ResultsCarousel>
          </ResultsSection>
        );
      })}
    </div>
  );
};

interface BucketResultViewProps {
  searchMode: components["schemas"]["SearchMode"];
}

const BucketResultView = ({ searchMode }: BucketResultViewProps) => {
  const { aggregationKey } = useSearchDisplayStore();
  const { searchResponse } = useOmniSearch();
  if (!searchResponse) return null;
  const moments = getMomentsBySearchMode(searchResponse, searchMode);

  return (
    <>
      {aggregationKey === "none" && (
        <ResultsSection>
          <ResultsGrid>
            {moments?.map((moment) => (
              <OmniSearchCard
                key={moment.id}
                moment={moment}
                searchResponse={searchResponse}
              />
            ))}
          </ResultsGrid>
        </ResultsSection>
      )}
      {aggregationKey !== "none" && (
        <GroupedBucketResultView aggregationKey={aggregationKey} />
      )}
    </>
  );
};

export default BucketResultView;
