/**
 * The narrowest that the player can be "zoomed" in
 */
export const MIN_WINDOW_SECONDS = 1;

/**
 * Number of seconds added to either side of a moment
 * in order to give some lead-in/out during playback
 */
export const MOMENT_BUFFER_SECONDS = 1;
