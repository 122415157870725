import { useState, useEffect } from "react";
import { useActiveServerStore } from "@/store/activeServerStore";

export const usePythonServerStatus = () => {
  const [isPythonServerOnline, setIsPythonServerOnline] = useState<
    boolean | null
  >(null);
  const { activeServerUrl, serverOverrideUrl } = useActiveServerStore();

  useEffect(() => {
    const checkPythonServer = async () => {
      const serverUrl = serverOverrideUrl || activeServerUrl;
      if (!serverUrl) {
        setIsPythonServerOnline(null);
        return;
      }

      try {
        const response = await fetch(`${serverUrl}ping`, {
          method: "GET",
        });
        if (response.ok) {
          const data = await response.json();
          setIsPythonServerOnline(data.message === "pong");
        } else {
          setIsPythonServerOnline(false);
        }
      } catch (error) {
        console.error("Error checking Python server:", error);
        setIsPythonServerOnline(false);
      }
    };

    checkPythonServer();
    const interval = setInterval(checkPythonServer, 30000);
    return () => clearInterval(interval);
  }, [serverOverrideUrl, activeServerUrl]);

  return isPythonServerOnline;
};
