import React, { ChangeEvent, useRef, useCallback } from "react";
import { Button, ClickableIcon, cn, ScrollArea } from "@kino/ui";
import { PlusIcon } from "@radix-ui/react-icons";
import { useAVBFileStore } from "@/store/avbFileStore";
import { getTimeAgo } from "@/utils/time";
import { formatStringPlurality } from "@/utils/stringUtils";
import DragDropWrapper from "./DragDropWrapper";
import {
  useAVBUpload,
  getStatusIcon,
  getStatusColor,
} from "@/hooks/useAVBFileImport";

const AVBImport: React.FC = () => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { importedAVBFiles } = useAVBFileStore();

  const { handleUpload } = useAVBUpload();

  const handleFileInput = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const selectedFiles = Array.from(e.target.files).filter((file) =>
          file.name.endsWith(".avb"),
        );
        handleUpload(selectedFiles);
      }
    },
    [handleUpload],
  );

  const handleBrowseClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    } else {
      console.error("File input element not found");
    }
  };

  return (
    <div className="flex h-full w-full flex-col divide-y border-b">
      <div className="flex w-full flex-row items-center justify-between gap-2 p-2">
        <div className="flex flex-row items-center gap-1">
          <div className="rounded-sm bg-neutral-800 px-1 text-xs text-neutral-500">
            AVB
          </div>
          <div className="text-xs text-neutral-500"> Imports</div>
        </div>
        <ClickableIcon
          Icon={PlusIcon}
          name="Add File(s)"
          onClick={handleBrowseClick}
          className="text-neutral-600 hover:text-neutral-500"
          tooltip="Add File(s)"
        />
      </div>
      <DragDropWrapper onFileDrop={handleUpload}>
        <div className="flex h-full w-full flex-col gap-2 overflow-hidden">
          {importedAVBFiles.length === 0 && (
            <div className="flex h-full w-full flex-col items-center justify-center gap-2 px-2">
              <p className="text-center text-xs text-neutral-500">
                Drag and drop your AVB files here
              </p>
              <span className="text-xs text-neutral-600">or</span>
              <Button onClick={handleBrowseClick} variant="kino" size="sm">
                Browse
              </Button>
            </div>
          )}
          {importedAVBFiles.length > 0 && (
            <ScrollArea>
              <div className="p-2 text-xs text-neutral-700">
                {importedAVBFiles.length}{" "}
                {formatStringPlurality(
                  importedAVBFiles.length,
                  "file",
                  "files",
                )}{" "}
                imported
              </div>
              <div className="flex flex-col gap-2 px-2">
                {importedAVBFiles
                  .slice()
                  .reverse()
                  .map((file) => (
                    <div
                      key={file.id}
                      className={cn(
                        "neutral-800 flex w-full cursor-default items-center justify-between gap-2 rounded-md border p-2 text-xs text-neutral-400",
                      )}
                    >
                      <div className="flex flex-row items-center gap-2 overflow-hidden">
                        <div className={cn(getStatusColor(file.status))}>
                          {getStatusIcon(file.status)}
                        </div>
                        <span className="flex-1 truncate text-ellipsis whitespace-nowrap">
                          {file.filePath}
                        </span>
                      </div>
                      <span className="text-neutral-500">
                        {getTimeAgo(new Date(file.date), true)}
                      </span>
                    </div>
                  ))}
              </div>
            </ScrollArea>
          )}
          <input
            ref={fileInputRef}
            type="file"
            onChange={handleFileInput}
            className="hidden"
            multiple
            accept=".avb"
          />
        </div>
      </DragDropWrapper>
    </div>
  );
};

export default AVBImport;
