import React, { useRef, useEffect } from "react";
import { useCarouselStore } from "@/store/search/carouselStore";

interface ResultsCarouselProps {
  children: React.ReactNode;
  id: string; // Unique identifier for this carousel instance
}

const ResultsCarousel: React.FC<ResultsCarouselProps> = ({ children, id }) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const { addCarouselRef, removeCarouselRef } = useCarouselStore();

  useEffect(() => {
    if (scrollContainerRef.current) {
      addCarouselRef(scrollContainerRef, id);

      const handleScroll = () => {
        // This will cause a re-render, which will re-calculate isItemInViewport
        addCarouselRef(scrollContainerRef, id);
      };

      scrollContainerRef.current.addEventListener("scroll", handleScroll);

      return () => {
        scrollContainerRef.current?.removeEventListener("scroll", handleScroll);
        removeCarouselRef(id);
      };
    }
  }, [addCarouselRef, removeCarouselRef, id]);

  return (
    <div
      ref={scrollContainerRef}
      className="no-scrollbar flex w-full overflow-x-scroll"
    >
      <div className="flex w-full gap-4">
        {React.Children.map(children, (child) => (
          <div className="w-72 flex-shrink-0">{child}</div>
        ))}
      </div>
    </div>
  );
};

export default ResultsCarousel;
