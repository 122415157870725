import {
  createContext,
  PropsWithChildren,
  useContext,
  useState,
  useCallback,
} from "react";

export enum PlayerMomentType {
  Video = 1,
  Sound,
  Image,
  OCR,
  Transcript,
  Clip,
  Visual,
}

export interface PlayerMoment {
  startTime: number;
  endTime: number;
  type: PlayerMomentType;
}

export interface IPlayerMomentContext {
  playerMoments: PlayerMoment[];
  selectedMoment: PlayerMoment | undefined;
  registerMoment: (moment: PlayerMoment) => void;
  unregisterMoment: (moment: PlayerMoment) => void;
}

const PlayerMomentContext = createContext<IPlayerMomentContext | undefined>(
  undefined,
);

// interface PlayerMomentProps extends PropsWithChildren {}

export const PlayerMomentProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [playerMoments, setPlayerMoments] = useState<PlayerMoment[]>([]);

  const registerMoment = useCallback(
    (moment: PlayerMoment) => setPlayerMoments((prev) => [...prev, moment]),
    [],
  );
  const unregisterMoment = useCallback(
    (moment: PlayerMoment) =>
      setPlayerMoments((prev) => {
        return prev.filter(
          (m) =>
            !(
              m.startTime === moment.startTime &&
              m.endTime === moment.endTime &&
              m.type === moment.type
            ),
        );
      }),
    [],
  );

  const value = {
    playerMoments,
    selectedMoment: undefined,
    registerMoment,
    unregisterMoment,
  };

  return (
    <PlayerMomentContext.Provider value={value}>
      {children}
    </PlayerMomentContext.Provider>
  );
};

export const useRegisterPlayerMoment = (): IPlayerMomentContext => {
  const context = useContext(PlayerMomentContext);

  if (!context) {
    throw new Error(
      "useRegisterPlayerMoment must be used within a PlayerMomentContext",
    );
  }

  return context;
};

export const usePlayerMoments = (): PlayerMoment[] => {
  const context = useContext(PlayerMomentContext);

  if (!context) {
    throw new Error(
      "usePlayerZoomController must be used within a PlayerMomentContext",
    );
  }

  return context.playerMoments;
};
