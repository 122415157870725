import { create } from "zustand";

interface CarouselRef {
  ref: React.RefObject<HTMLDivElement>;
  id: string;
}

interface CarouselState {
  carouselRefs: CarouselRef[];
  addCarouselRef: (ref: React.RefObject<HTMLDivElement>, id: string) => void;
  removeCarouselRef: (id: string) => void;
  isItemInViewport: (id: string, index: number) => boolean;
  scrollToItem: (id: string, index: number) => void;
}

export const useCarouselStore = create<CarouselState>((set, get) => ({
  carouselRefs: [],
  addCarouselRef: (ref, id) =>
    set((state) => ({
      carouselRefs: [
        ...state.carouselRefs.filter((c) => c.id !== id),
        { ref, id },
      ],
    })),
  removeCarouselRef: (id) =>
    set((state) => ({
      carouselRefs: state.carouselRefs.filter((c) => c.id !== id),
    })),
  isItemInViewport: (id, index) => {
    const carouselRef = get().carouselRefs.find((c) => c.id === id)?.ref;
    if (!carouselRef || !carouselRef.current) return false;

    const container = carouselRef.current;
    const item = container?.children[0]?.children[index] as HTMLElement;
    if (!item) return false;

    const containerRect = container.getBoundingClientRect();
    const itemRect = item.getBoundingClientRect();

    const containerScrollLeft = container.scrollLeft;
    const containerVisibleWidth = container.clientWidth;

    const itemLeftRelativeToContainer =
      itemRect.left - containerRect.left + containerScrollLeft;
    const itemRightRelativeToContainer =
      itemRect.right - containerRect.left + containerScrollLeft;

    return (
      itemLeftRelativeToContainer >= containerScrollLeft &&
      itemRightRelativeToContainer <=
        containerScrollLeft + containerVisibleWidth
    );
  },
  scrollToItem: (id, index) => {
    const carouselRef = get().carouselRefs.find((c) => c.id === id)?.ref;
    if (!carouselRef || !carouselRef.current) return;

    const container = carouselRef.current;
    const item = container?.children[0]?.children[index] as HTMLElement;
    if (!item) return;

    const containerVisibleWidth = container.clientWidth;
    const itemWidth = item.offsetWidth;

    let targetScrollLeft;

    if (itemWidth <= containerVisibleWidth) {
      // If the item fits entirely in the viewport, center it
      targetScrollLeft =
        item.offsetLeft - (containerVisibleWidth - itemWidth) / 2;
    } else {
      // If the item is wider than the viewport, align its left edge with the viewport's left edge
      targetScrollLeft = item.offsetLeft;
    }

    // Ensure we don't scroll past the end of the content
    const maxScrollLeft = container.scrollWidth - containerVisibleWidth;
    targetScrollLeft = Math.min(targetScrollLeft, maxScrollLeft);

    container.scrollTo({
      left: targetScrollLeft,
      behavior: "smooth",
    });
  },
}));
