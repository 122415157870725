import { create } from "zustand";
import {
  type BaseFilters,
  createFilteredOptions,
  EMPTY_BASE_FILTERS,
  type SearchResultsFilters,
} from "@/types/filters";

interface FilterOptionsStore {
  filterOptions: SearchResultsFilters;
  setFilterOptions: (options: SearchResultsFilters) => void;
  updateBaseFilterOptionKey: <K extends keyof BaseFilters>(
    key: K,
    value: BaseFilters[K],
  ) => void;
}

export const useFilterOptionsStore = create<FilterOptionsStore>((set) => ({
  filterOptions: createFilteredOptions(EMPTY_BASE_FILTERS, []),
  setFilterOptions: (options) =>
    set((currentState) => ({
      ...currentState,
      filterOptions: { ...options },
    })),
  updateBaseFilterOptionKey: <K extends keyof BaseFilters>(
    key: K,
    value: BaseFilters[K],
  ) =>
    set((currentState) => ({
      ...currentState,
      filterOptions: { ...currentState.filterOptions, [key]: value },
    })),
}));
