import React, { useState } from "react";
import { TextWithTooltip } from "@kino/ui";
import { toast } from "sonner";
import { CopyIcon } from "@radix-ui/react-icons";

interface AdvancedPropertyProps {
  label: string;
  value?: string | number | null;
}

const AdvancedProperty: React.FC<AdvancedPropertyProps> = ({
  label,
  value,
}) => {
  const [isHover, setIsHover] = useState(false);
  const handleCopy = () => {
    if (!value) {
      return;
    }
    navigator.clipboard.writeText(value.toString());
    toast.success(`${label} copied to clipboard`);
  };

  return (
    <div className="flex w-full cursor-default items-start justify-between gap-1">
      <div className="w-1/3">{label}</div>
      <div
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        className="relative flex w-2/3 flex-grow items-center gap-1 overflow-hidden rounded-md bg-neutral-800 px-2 py-1"
      >
        <div className="word-break flex w-full whitespace-break-spaces break-all text-xs text-neutral-500">
          {value ?? <span className="text-neutral-400">-</span>}
        </div>
        {isHover && value !== null && value !== undefined && (
          <div className="absolute right-1 top-1 z-10 flex items-center justify-center rounded-sm bg-neutral-800 p-1">
            <TextWithTooltip tooltipText={"Click to copy"} position="top">
              <CopyIcon
                onClick={handleCopy}
                className="h-3 w-3 cursor-pointer text-neutral-400"
              />
            </TextWithTooltip>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdvancedProperty;
