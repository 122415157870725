import { useMediaRemote, useMediaState } from "@vidstack/react";
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useState,
} from "react";
import { PlayerMoment, PlayerMomentType } from "./PlayerMomentsContext";

export interface IClipMomentContext {
  clipMoment: PlayerMoment;
  hasClipMoment: boolean;
  isClipMomentActive: boolean;
  setClipMomentStart: (time: number) => void;
  setClipMomentEnd: (time: number) => void;
  activateClipMoment: (moment?: PlayerMoment) => void;
  deactivateClipMoment: () => void;
  clearClipMomentStart: () => void;
  clearClipMomentEnd: () => void;
}

const ClipMomentContext = createContext<IClipMomentContext | undefined>(
  undefined,
);

export const ClipMomentProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const remote = useMediaRemote();
  const intrinsicDuration = useMediaState("intrinsicDuration");

  const [clipMomentStart, setClipMomentStart] = useState<number | null>(null);
  const [clipMomentEnd, setClipMomentEnd] = useState<number | null>(null);

  const [isClipActive, setClipIsActive] = useState<boolean>(false);

  return (
    <ClipMomentContext.Provider
      value={{
        clipMoment: {
          startTime: clipMomentStart || -1,
          endTime: clipMomentEnd || Infinity,
          type: PlayerMomentType.Clip,
        },
        hasClipMoment: clipMomentStart !== null || clipMomentEnd !== null,
        isClipMomentActive: isClipActive,
        setClipMomentStart: (number) => {
          if (clipMomentEnd && number > clipMomentEnd) {
            return;
          }

          setClipMomentStart(number);

          if (isClipActive) {
            remote.changeClipStart(number);
          }
        },
        setClipMomentEnd: (number) => {
          if (number < (clipMomentStart || 0)) {
            return;
          }

          setClipMomentEnd(number);

          if (isClipActive) {
            remote.changeClipEnd(number);
          }
        },
        activateClipMoment: (moment?: PlayerMoment) => {
          if (moment) {
            setClipMomentStart(moment.startTime);
            setClipMomentEnd(moment.endTime);
          }

          setClipIsActive(true);
          remote.seek(moment ? moment.startTime : clipMomentStart || 0);
          remote.changeClipStart(
            moment ? moment.startTime : clipMomentStart || 0,
          );
          remote.changeClipEnd(
            moment ? moment.endTime : clipMomentEnd || intrinsicDuration,
          );
        },
        deactivateClipMoment: () => {
          setClipIsActive(false);
          remote.changeClipStart(0);
          remote.changeClipEnd(intrinsicDuration);
        },
        clearClipMomentStart: () => {
          setClipMomentStart(null);
          remote.changeClipStart(0);
          setClipIsActive(isClipActive && Number.isFinite(clipMomentEnd));
        },
        clearClipMomentEnd: () => {
          setClipMomentEnd(Infinity);
          remote.changeClipEnd(intrinsicDuration);
          setClipIsActive(isClipActive && (clipMomentStart ?? -1) > -1);
        },
      }}
    >
      {children}
    </ClipMomentContext.Provider>
  );
};

export const useClipMoment = (): IClipMomentContext => {
  const context = useContext(ClipMomentContext);

  if (!context) {
    throw new Error("useClipMoment must be used within a ClipMomentProvider");
  }

  return context;
};
