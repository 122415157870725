import { Separator } from "@kino/ui";
import { components } from "@/openapi-bindings/v2";
import AdvancedProperty from "./AdvancedProperty";
import { secondsToTimestamp } from "@/utils/time";
import { formatBitRate, formatSize } from "@/utils/ffprobe";
import { getFileName } from "@/utils/pathUtils";

interface BasicPropertiesCardProps {
  mediaItem: components["schemas"]["MediaItem"];
}

const BasicPropertiesCard = ({ mediaItem }: BasicPropertiesCardProps) => {
  const ffprobeData = mediaItem.ffprobe_data;
  const streams = ffprobeData?.streams;
  const streamData = streams?.[0];
  const formatData = ffprobeData?.format;

  return (
    <div className="px-2 text-xs text-neutral-600">
      <div className="flex flex-col gap-2 overflow-hidden">
        <div className="word-break flex w-full whitespace-break-spaces break-all">
          {getFileName(mediaItem.ffprobe_data?.format?.filename ?? "") ?? (
            <span className="text-neutral-400">No file name</span>
          )}
        </div>
        <Separator />
        <AdvancedProperty
          label="File Path"
          value={mediaItem.ffprobe_data?.format?.filename}
        />
        <AdvancedProperty
          label="Duration"
          value={
            formatData?.duration
              ? secondsToTimestamp(Number(formatData.duration))
              : undefined
          }
        />
        <AdvancedProperty
          label="Dimensions"
          value={
            streamData?.width && streamData?.height
              ? `${streamData.width}x${streamData.height}`
              : undefined
          }
        />
        {ffprobeData?.num_audio_streams !== undefined && (
          <AdvancedProperty
            label="Streams"
            value={ffprobeData.num_audio_streams.toString()}
          />
        )}
        {streams?.filter((stream) => stream.codec_type === "video").length !==
          undefined && (
          <AdvancedProperty
            label="Video Streams"
            value={`${ffprobeData.num_video_streams}`}
          />
        )}
        {formatData && (
          <>
            <AdvancedProperty
              label="Size"
              value={
                formatData.size
                  ? formatSize(parseInt(formatData.size))
                  : undefined
              }
            />
            <AdvancedProperty label="size" value={formatData.size} />
          </>
        )}
        {streamData && (
          <>
            <AdvancedProperty
              label="Codec Type"
              value={streamData.codec_type}
            />
            <AdvancedProperty
              label="Bit Rate"
              value={
                streamData.bit_rate
                  ? formatBitRate(parseInt(streamData.bit_rate))
                  : undefined
              }
            />
            <AdvancedProperty
              label="Display Aspect Ratio"
              value={streamData.display_aspect_ratio}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default BasicPropertiesCard;
