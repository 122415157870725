import {
  ClickableIcon,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@kino/ui";
import AVBImport from "../AVBImport";

const AVBIcon = () => (
  <div className="flex h-4 w-5 items-center justify-center rounded-sm border-[1.5px] border-neutral-500 text-[6px] font-semibold text-neutral-500">
    AVB
  </div>
);

const AVBImportPopover = () => {
  return (
    <Popover modal={true}>
      <PopoverTrigger>
        <ClickableIcon
          Icon={AVBIcon}
          name="avb-import"
          tooltip="AVB Imports"
          className="rounded-md p-1 text-neutral-500 hover:bg-neutral-800"
        />
      </PopoverTrigger>
      <PopoverContent className="h-96 w-96 overflow-hidden p-0">
        <AVBImport />
      </PopoverContent>
    </Popover>
  );
};

export default AVBImportPopover;
