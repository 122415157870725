import { ScrollArea } from "@kino/ui";
import BasicPropertiesCard from "../propertiesCard/BasicPropertiesCard";
import { InspectorCollapsiblePanelSection } from "./InspectorCollapsiblePanelSection";
import { components } from "@/openapi-bindings/v2";

interface InspectorBasicPropertiesCollapsibleProps {
  mediaItem: components["schemas"]["MediaItem"];
}

const InspectorBasicPropertiesCollapsible = ({
  mediaItem,
}: InspectorBasicPropertiesCollapsibleProps) => {
  return (
    <InspectorCollapsiblePanelSection
      label="Properties"
      name="properties"
      defaultOpen
    >
      <ScrollArea className="w-full">
        <BasicPropertiesCard mediaItem={mediaItem} />
      </ScrollArea>
    </InspectorCollapsiblePanelSection>
  );
};

export default InspectorBasicPropertiesCollapsible;
