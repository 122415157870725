import { RefObject, useLayoutEffect, useState } from "react";

// https://github.com/radix-ui/primitives/blob/6469d41dcc6e84fe41d70a2703924338e7562dd1/packages/react/use-size/src/useSize.tsx#L6
export const useSize = (elementRef: RefObject<HTMLElement>) => {
  const [size, setSize] = useState<
    { width: number; height: number } | undefined
  >(undefined);

  useLayoutEffect(() => {
    const element = elementRef.current;

    if (!element) {
      return;
    }

    setSize({ width: element.offsetWidth, height: element.offsetHeight });

    const observer = new ResizeObserver((entries) => {
      if (!entries[0]) {
        return;
      }

      const entry = entries[0];

      const borderSizeEntry = entry["borderBoxSize"];
      const borderSize = Array.isArray(borderSizeEntry)
        ? borderSizeEntry[0]
        : borderSizeEntry;

      setSize({
        width: borderSize["inlineSize"],
        height: borderSize["blockSize"],
      });
    });

    observer.observe(element, { box: "border-box" });

    return () => observer.unobserve(element);
  }, [elementRef]);

  return size;
};
