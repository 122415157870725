import { forwardRef } from "react";

const JumpToClipOutIcon = forwardRef<
  SVGSVGElement,
  React.SVGAttributes<SVGElement>
>((props, forwardedRef) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    ref={forwardedRef}
    {...props}
  >
    <path
      d="M28.6875 33.5582L28.6875 24.5317L34.4287 19.7374L28.6877 14.9432L28.6877 6"
      stroke="currentColor"
      strokeWidth="3.44476"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5 16.82L14.5 12.939L25.4286 19.2486L14.5 25.5582L14.5 21.6772L6 21.6772L6 16.82L14.5 16.82Z"
      fill="currentColor"
    />
  </svg>
));

export default JumpToClipOutIcon;
