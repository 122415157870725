import { Theme } from "@glideapps/glide-data-grid";

// Note that we use dark mode so "textDark" is actually technically "textLight"

export const glideTableDarkTheme: Theme = {
  accentColor: "#6366f1", // indigo-500
  accentFg: "#fafafa", // neutral-50
  accentLight: "rgba(99, 102, 241, 0.2)", // indigo-500 with opacity

  textDark: "#a3a3a3", // neutral-400
  textMedium: "#a3a3a3", // neutral-400
  textLight: "#737373", // neutral-500
  textBubble: "#a3a3a3", // neutral-900

  bgIconHeader: "#737373", // neutral-500
  fgIconHeader: "#fafafa", // neutral-50
  textHeader: "#fafafa", // neutral-50
  textGroupHeader: "#e5e5e5bb", // neutral-200 with opacity
  textHeaderSelected: "#ffffff", // white

  bgCell: "#171717", // neutral-900
  bgCellMedium: "#404040", // neutral-700
  bgHeader: "#171717", // neutral-900
  bgHeaderHasFocus: "#4f46e5", // indigo-600
  bgHeaderHovered: "#4338ca", // indigo-700

  bgBubble: "#525252", // neutral-600
  bgBubbleSelected: "#6366f1", // indigo-500

  bgSearchResult: "#854d0e", // Not from the provided palettes, keeping a dark yellow for visibility

  borderColor: "rgba(163, 163, 163, 0.16)", // neutral-400 with low opacity
  drilldownBorder: "rgba(255, 255, 255, 0.1)", // white with very low opacity

  linkColor: "#818cf8", // indigo-400

  cellHorizontalPadding: 8,
  cellVerticalPadding: 3,

  headerIconSize: 16,

  headerFontStyle: "400 12px",
  baseFontStyle: "400 12px",
  markerFontStyle: "9px",
  fontFamily:
    "Inter, Roboto, -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, noto, arial, sans-serif",
  editorFontSize: "12px",
  lineHeight: 1.4,
};
