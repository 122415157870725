import { forwardRef } from "react";

const ClipInIcon = forwardRef<SVGSVGElement, React.SVGAttributes<SVGElement>>(
  (props, forwardedRef) => (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      ref={forwardedRef}
      {...props}
    >
      <path
        d="M21.7412 6V15.0265L16 19.8208L21.741 24.615V33.5582"
        stroke="currentColor"
        strokeWidth="3.44476"
      />
    </svg>
  ),
);

export default ClipInIcon;
