import React, { createContext, PropsWithChildren, useContext } from "react";

export type FrameRate = [number, number];

const DEFAULT_NUMERATOR = 30000;
const DEFAULT_DENOMINATOR = 1001;
const DEFAULT_FRAMERATE = [DEFAULT_NUMERATOR, DEFAULT_DENOMINATOR] as FrameRate;
const FrameRateContext = createContext<FrameRate>(DEFAULT_FRAMERATE);

export const FrameRateProvider: React.FC<
  PropsWithChildren<{ frameRate?: FrameRate }>
> = ({ frameRate = DEFAULT_FRAMERATE, children }) => {
  return (
    <FrameRateContext.Provider value={frameRate}>
      {children}
    </FrameRateContext.Provider>
  );
};

export const useFrameRate = (): FrameRate => {
  const context = useContext(FrameRateContext);

  if (!context) {
    throw new Error("useFrameRate must be used within a FrameRateProvider");
  }

  return context;
};

export const useFramesPerSecond = (): number => {
  const context = useContext(FrameRateContext);

  if (!context) {
    throw new Error(
      "useFramesPerSecond must be used within a FrameRateProvider",
    );
  }

  const [frameNumerator, frameDenominator] = context;

  return frameNumerator / frameDenominator;
};
