import { components } from "@/openapi-bindings/v2";
import { FilterBadgeTag } from "../../filter/filterPanel/tags/FilterBadgeTag";
import OmniSearchCardSectionEmptyState from "@components/omniSearch/cardModules/OmniSearchCardSectionEmptyState";
import {
  Avatar,
  AvatarImage,
  AvatarFallback,
  cn,
  TooltipTrigger,
  TooltipContent,
  Tooltip,
  TooltipProvider,
} from "@kino/ui";
import { useActiveServerStore } from "@/store/activeServerStore";

interface OmniSearchCardEntitiesProps {
  people: components["schemas"]["Person"][] | undefined;
  tags: components["schemas"]["Tag"][] | undefined;
}

interface PersonAvatarProps extends React.HTMLAttributes<HTMLSpanElement> {
  person: components["schemas"]["Person"];
}

const PersonAvatar = ({ person, className, ...props }: PersonAvatarProps) => {
  const { buildServerUrl } = useActiveServerStore();
  return (
    <Avatar
      className={cn("h-5 w-5 border border-neutral-700", className)}
      {...props}
    >
      <AvatarImage src={buildServerUrl(person.thumbnail_path ?? "")} />
      <AvatarFallback className="text-[8px]">{person.name[0]}</AvatarFallback>
    </Avatar>
  );
};

const OmniSearchCardEntities = ({
  people,
  tags,
}: OmniSearchCardEntitiesProps) => {
  if (!tags && !people) {
    return (
      <OmniSearchCardSectionEmptyState
        message="No entities"
        className="h-full w-full p-2"
      />
    );
  }
  if (!tags && !people) {
    return (
      <OmniSearchCardSectionEmptyState
        message="No entities"
        className="h-full w-full p-2"
      />
    );
  }

  return (
    <div className="no-scrollbar h-full w-full overflow-x-scroll">
      <div className="flex gap-2 p-1 px-2">
        {people && (
          <div className="flex items-center gap-2">
            {people.map((person, index) => (
              <TooltipProvider key={person.id}>
                <Tooltip delayDuration={50}>
                  <TooltipTrigger className="cursor-default select-none">
                    <PersonAvatar
                      person={person}
                      className={`${index !== 0 && "-ml-4"}`}
                    />
                  </TooltipTrigger>
                  <TooltipContent>
                    <div className="flex items-center gap-1">
                      <PersonAvatar person={person} />
                      <div className="text-xs">
                        {person.name.replace("_", " ")}
                      </div>
                    </div>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            ))}
          </div>
        )}
        <div className="flex items-center gap-2">
          {tags &&
            tags.map((tag) => {
              return <FilterBadgeTag tag={tag} displayOnly={true} />;
            })}
        </div>
      </div>
    </div>
  );
};

export default OmniSearchCardEntities;
