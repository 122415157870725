import { ChevronDownIcon, ChevronRightIcon } from "@radix-ui/react-icons";
import {
  cn,
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@kino/ui";
import { useInspectorStore } from "@/store/inspectorStore";
import { useEffect } from "react";

export interface InspectorCollapsiblePanelSectionProps
  extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  label: string;
  name: string;
  icon?: React.ReactNode;
  defaultOpen?: boolean;
  contentClassName?: string;
  /**
   * A number in a badge to display on the right side of the section.
   * For filter sections, this shows the number of filters that are currently applied.
   */
  numBadge?: number;
  /**
   * Optional content to display when the section is collapsed.
   * For filter sections, this shows the filters that are currently applied.
   */
  previewContent?: React.ReactNode;
  triggerRightContent?: React.ReactNode;
  isExpandable?: boolean;
}

const InspectorCollapsiblePanelSection: React.FC<
  InspectorCollapsiblePanelSectionProps
> = ({
  children,
  label,
  name,
  icon,
  className,
  defaultOpen,
  contentClassName,
  numBadge,
  previewContent,
  triggerRightContent,
  isExpandable = true,
  ...props
}) => {
  const isOpen = useInspectorStore(
    (state) => state.expandedSections[name] || false,
  );
  const setSectionExpanded = useInspectorStore(
    (state) => state.setSectionExpanded,
  );

  useEffect(() => {
    if (defaultOpen) {
      setSectionExpanded(name, defaultOpen);
    }
  }, [defaultOpen, name, setSectionExpanded]);

  const handleOpenChange = (nextOpen: boolean) => {
    setSectionExpanded(name, nextOpen);
  };

  useEffect(() => {
    if (!isExpandable) {
      setSectionExpanded(name, false);
    }
  }, [isExpandable, name, setSectionExpanded]);

  return (
    <Collapsible open={isOpen} onOpenChange={handleOpenChange}>
      <CollapsibleTrigger asChild>
        <div
          className={cn(
            "flex w-full p-2",
            previewContent
              ? "flex-col items-start gap-2"
              : "h-11 items-center justify-between",
          )}
        >
          <div
            className={cn(
              `flex w-full cursor-pointer items-center justify-between text-neutral-200`,
              className,
            )}
            {...props}
          >
            <div className="flex items-center gap-2">
              {icon && (
                <div className="h-3.5 w-3.5 text-neutral-500">{icon}</div>
              )}
              <span className="truncate text-ellipsis text-xs font-medium">
                {label}
              </span>
            </div>
            <div className="flex items-center gap-1">
              {triggerRightContent}
              {!!numBadge && (
                <div className="flex h-3 w-3 items-center justify-center rounded-full bg-indigo-600 text-[8px] font-medium text-white">
                  {numBadge}
                </div>
              )}
              {isExpandable && (
                <div className="h-4 w-4 text-neutral-400">
                  {!isOpen && <ChevronRightIcon />}
                  {isOpen && <ChevronDownIcon />}
                </div>
              )}
            </div>
          </div>
          {previewContent && <div>{previewContent}</div>}
        </div>
      </CollapsibleTrigger>
      {isExpandable && (
        <CollapsibleContent className={cn("flex w-full", contentClassName)}>
          {children}
        </CollapsibleContent>
      )}
    </Collapsible>
  );
};

export { InspectorCollapsiblePanelSection };
