import React, { useEffect, useState } from "react";
import DataEditor, {
  CompactSelection,
  GetRowThemeCallback,
  GridMouseEventArgs,
  GridSelection,
} from "@glideapps/glide-data-grid";
import "@glideapps/glide-data-grid/dist/index.css";
import { glideTableDarkTheme } from "@/components/glide-table/theme";
import useMediaGrid from "@/hooks/useMediaGrid";
import Loading from "@/layouts/Loading";
import { formatStringPlurality, getInspectorItemId } from "@/utils/stringUtils";
import PanelHeader from "@/layouts/panels/PanelHeader";

import { useAllMedia } from "@/hooks/useAllMediaQuery";
import { useSelectedMediaStore } from "@/store/selectedMediaStore";
import customCellRenderers from "@/components/glide-table/custom-cell-renderers";
import { getTailwindColorWithOpacity } from "@/utils/tailwind";
import GlideTablePortal from "@/components/glide-table/GlideTablePortal";
import { components } from "@/openapi-bindings/v2";
import { Badge } from "@kino/ui";
import TagBadge from "@/components/inspector/properties/TagBadge";
import { useMediaLoggingEnabled } from "@/hooks/useMediaLoggingEnabled";

const gridSelectionToRowIndexes = (selection: GridSelection): number[] => {
  if (!selection.current) {
    return Array.from(selection.rows, (_, i) => i);
  }

  const { y, height } = selection.current.range;
  return Array.from({ length: height }, (_, i) => y + i);
};

const gridSelectionToMediaFiles = (
  mediaFiles: components["schemas"]["MediaItem"][],
  selection: GridSelection,
): components["schemas"]["MediaItem"][] => {
  const selectedRowIndexes = gridSelectionToRowIndexes(selection);

  return mediaFiles.filter((_, index) => selectedRowIndexes.includes(index));
};

const MediaPage: React.FC = () => {
  const [showSearch, setShowSearch] = useState(false);
  const [gridSelection, setGridSelection] = React.useState<GridSelection>({
    rows: CompactSelection.empty(),
    columns: CompactSelection.empty(),
  });
  const { data: media, isLoading } = useAllMedia();
  const numRows = media?.media_items.length || 0;
  const { columns, getCellContent, onColumnResize } = useMediaGrid(media);
  const { setSelectedMedia } = useSelectedMediaStore((state) => ({
    setSelectedMedia: state.setCurrentSelection,
  }));

  const [hoverRow, setHoverRow] = React.useState<number | undefined>(undefined);
  const onItemHovered = React.useCallback((args: GridMouseEventArgs) => {
    const [_, row] = args.location;
    setHoverRow(args.kind !== "cell" ? undefined : row);
  }, []);
  const getRowThemeOverride = React.useCallback<GetRowThemeCallback>(
    (row) => {
      if (row !== hoverRow) return undefined;
      return {
        bgCell: getTailwindColorWithOpacity("neutral", "800", 0.5),
        bgCellMedium: getTailwindColorWithOpacity("neutral", "700", 0.5),
      };
    },
    [hoverRow],
  );

  const onGridSelectionChange = (selection: GridSelection) => {
    setGridSelection(selection);
    const selectedFiles = gridSelectionToMediaFiles(
      media?.media_items || [],
      selection,
    );
    setSelectedMedia(
      selectedFiles.map((file) => ({
        mediaItem: file,
        moment: undefined,
        id: getInspectorItemId(file.id),
      })),
    );
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.ctrlKey || event.metaKey) && event.code === "KeyF") {
        setShowSearch((cv) => !cv);
        event.stopPropagation();
        event.preventDefault();
      }
    };

    window.addEventListener("keydown", handleKeyDown, {
      capture: false,
      passive: false,
    });

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const isMediaLoggingEnabled = useMediaLoggingEnabled();

  return (
    <div className="flex h-full flex-col divide-y">
      <PanelHeader className="h-11 max-h-11 min-h-11 gap-2 border-none">
        <h1 className="text-xs">All Media</h1>
        <span className="text-xs text-neutral-600">
          {numRows} {formatStringPlurality(numRows, "item", "items")}
        </span>
        {isMediaLoggingEnabled && (
          <TagBadge
            variant="tagResult"
            tag={{
              id: "generated",
              value: "All fields are generated by Kino AI",
              tag_type: "GENERATED",
            }}
          />
        )}
      </PanelHeader>
      {isLoading && <Loading />}
      {!isLoading && (
        <DataEditor
          getCellContent={getCellContent}
          customRenderers={customCellRenderers}
          columns={columns}
          rows={numRows}
          height="100%"
          width="100%"
          preventDiagonalScrolling={true}
          smoothScrollX={true}
          smoothScrollY={true}
          rowMarkers={"both"}
          columnSelect="none"
          rowSelect="multi"
          theme={glideTableDarkTheme}
          onColumnResize={onColumnResize}
          onGridSelectionChange={onGridSelectionChange}
          getCellsForSelection={true}
          gridSelection={gridSelection}
          freezeColumns={2}
          showSearch={showSearch}
          onSearchClose={() => setShowSearch(false)}
          rowHeight={80}
          verticalBorder={(columnIndex) => columnIndex === 2}
          rowSelectionBlending="mixed"
          rowSelectionMode="multi"
          getRowThemeOverride={getRowThemeOverride}
          onItemHovered={onItemHovered}
        />
      )}
      <GlideTablePortal />
    </div>
  );
};

export default MediaPage;
