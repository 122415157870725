import React from "react";
import { ChevronLeftIcon } from "@radix-ui/react-icons";
import TimecodeFilterCollapsible from "./timecode/TimecodeFilterCollapsible";
import TagsFilterCollapsible from "./tags/TagsFilterCollapsible";
import SecondaryFilterPanel, {
  SecondaryFilterPanelContent,
  SecondaryFilterPanelHeader,
} from "./SecondaryFilterPanel";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@kino/ui";
import {
  FocusedSecondaryPanelFilter,
  useSecondaryFilterPanelStore,
} from "@/store/filter/secondaryFilterPanelStore";
import { EMPTY_BASE_FILTERS } from "@/types/filters";
import TagsSecondaryFilterPanelContent from "./tags/TagsSecondaryFilterPanelContent";
import { useLayoutStore } from "@/store/layoutStore";
import ArrayFilterCollapsible from "./array/ArrayFilterCollapsible";
import { FilterButton } from "../FilterButton";
import ArraySecondaryFilterPanelContent from "./array/ArraySecondaryFilterPanelContent";
import AppliedFiltersPool from "./AppliedFiltersPool";
import { formatTagTypeLabel } from "@/utils/constants/tags";
type CommonSecondaryFilterPanelContentProps = {
  onClose: () => void;
};

type SecondaryFilterPanelContentProps =
  CommonSecondaryFilterPanelContentProps & {
    focusedFilter: FocusedSecondaryPanelFilter;
  };

type FilterContentComponent =
  React.ComponentType<SecondaryFilterPanelContentProps>;

type FocusedFilterKeys = "tags" | "people";

const filterContentMap: Record<
  FocusedFilterKeys,
  {
    component: FilterContentComponent;
    renderProps: (props: SecondaryFilterPanelContentProps) => React.ReactNode;
  }
> = {
  tags: {
    component: TagsSecondaryFilterPanelContent,
    renderProps: (props) => <TagsSecondaryFilterPanelContent {...props} />,
  },
  people: {
    component: ArraySecondaryFilterPanelContent,
    renderProps: (props) => <ArraySecondaryFilterPanelContent {...props} />,
  },
};

const FilterPanel = () => {
  const { focusedFilter } = useSecondaryFilterPanelStore();
  const { set, isFilterPanelOpen } = useLayoutStore();

  const commonProps: CommonSecondaryFilterPanelContentProps = {
    onClose: () => {},
  };

  const renderSecondaryFilterContent = () => {
    if (focusedFilter && focusedFilter.key in filterContentMap) {
      const { renderProps } =
        filterContentMap[focusedFilter.key as FocusedFilterKeys];
      return renderProps({ ...commonProps, focusedFilter });
    }
    return null;
  };

  if (!isFilterPanelOpen) {
    return null;
  }

  return (
    <div className="relative flex h-full min-w-72 max-w-72 flex-col divide-y">
      <div className="flex h-11 items-center justify-between p-2">
        <div className="flex items-center gap-2">
          <FilterButton className="cursor-default border-none px-0 hover:bg-transparent" />
        </div>
        <ChevronLeftIcon
          className="h-3.5 w-3.5 cursor-pointer text-neutral-400 hover:text-neutral-300"
          onClick={() => set("isFilterPanelOpen", false)}
        />
      </div>
      <AppliedFiltersPool filters={EMPTY_BASE_FILTERS} />
      <div className="flex flex-col divide-y overflow-y-auto">
        {/* <TimecodeFilterCollapsible /> */}
        <TagsFilterCollapsible />
        <ArrayFilterCollapsible filterKey="people" />
      </div>
      <SecondaryFilterPanel>
        {focusedFilter && (
          <SecondaryFilterPanelHeader>
            <Breadcrumb>
              <BreadcrumbList>
                <BreadcrumbItem className="text-xs">
                  {EMPTY_BASE_FILTERS[focusedFilter.key].label}
                </BreadcrumbItem>
                {focusedFilter.key === "tags" && focusedFilter.tagType && (
                  <>
                    <BreadcrumbSeparator />
                    <BreadcrumbItem className="text-xs">
                      {formatTagTypeLabel(focusedFilter.tagType)}
                    </BreadcrumbItem>
                  </>
                )}
              </BreadcrumbList>
            </Breadcrumb>
          </SecondaryFilterPanelHeader>
        )}
        <SecondaryFilterPanelContent className="p-2 pb-0 text-xs">
          {renderSecondaryFilterContent()}
        </SecondaryFilterPanelContent>
      </SecondaryFilterPanel>
    </div>
  );
};

export default FilterPanel;
