import { cn } from "@/utils/tailwind";
import { HTMLAttributes } from "react";
interface OmniSearchCardSectionEmptyStateProps
  extends HTMLAttributes<HTMLDivElement> {
  message?: string;
}

const OmniSearchCardSectionEmptyState: React.FC<
  OmniSearchCardSectionEmptyStateProps
> = ({ message, className, ...props }) => {
  return (
    <div
      className={cn(
        "text-xsm flex cursor-default select-none items-center",
        className,
      )}
      {...props}
    >
      <span className="text-neutral-600">{message ?? "N/A"}</span>
    </div>
  );
};

export default OmniSearchCardSectionEmptyState;
