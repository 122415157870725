import { useCallback, useRef } from "react";
import { type Virtualizer } from "@tanstack/react-virtual";

function easeInOutQuint(t: number) {
  return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t;
}

const useSmoothScroll = (
  virtualizer: Virtualizer<HTMLDivElement, Element>,
  viewportRef: React.RefObject<HTMLElement>,
) => {
  const scrollingRef = useRef(0);

  return useCallback(
    (
      index: number,
      {
        align: initialAlign = "start",
        duration = 200,
      }: {
        align?: "start" | "center" | "end" | "auto";
        duration?: number;
      } = {},
    ) => {
      const viewport = viewportRef.current;
      if (!viewport) return;

      const start = viewport.scrollTop;
      const startTime = (scrollingRef.current = Date.now());
      const result = virtualizer.getOffsetForIndex(index, initialAlign) || [
        0,
        initialAlign,
      ];
      const [, align] = result;

      const run = () => {
        if (scrollingRef.current !== startTime) return;
        const now = Date.now();
        const elapsed = now - startTime;
        const progress = easeInOutQuint(Math.min(elapsed / duration, 1));
        const [offset] = virtualizer.getOffsetForIndex(index, align) ?? [
          0,
          align,
        ];
        const interpolated = start + (offset - start) * progress;

        if (elapsed < duration) {
          viewport.scrollTop = interpolated;
          requestAnimationFrame(run);
        } else {
          viewport.scrollTop = offset;
        }
      };

      requestAnimationFrame(run);
    },
    [virtualizer, viewportRef],
  );
};

export default useSmoothScroll;
