import InspectorBasicPropertiesCollapsible from "@/components/inspector/collapsibleSection/InspectorBasicPropertiesCollapsible";
import { ScrollArea } from "@kino/ui";
import { useSelectedMediaStore } from "@/store/selectedMediaStore";

const InspectorBasicPropertiesTab = () => {
  const selectedMedia = useSelectedMediaStore(
    (state) => state.currentSelection,
  );
  const numSelectedMedia = selectedMedia?.length ?? 0;
  const singleSelection =
    numSelectedMedia == 1 ? selectedMedia?.[0] : undefined;

  if (
    !singleSelection ||
    !singleSelection.mediaItem ||
    !singleSelection.mediaItem.ffprobe_data
  ) {
    return null;
  }

  return (
    <ScrollArea className="">
      <InspectorBasicPropertiesCollapsible
        mediaItem={singleSelection.mediaItem}
      />
    </ScrollArea>
  );
};

export { InspectorBasicPropertiesTab };
