import {
  type BaseFilterState,
  EMPTY_FILTER_STATE,
  type FilterConditionType,
} from "@/types/filters";
import _ from "lodash";

export const countNonEmptyFilters = (state: BaseFilterState): number => {
  return Object.keys(state).reduce((count, key) => {
    const value = state[key as keyof BaseFilterState];
    const emptyValue = EMPTY_FILTER_STATE[key as keyof BaseFilterState];

    if (!_.isEqual(value, emptyValue)) {
      return count + 1;
    }
    return count;
  }, 0);
};

export function getFilterText(
  filterConditionType: FilterConditionType,
  selectedOptionsLength: number,
): string {
  switch (filterConditionType) {
    case "equality":
      return selectedOptionsLength > 1 ? "is any of" : "is";
    case "inclusion":
      return "contains";
    case "exclusion":
      return selectedOptionsLength > 1 ? "do not contain" : "does not contain";
    case "inSet":
      return "in";
    default:
      return "";
  }
}
