import { useState } from "react";
import {
  PanelResizeHandle as PanelResizeHandleComponent,
  type PanelResizeHandleProps as PanelResizeHandleOriginalProps,
} from "react-resizable-panels";

interface PanelResizeHandleProps extends PanelResizeHandleOriginalProps {
  onDragging?: (isDragging: boolean) => void;
}

const PanelResizeHandle: React.FC<PanelResizeHandleProps> = ({
  onDragging,
  ...props
}) => {
  const [isDragging, setIsDragging] = useState<boolean>(false);

  const handleDragging = (e: boolean) => {
    setIsDragging(e);
    onDragging && onDragging(e);
  };

  return (
    <PanelResizeHandleComponent
      {...props}
      onDragging={handleDragging}
      className={`panel-drag-handle z-0 flex w-[2px] flex-col items-center justify-center gap-1 bg-black transition-all hover:bg-blue-600 ${isDragging && "bg-blue-600"}`}
    />
  );
};

export default PanelResizeHandle;
