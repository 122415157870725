import { InspectorCollapsiblePanelSection } from "../collapsibleSection/InspectorCollapsiblePanelSection";
import { TranscriptTextEditor } from "@/components/transcript/TranscriptTextEditor";
import { ClickableIcon, cn, ScrollArea } from "@kino/ui";
import {
  HandThumbUpIcon,
  HandThumbDownIcon,
} from "@heroicons/react/24/outline";
import { useRef } from "react";
import { useSelectedMediaStore } from "@/store/selectedMediaStore";

const InspectorSearchResultSummary = () => {
  const transcriptSectionRef = useRef<HTMLDivElement>(null);
  const selectedMedia = useSelectedMediaStore(
    (state) => state.currentSelection,
  );
  const numSelectedMedia = selectedMedia?.length ?? 0;
  const singleSelection =
    numSelectedMedia == 1 ? selectedMedia?.[0] : undefined;
  if (!singleSelection) {
    return null;
  }

  return (
    <div className="flex h-full w-full flex-col divide-y">
      <div className="flex flex-row items-center justify-between rounded-sm p-2 text-xs">
        <span className="text-neutral-400">90% match</span>
        <div className="flex items-center gap-2">
          <ClickableIcon
            Icon={HandThumbUpIcon}
            name="Like"
            tooltip="Like"
            className={cn(
              "p-0 text-xs font-normal text-neutral-500 hover:text-neutral-400",
            )}
          />
          <ClickableIcon
            Icon={HandThumbDownIcon}
            name="Dislike"
            tooltip="Dislike"
            className={cn(
              "p-0 text-xs font-normal text-neutral-500 hover:text-neutral-400",
            )}
          />
        </div>
      </div>
      <ScrollArea>
        <InspectorCollapsiblePanelSection
          label="Transcript"
          name="transcript"
          defaultOpen
          contentClassName="max-h-[200px] overflow-hidden border-b"
        >
          <div className="w-full overflow-y-auto" ref={transcriptSectionRef}>
            <TranscriptTextEditor
              activeInspectorItem={singleSelection}
              viewportRef={transcriptSectionRef}
            />
          </div>
        </InspectorCollapsiblePanelSection>
      </ScrollArea>
    </div>
  );
};

export { InspectorSearchResultSummary };
