import { forwardRef } from "react";

const JumpToClipInIcon = forwardRef<
  SVGSVGElement,
  React.SVGAttributes<SVGElement>
>((props, forwardedRef) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    ref={forwardedRef}
    {...props}
  >
    <path
      d="M11.7412 6V15.0265L5.99998 19.8208L11.741 24.615V33.5582"
      stroke="currentColor"
      strokeWidth="3.44476"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.9286 22.7382L25.9286 26.6192L15 20.3096L25.9286 14L25.9286 17.881L34.4286 17.881L34.4286 22.7382L25.9286 22.7382Z"
      fill="currentColor"
    />
  </svg>
));

export default JumpToClipInIcon;
