import React from "react";
import OmniSearchCardSectionEmptyState from "./OmniSearchCardSectionEmptyState";
import OmniSearchCardPopover from "../OmniSearchCardPopover";
import { components } from "@/openapi-bindings/v2";
import { getFileName } from "@/utils/pathUtils";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@kino/ui";

interface OmniSearchCardPathProps {
  path?: string;
  moment: components["schemas"]["Moment"];
}

const OmniSearchCardPath: React.FC<OmniSearchCardPathProps> = ({
  path,
  moment,
}) => {
  if (!path) return <OmniSearchCardSectionEmptyState message="No path" />;
  return (
    <div className="group flex h-3 flex-row items-center justify-between gap-2">
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <h3 className="text-xsm flex-1 cursor-default select-none truncate text-ellipsis font-light text-neutral-600">
              {getFileName(path)}
            </h3>
          </TooltipTrigger>
          <TooltipContent className="cursor-default select-none text-xs">
            {path}
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
      <OmniSearchCardPopover moment={moment} />
    </div>
  );
};

export default OmniSearchCardPath;
