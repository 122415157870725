import { useEffect, useState } from "react";
import { Command, CommandDialog } from "@kino/ui";
import {
  CommandMenuList,
  CommandMenuVariant,
} from "@components/omniSearch/commandMenu/CommandMenuList";
import {
  CommandMenuFocusType,
  useCommandMenuFocus,
  useCommandMenuStore,
} from "@/store/commandMenuStore";

export const COMMAND_ITEM_VALUE_DELIMITER = "_";

interface CommandMenuProps {
  isDialog?: boolean;
  focused: boolean;
  setFocused: (focused: boolean) => void;
  variant?: CommandMenuVariant;
  maxListWidth?: number;
  focusByDefault?: boolean;
}

export const CommandMenu = ({
  isDialog,
  focused,
  setFocused,
  maxListWidth,
  variant = CommandMenuVariant.default,
  focusByDefault,
}: CommandMenuProps) => {
  const activeSearchEntity = useCommandMenuStore(
    (state) => state.activeSearchEntity,
  );
  /**
   * assumed value format is [id]_[searchTerm]
   * e.g. transcript_happy-days_are_here
   * The search term above would be "happy-days_are_here"
   */
  const handleFilterCommandItems = (value: string, search: string): number => {
    const idIndex = value.indexOf(COMMAND_ITEM_VALUE_DELIMITER);

    if (idIndex === -1) {
      return 0; // Return 0 if value does not contain '-'
    }

    const searchTerm = value
      .substring(idIndex + 1)
      .trim()
      .toLowerCase(); // Convert searchTerm to lowercase

    const encodedSearch = encodeURIComponent(search.trim().toLowerCase());

    if (searchTerm.includes(encodedSearch)) {
      return 1; // Convert search to lowercase
    }

    return 0;
  };

  const handleFilterSearchEntityItems = (
    value: string,
    search: string,
  ): number => {
    return value.includes(activeSearchEntity?.text || "") ? 1 : 0;
  };

  return (
    <Command
      shouldFilter={true}
      filter={
        activeSearchEntity
          ? handleFilterSearchEntityItems
          : handleFilterCommandItems
      }
      className={`bg-transparent`}
    >
      <CommandMenuList
        isDialog={isDialog}
        focused={focused}
        setFocused={setFocused}
        variant={variant}
        maxListWidth={maxListWidth}
        focusByDefault={focusByDefault}
      />
    </Command>
  );
};

export const CommandMenuDialog = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [dialogFocused, setDialogFocused] = useCommandMenuFocus(
    CommandMenuFocusType.Dialog,
  );

  useEffect(() => {
    !dialogFocused && setOpen(false);
  }, [dialogFocused]);

  // Cmd + K
  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === "k" && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        setOpen((open) => !open);
      }
    };
    document.addEventListener("keydown", down);
    return () => document.removeEventListener("keydown", down);
  }, [location.pathname, setDialogFocused]);

  return (
    <CommandDialog open={open} onOpenChange={setOpen}>
      <CommandMenu
        isDialog={true}
        focused={dialogFocused}
        setFocused={setDialogFocused}
      />
    </CommandDialog>
  );
};
