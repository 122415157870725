import * as React from "react";
import { cn } from "../utils/tailwind";
import {
  ThreeDots,
  TailSpin,
  Oval,
  Puff,
  Rings,
  Bars,
} from "react-loading-icons";

const spinnerVariants = {
  default: TailSpin,
  threeDots: ThreeDots,
  oval: Oval,
  puff: Puff,
  rings: Rings,
  bars: Bars,
};

export interface SpinnerProps extends React.HTMLAttributes<HTMLDivElement> {
  variant?: keyof typeof spinnerVariants;
  className?: string;
}

export function Spinner({ variant = "default", className }: SpinnerProps) {
  const SpinnerIcon = spinnerVariants[variant];

  return (
    <div className={cn("flex h-6 w-6 items-center justify-center", className)}>
      <SpinnerIcon
        stroke={""}
        strokeOpacity={4}
        strokeWidth={4}
        speed={1}
        className={cn("h-6 w-6 stroke-indigo-500 p-1", className)}
      />
    </div>
  );
}
