import { create } from "zustand";

interface InspectorState {
  expandedSections: Record<string, boolean>;
  setSectionExpanded: (section: string, isExpanded: boolean) => void;
}

const useInspectorStore = create<InspectorState>((set) => ({
  expandedSections: {},
  setSectionExpanded: (section, isExpanded) =>
    set((state) => ({
      expandedSections: { ...state.expandedSections, [section]: isExpanded },
    })),
}));

export { useInspectorStore };
