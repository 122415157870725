import { Button } from "@kino/ui";
import { ChevronRightIcon } from "@radix-ui/react-icons";
import { useNavigate, useLocation } from "react-router-dom";

interface ResultsSectionHeaderProps {
  title: string;
  numResults?: number;
}
const ResultsSectionHeader = ({
  title,
  numResults,
}: ResultsSectionHeaderProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const handleClick = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("tab_type", title.toLowerCase());
    navigate(`/search?${searchParams.toString()}`);
  };
  return (
    <div className="m-0 mt-4 flex flex-row items-center gap-4">
      <h3 className="text-sm text-neutral-100">
        {title.charAt(0).toUpperCase() + title.slice(1).toLowerCase()} (
        {numResults ? numResults : 0})
      </h3>
      <Button
        onClick={handleClick}
        variant="link"
        size="xsm"
        className="flex items-center gap-1 p-0 font-mono text-xs font-medium text-neutral-600 hover:text-neutral-300"
      >
        All
        <ChevronRightIcon className="h-3 w-3" />
      </Button>
    </div>
  );
};

export default ResultsSectionHeader;
