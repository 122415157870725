import React, { useState } from "react";
import { InspectorCollapsiblePanelSection } from "@/components/inspector/collapsibleSection/InspectorCollapsiblePanelSection";
import { CommandList, Command, CommandInput } from "@kino/ui";
import { useFilterOptionsStore } from "@/store/filter/filterOptionsStore";
import { ArrayFilterKeys } from "@/types/filters";
import ArrayFilterSection from "./ArrayFilterSection";
import { useFilterState } from "@/hooks/useFilterState";

interface ArrayFilterCollapsibleProps {
  filterKey: ArrayFilterKeys;
}

const ArrayFilterCollapsible: React.FC<ArrayFilterCollapsibleProps> = ({
  filterKey,
}) => {
  const [kw, setKw] = useState("");
  const { filterOptions } = useFilterOptionsStore();
  const { searchState } = useFilterState();
  const filterState = searchState.filterState;

  return (
    <InspectorCollapsiblePanelSection
      label={filterOptions[filterKey].label}
      name={filterKey}
      icon={filterOptions[filterKey].icon}
      numBadge={filterState[filterKey]?.length || 0}
      // previewContent={
      //   !isFilterEmpty(filterKey, searchResultsFilterState) && (
      //     <div className="flex w-full flex-wrap gap-2">
      //       {searchResultsFilterState[filterKey]?.map((id) => {
      //         const filterOption = filterOptions[
      //           filterKey
      //         ].criteria?.find((option) => option.id === id);
      //         if (!filterOption) {
      //           return null;
      //         }
      //         return (
      //           <FilterBadgeArray
      //             filterStateType={FilterStateType.Search}
      //             filterKey={filterKey}
      //             filterOption={filterOption}
      //           />
      //         );
      //       })}
      //     </div>
      //   )
      // }
    >
      <div className="flex w-full flex-col px-2">
        <Command className="flex flex-col">
          <CommandInput
            placeholder="Find a person"
            searchLoading={false}
            className="h-7 rounded-md border border-neutral-800 px-1"
            value={kw}
            onValueChange={setKw}
          />
          <CommandList className="flex h-full max-h-none flex-col">
            <ArrayFilterSection
              filter={filterOptions[filterKey]}
              search={kw}
              filterKey={filterKey}
            />
          </CommandList>
        </Command>
      </div>
    </InspectorCollapsiblePanelSection>
  );
};

export default ArrayFilterCollapsible;
