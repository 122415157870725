import { cn } from "@/utils/tailwind";
import ResultsSectionHeader from "./ResultsSectionHeader";

interface ResultsSectionProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  title?: string;
  header?: React.ReactNode;
  numResults?: number;
}
const ResultsSection = ({
  children,
  title,
  header,
  className,
  numResults,
  ...props
}: ResultsSectionProps) => {
  return (
    <div
      className={cn(
        "m-4 mt-0 flex flex-col gap-4",
        header ? "divide-y *:py-4 first:*:py-0 last:*:pb-0" : "",
        className,
      )}
      {...props}
    >
      <div className="flex flex-col gap-4">
        {title && (
          <ResultsSectionHeader title={title} numResults={numResults} />
        )}
        {header && header}
      </div>
      {children}
    </div>
  );
};

export default ResultsSection;
