import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Switch,
} from "@kino/ui";
import { forwardRef, useEffect, useImperativeHandle } from "react";
import { useActiveServerStore } from "@/store/activeServerStore";
import { getServerUrl } from "@/utils/stringUtils";

export const RemoteCacheSchema = z.object({
  serverIp: z.string().min(1, {
    message: "Server address is required",
  }),
  serverPort: z
    .string()
    .min(1, {
      message: "Port is required",
    })
    .refine(
      (value) => {
        const numberValue = parseInt(value);
        return !isNaN(numberValue) && numberValue >= 1 && numberValue <= 65535;
      },
      {
        message: "Port is out of range",
      },
    ),
  useHttps: z.boolean().default(true),
  id: z.string().optional(),
  signInOnly: z.boolean().default(false),
});

export type TServerConnectionForm = z.infer<typeof RemoteCacheSchema>;

interface FormMethods {
  submit: () => void;
}

export type ServerConnectionFormRef = TServerConnectionForm & FormMethods;

interface ServerConnectionFormProps {
  defaultValues?: {
    ip: string;
    port: string;
    useHttps: boolean;
  };
  readOnly?: boolean;
}

const ServerConnectionForm = forwardRef<
  ServerConnectionFormRef,
  ServerConnectionFormProps
>(({ defaultValues, readOnly }, ref) => {
  const { setServerOverrideUrl } = useActiveServerStore();

  const form = useForm<TServerConnectionForm>({
    resolver: zodResolver(RemoteCacheSchema),
    defaultValues: {
      serverIp: "",
      serverPort: "1923",
      useHttps: true,
      id: "",
      signInOnly: false,
      ...defaultValues,
    },
  });

  const serverPort = form.watch("serverPort");

  useEffect(() => {
    const numberValue = parseInt(serverPort);
    if (isNaN(numberValue) || numberValue < 1 || numberValue > 65535) {
      form.setError("serverPort", {
        type: "manual",
        message: "Port is out of range",
      });
    } else {
      form.clearErrors("serverPort");
    }
  }, [serverPort, form]);

  useImperativeHandle(ref, () => ({
    ...form.getValues(),
    submit: () =>
      form.handleSubmit((data) =>
        setServerOverrideUrl(
          getServerUrl(data.serverIp, data.serverPort, data.useHttps),
        ),
      )(),
  }));

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit((data) => {
          setServerOverrideUrl(
            getServerUrl(data.serverIp, data.serverPort, data.useHttps),
          );
        })}
        className="flex w-full flex-col gap-2"
      >
        <div className="flex h-full w-full items-start gap-2 text-xs text-gray-500">
          <FormField
            control={form.control}
            name="serverIp"
            render={({ field }) => (
              <FormItem className="flex flex-grow flex-col items-start">
                <FormLabel className="text-xs font-normal text-gray-400">
                  IP or Domain
                </FormLabel>
                <Input
                  {...field}
                  readOnly={readOnly}
                  placeholder="IP or Domain"
                  className="h-8 max-h-8 w-full flex-grow shadow-none read-only:bg-gray-100"
                />
                <FormMessage className="text-xs font-normal" />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="serverPort"
            render={({ field }) => (
              <FormItem className="flex h-full flex-grow flex-col items-start">
                <FormLabel className="text-xs font-normal text-gray-400">
                  Port
                </FormLabel>
                <Input
                  {...field}
                  readOnly={readOnly}
                  placeholder="Port"
                  className="h-8 max-h-8 w-full max-w-[90px] flex-grow shadow-none read-only:bg-gray-100"
                />
                <FormMessage className="text-xs font-normal" />
              </FormItem>
            )}
          />
        </div>
        <FormField
          control={form.control}
          name="useHttps"
          render={({ field }) => (
            <FormItem className="flex items-center gap-2">
              <Switch
                checked={field.value}
                onCheckedChange={field.onChange}
                disabled={readOnly}
              />
              <FormLabel className="text-xs font-normal text-gray-400">
                Use HTTPS
              </FormLabel>
            </FormItem>
          )}
        />
      </form>
    </Form>
  );
});

ServerConnectionForm.displayName = "ServerConnectionForm";

export default ServerConnectionForm;
