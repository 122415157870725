import {
  GridCell,
  GridCellKind,
  Item,
  GridColumn,
  GridColumnIcon,
} from "@glideapps/glide-data-grid";
import { getFileName } from "@/utils/pathUtils";
import { useMemo, useCallback, useState } from "react";
import { components } from "@/openapi-bindings/v2";
import { secondsToTimestamp } from "@/utils/time";

import { useMediaLoggingEnabled } from "./useMediaLoggingEnabled";
import { useActiveServerStore } from "@/store/activeServerStore";

type MediaItem = components["schemas"]["MediaItem"];

// Content cache to avoid regenerating cells
class ContentCache {
  private cache: Map<string, GridCell> = new Map();

  get(col: number, row: number): GridCell | undefined {
    return this.cache.get(`${col}-${row}`);
  }

  set(col: number, row: number, value: GridCell): void {
    this.cache.set(`${col}-${row}`, value);
  }
}

interface ColumnDefinition {
  readonly gridColumn: GridColumn;
  readonly getContent: (item: MediaItem) => GridCell;
  readonly hidden?: boolean;
}

const createColumn = (definition: ColumnDefinition): ColumnDefinition =>
  definition;

const useMediaGrid = (
  media: components["schemas"]["AllMediaResponse"] | undefined,
) => {
  const cache = useMemo(() => new ContentCache(), []);
  const isSpecialOrg = useMediaLoggingEnabled();
  const { buildServerUrl } = useActiveServerStore();
  const specialColumnDefinitions = useMemo(
    () => [
      createColumn({
        gridColumn: {
          title: "",
          id: "thumbnail",
          icon: GridColumnIcon.HeaderImage,
          width: 180,
        },
        getContent: (item) => ({
          kind: GridCellKind.Image,
          data: [
            buildServerUrl(`thumbnail/${item.thumbnail_path}` || "") || "",
          ],
          allowOverlay: true,
          readOnly: true,
          copyData:
            buildServerUrl(`thumbnail/${item.thumbnail_path}` || "") || "",
        }),
      }),
      createColumn({
        gridColumn: {
          title: "Title",
          id: "generated_title",
          width: 325,
        },
        getContent: (item) => ({
          kind: GridCellKind.Text,
          data: (item.metadata?.title_GEN as string) || "",
          displayData: (item.metadata?.title_GEN as string) || "",
          allowOverlay: true,
          readOnly: true,
        }),
      }),
      createColumn({
        gridColumn: {
          title: "Celebs",
          id: "celebs",
          width: 200,
        },
        getContent: (item) => {
          if (!item.metadata?.celebrities_GEN) {
            return {
              kind: GridCellKind.Text,
              data: "",
              displayData: "",
              allowOverlay: false,
              readonly: true,
            };
          }
          return {
            kind: GridCellKind.Custom,
            data: {
              kind: "tag-cell",
              tags:
                ((item.metadata?.celebrities_GEN as string) || "")
                  ?.split(",")
                  .map((c: string) => ({
                    value: c.trim(),
                    tag_type: "TMZ_CELEBRITY",
                  }))
                  .filter(Boolean) || [],
            },
            allowOverlay: true,
            copyData: (item.metadata?.celebrities_GEN as string) || "",
            readOnly: true,
          };
        },
      }),
      createColumn({
        gridColumn: {
          title: "Description",
          id: "generated_description",
          width: 500,
        },
        getContent: (item) => ({
          kind: GridCellKind.Text,
          data: (item.metadata?.description_GEN as string) || "",
          displayData: (item.metadata?.description_GEN as string) || "",
          allowOverlay: true,
          readOnly: true,
          allowWrapping: true,
        }),
      }),
      createColumn({
        gridColumn: {
          title: "Asset Type",
          id: "asset_type",
          width: 200,
        },
        getContent: (item) => {
          if (!item.metadata?.asset_type_GEN) {
            return {
              kind: GridCellKind.Text,
              data: "",
              displayData: "",
              allowOverlay: false,
              readonly: true,
            };
          }
          return {
            kind: GridCellKind.Custom,
            data: {
              kind: "tag-cell",
              tags:
                ((item.metadata?.asset_type_GEN as string) || "")
                  ?.split(",")
                  .map((c: string) => ({
                    value: c.trim(),
                    tag_type: "TMZ_ASSET_TYPE",
                  }))
                  .filter(Boolean) || [],
            },
            allowOverlay: true,
            copyData: (item.metadata?.asset_type_GEN as string) || "",
            readOnly: true,
          };
        },
      }),
      createColumn({
        gridColumn: {
          title: "Celebrities Not In Video",
          id: "celebrities_not_in_video",
          width: 200,
        },
        getContent: (item) => {
          if (!item.metadata?.celebrities_not_in_video_GEN) {
            return {
              kind: GridCellKind.Text,
              data: "",
              displayData: "",
              allowOverlay: false,
              readonly: true,
            };
          }
          return {
            kind: GridCellKind.Custom,
            data: {
              kind: "tag-cell",
              tags:
                ((item.metadata?.celebrities_not_in_video_GEN as string) || "")
                  ?.split(",")
                  .map((c: string) => ({
                    value: c.trim(),
                    tag_type: "TMZ_CELEBRITY_NOT_IN_VIDEO",
                  }))
                  .filter(Boolean) || [],
            },
            allowOverlay: true,
            copyData:
              (item.metadata?.celebrities_not_in_video_GEN as string) || "",
            readOnly: true,
          };
        },
      }),
      createColumn({
        gridColumn: {
          title: "Key Moments",
          id: "moments",
          width: 300,
          icon: GridColumnIcon.HeaderSplitString,
        },
        getContent: (item) => ({
          kind: GridCellKind.Text,
          data: ((item.metadata?.moments_GEN as string) || "")
            .split(",")
            .join("\n\n"),
          displayData: (item.metadata?.moments_GEN as string) || "",
          allowOverlay: true,
          readOnly: true,
          allowWrapping: true,
        }),
      }),
      createColumn({
        gridColumn: {
          title: "Original Filename",
          id: "original_filename",
          width: 125,
        },
        getContent: (item) => ({
          kind: GridCellKind.Text,
          data: item.ffprobe_data.format.filename || "",
          displayData:
            getFileName(item.ffprobe_data.format.filename || "") ?? "",
          allowOverlay: true,
          readOnly: true,
        }),
      }),
      createColumn({
        gridColumn: {
          title: "Location",
          id: "location",
          width: 150,
        },
        getContent: (item) => ({
          kind: GridCellKind.Text,
          data: (item.metadata?.location_GEN as string) || "",
          displayData: (item.metadata?.location_GEN as string) || "",
          allowOverlay: true,
          readOnly: true,
        }),
      }),
      createColumn({
        gridColumn: {
          title: "Event",
          id: "event",
          width: 200,
        },
        getContent: (item) => ({
          kind: GridCellKind.Text,
          data: (item.metadata?.event_name_GEN as string) || "",
          displayData: (item.metadata?.event_name_GEN as string) || "",
          allowOverlay: true,
          readOnly: true,
        }),
      }),
      createColumn({
        gridColumn: {
          title: "Original context",
          id: "original_context",
          width: 200,
        },
        getContent: (item) => ({
          kind: GridCellKind.Text,
          data: (item.metadata?.additional_context_input as string) || "",
          displayData:
            (item.metadata?.additional_context_input as string) || "",
          allowOverlay: true,
          readOnly: true,
        }),
      }),
      createColumn({
        gridColumn: {
          title: "Transcript",
          id: "transcript",
          width: 300,
          icon: GridColumnIcon.HeaderSplitString,
        },
        getContent: (item) => ({
          kind: GridCellKind.Text,
          data: ((item.metadata?.transcript_GEN as string) || "")
            .split("<br>")
            .join("\n"),
          displayData: ((item.metadata?.transcript_GEN as string) || "")
            .split("<br>")
            .join("\n"),
          allowOverlay: true,
          readOnly: true,
          allowWrapping: true,
        }),
      }),
    ],
    [],
  );
  const defaultColumnDefinitions = useMemo(
    () => [
      createColumn({
        gridColumn: {
          title: "",
          id: "thumbnail",
          icon: GridColumnIcon.HeaderImage,
          width: 180,
        },
        getContent: (item) => ({
          kind: GridCellKind.Image,
          data: [
            buildServerUrl(`thumbnail/${item.thumbnail_path}` || "") || "",
          ],
          allowOverlay: true,
          contentAlign: "center",
          readOnly: true,
          copyData:
            buildServerUrl(`thumbnail/${item.thumbnail_path}` || "") || "",
        }),
      }),
      createColumn({
        gridColumn: {
          title: "Filename",
          id: "filename",
          width: 325,
        },
        getContent: (item) => ({
          kind: GridCellKind.Text,
          data: item.ffprobe_data.format.filename || "",
          displayData:
            getFileName(item.ffprobe_data.format.filename || "") ?? "",
          allowOverlay: true,
          readOnly: true,
        }),
      }),
      createColumn({
        gridColumn: {
          title: "Duration",
          id: "duration",
          width: 60,
        },
        getContent: (item) => ({
          kind: GridCellKind.Text,
          data: secondsToTimestamp(
            Number(item.ffprobe_data.format.duration) || 0,
            false,
          ),
          displayData: secondsToTimestamp(
            Number(item.ffprobe_data.format.duration) || 0,
            false,
          ),
          copyData: secondsToTimestamp(
            Number(item.ffprobe_data.format.duration) || 0,
            false,
          ),
          allowOverlay: true,
          contentAlign: "right",
          readOnly: true,
        }),
      }),
      createColumn({
        gridColumn: {
          title: "ID",
          id: "id",
          icon: GridColumnIcon.ProtectedColumnOverlay,
        },
        getContent: (item) => ({
          kind: GridCellKind.RowID,
          data: item.id || "",
          allowOverlay: true,
          readOnly: true,
        }),
        hidden: process.env.NODE_ENV !== "development",
      }),
    ],
    [],
  );

  const [columnDefinitions, setColumnDefinitions] = useState(
    (isSpecialOrg ? specialColumnDefinitions : defaultColumnDefinitions).filter(
      ({ hidden }) => !hidden,
    ),
  );

  const columns = useMemo(
    () => columnDefinitions.map(({ gridColumn }) => gridColumn),
    [columnDefinitions],
  );

  const getCellContent = useCallback(
    ([col, row]: Item): GridCell => {
      const mediaItem = media?.media_items[row];

      if (!mediaItem) {
        return {
          kind: GridCellKind.Loading,
          allowOverlay: false,
        };
      }

      const cached = cache.get(col, row);
      if (cached !== undefined) {
        return cached;
      }

      const columnDef = columnDefinitions[col];
      if (!columnDef) {
        return {
          kind: GridCellKind.Text,
          allowOverlay: false,
          data: "",
          displayData: "",
        };
      }

      const cell = columnDef.getContent(mediaItem);
      cache.set(col, row, cell);
      return cell;
    },
    [cache, columnDefinitions, media],
  );

  const onColumnResize = useCallback((column: GridColumn, newSize: number) => {
    setColumnDefinitions((prevDefs) =>
      prevDefs.map((def) =>
        def.gridColumn.id === column.id
          ? {
              ...def,
              gridColumn: { ...def.gridColumn, width: newSize },
            }
          : def,
      ),
    );
  }, []);

  return { columns, getCellContent, onColumnResize };
};

export default useMediaGrid;
