import React, { useState, DragEvent, ReactNode } from "react";
import { cn } from "@kino/ui";
import { UploadIcon } from "@radix-ui/react-icons";

interface DragDropWrapperProps {
  onFileDrop: (files: File[]) => void;
  children: ReactNode;
}

const DragDropWrapper: React.FC<DragDropWrapperProps> = ({
  onFileDrop,
  children,
}) => {
  const [isDragging, setIsDragging] = useState(false);

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => setIsDragging(false);

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(false);
    const droppedFiles = Array.from(e.dataTransfer.files).filter((file) =>
      file.name.endsWith(".avb"),
    );
    onFileDrop(droppedFiles);
  };

  return (
    <div
      className="flex h-full w-full"
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      {isDragging ? (
        <div
          className={cn(
            "h-full w-full border border-dashed p-8 transition-colors duration-300 ease-in-out",
            "border-indigo-500 bg-indigo-600/30",
          )}
        >
          <div className="flex h-full w-full flex-col items-center justify-center text-center">
            <UploadIcon className="mb-4 h-4 w-4 text-neutral-400" />
            <p className="mb-2 text-sm text-neutral-400">
              Drop your file(s) here
            </p>
          </div>
        </div>
      ) : (
        children
      )}
    </div>
  );
};

export default DragDropWrapper;
