import { forwardRef } from "react";

const WindowHandleRightIcon = forwardRef<
  SVGSVGElement,
  React.SVGAttributes<SVGElement>
>((props, forwardedRef) => (
  <svg viewBox="0 0 4 10" fill="none" {...props} ref={forwardedRef}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.00414583 0H1.00415V3.07502L3.89023 5L1 6.92V10H0V6.4L2.10977 5L0.00414583 3.59782V0Z"
      fill="currentColor"
    />
    <path d="M2.10938 5.00007L3.60814e-07 3.60007L2.38419e-07 6.40015L2.10938 5.00007Z" />
  </svg>
));

export default WindowHandleRightIcon;
