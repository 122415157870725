import { useEffect } from "react";
import { useFilterOptionsStore } from "@/store/filter/filterOptionsStore";
import { EMPTY_BASE_FILTERS } from "@/types/filters";
import { TagIcon } from "lucide-react";
import { groupTagsByType, useGetTagsQuery } from "./useGetTagsQuery";
import { useGetPeopleQuery } from "./useGetPeopleQuery";
import { FilterCriteriaTagType } from "@/types/filters"; // Make sure this import exists
import { useActiveServerStore } from "@/store/activeServerStore";
import { Avatar, AvatarFallback, AvatarImage } from "@kino/ui";

const formatTagType = (tagType: string): string => {
  return tagType
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

export const useInitializeFilters = () => {
  const { updateBaseFilterOptionKey } = useFilterOptionsStore();
  const { data: tags } = useGetTagsQuery();
  const { data: people } = useGetPeopleQuery();
  const { buildServerUrl } = useActiveServerStore();

  useEffect(() => {
    if (!tags) return;

    const groupedTags = groupTagsByType(tags);
    const tagsFilter = EMPTY_BASE_FILTERS.tags;
    tagsFilter.criteria = Object.entries(
      groupedTags,
    ).reduce<FilterCriteriaTagType>((acc, [tagType, tags]) => {
      acc[tagType] = {
        tags,
        icon: <TagIcon className="h-4 w-4" />,
        label:
          tagType === "USER_DEFINED" ? "Your Tags" : formatTagType(tagType),
      };
      return acc;
    }, {});

    updateBaseFilterOptionKey("tags", tagsFilter);
  }, [updateBaseFilterOptionKey, tags]);

  useEffect(() => {
    if (!people) return;
    const peopleFilter = EMPTY_BASE_FILTERS.people;
    peopleFilter.criteria = people.map((person) => ({
      id: person.id,
      value: person.name,
      label: person.name
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" "),
      thumbnail_url: buildServerUrl(person.thumbnail_path || ""),
      icon: (
        <Avatar size="xs" className="h-3.5 w-3.5">
          <AvatarImage src={buildServerUrl(person.thumbnail_path || "")} />
          <AvatarFallback className="text-[8px]">{person.name}</AvatarFallback>
        </Avatar>
      ),
    }));
    updateBaseFilterOptionKey("people", peopleFilter);
  }, [updateBaseFilterOptionKey, people]);
};
