import React from "react";
import { Tag } from "@/types/filters";
import { FilterBadgeSkeleton } from "../../FilterBadgeSkeleton";
import {
  getTagTypeDefinition,
  TagTypeDefinition,
} from "@/utils/constants/tags";
import { LucideIcon } from "lucide-react";

interface FilterBadgeContentsTagProps {
  tag: Tag;
  showIcon?: boolean;
  tagTypeDefinition: TagTypeDefinition;
}

const FilterBadgeContentsTag = ({
  tag,
  tagTypeDefinition,
  showIcon = true,
}: FilterBadgeContentsTagProps) => {
  const Icon = tagTypeDefinition.icon as LucideIcon;
  return (
    <div className="flex items-center gap-2 text-xs">
      <div className="flex items-center gap-1">
        {showIcon && (
          <div className="h-4 w-4">
            <Icon className="h-4 w-4" />
          </div>
        )}
        <div>{tag.value}</div>
      </div>
    </div>
  );
};

interface FilterBadgeTagProps {
  tag: Tag;
  displayOnly?: boolean;
  focused?: boolean;
  showIcon?: boolean;
}

const FilterBadgeTag = ({
  tag,
  displayOnly = false,
  focused = true,
  showIcon = true,
}: FilterBadgeTagProps) => {
  const tagTypeDefinition = getTagTypeDefinition(tag.tag_type);
  return (
    <FilterBadgeSkeleton
      variant={displayOnly ? "displayOnly" : "default"}
      focus={focused ? "focused" : "unfocused"}
      // onClose={() => {
      //   updateFilterState(filterStateType, "tags", [tag.id]);
      // }}
      baseColor={tagTypeDefinition.baseColor}
    >
      <FilterBadgeContentsTag
        tag={tag}
        tagTypeDefinition={tagTypeDefinition}
        showIcon={showIcon}
      />
    </FilterBadgeSkeleton>
  );
};

export { FilterBadgeTag };
