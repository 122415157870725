import { useFrameRate } from "../../context/FramerateContext";
import { useClipMoment } from "../../context/ClipMomentsContext";
import { cn } from "../../utils/cn";
import { formatTime, useMediaStore } from "@vidstack/react";
import { useState } from "react";
import Timecode from "smpte-timecode";

export const TimeWithClip = () => {
  const [showClipTime, setShowClipTime] = useState<boolean>(true);
  const { isClipMomentActive } = useClipMoment();
  const { realCurrentTime, clipEndTime, clipStartTime, intrinsicDuration } =
    useMediaStore();

  const useClipTime = showClipTime && isClipMomentActive;

  return (
    <span
      onClick={
        isClipMomentActive ? () => setShowClipTime((prev) => !prev) : undefined
      }
      className={cn(
        "inline-flex h-6 items-center gap-1 px-2 text-xs text-neutral-500 antialiased transition-colors",
        isClipMomentActive && "cursor-pointer hover:bg-neutral-500/20",
        useClipTime && "text-blue-400/70",
      )}
    >
      <span className={cn("text-grey-300", useClipTime && "text-blue-300/70")}>
        {formatTime(
          useClipTime ? realCurrentTime - clipStartTime : realCurrentTime,
          {
            padHrs: true,
            padMins: true,
          },
        )}
      </span>
      <span>{"/"}</span>
      <span className="font-medium ">
        {formatTime(
          useClipTime ? clipEndTime - clipStartTime : intrinsicDuration,
          {
            padHrs: true,
            padMins: true,
          },
        )}
      </span>
    </span>
  );
};

export const Times = () => {
  const { realCurrentTime, intrinsicDuration } = useMediaStore();
  const frameRate = useFrameRate();
  const framesPerSecond = frameRate[0] / frameRate[1];

  const currentFrame = realCurrentTime * framesPerSecond;

  // Pass frameRate directly to Timecode
  const timecode = new Timecode(currentFrame, frameRate);

  return (
    <span className="inline-flex gap-1 text-xs tabular-nums text-neutral-500 antialiased">
      <span className="text-neutral-400">{timecode.toString()}</span>
      <span>{"/"}</span>
      <span>
        {formatTime(intrinsicDuration, {
          padHrs: true,
          padMins: true,
          showHrs: true,
        })}
      </span>
    </span>
  );
};
