import { cn } from "@/utils/tailwind";

interface TabProps {
  icon: React.ReactNode;
  label?: string;
  value: string;
  onClick?: () => void;
  isActive?: boolean;
}

export const Tab: React.FC<TabProps> = ({
  icon,
  label,
  onClick,
  isActive = false,
}) => {
  return (
    <div
      onClick={onClick}
      className={cn(
        "group flex h-5 cursor-pointer items-center justify-start gap-1 text-xs transition-all",
        isActive ? "text-white" : "text-neutral-600 hover:text-white",
      )}
    >
      <div
        className={cn(
          "flex h-4 w-4 items-center",
          isActive
            ? "text-indigo-500"
            : "text-neutral-500 group-hover:text-indigo-500",
        )}
      >
        {icon}
      </div>
      {label && <div>{label}</div>}
    </div>
  );
};
