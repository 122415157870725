export const isInViewport = (
  element: Element | null,
  viewportRef: React.RefObject<HTMLDivElement>,
) => {
  if (!element) {
    return false;
  }

  const viewport = viewportRef?.current;
  if (!viewport) {
    // Fallback to window if no custom viewport is provided
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  const viewportRect = viewport.getBoundingClientRect();
  const elementRect = element.getBoundingClientRect();

  return (
    elementRect.top >= viewportRect.top &&
    elementRect.left >= viewportRect.left &&
    elementRect.bottom <= viewportRect.bottom &&
    elementRect.right <= viewportRect.right
  );
};
