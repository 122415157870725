"use client";

import { forwardRef, HTMLAttributes, type ReactNode } from "react";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@kino/ui";

interface StatusCardProps extends HTMLAttributes<HTMLDivElement> {
  icon: ReactNode;
  title: string;
  description: string | ReactNode;
  children: ReactNode;
}

const StatusCard = forwardRef<HTMLDivElement, StatusCardProps>(
  ({ icon, title, description, children, ...props }, ref) => {
    return (
      <Card
        ref={ref}
        className="flex w-full max-w-[500px] flex-col items-start gap-3 rounded-md border bg-white p-4 shadow-sm"
        {...props}
      >
        <CardHeader className="flex w-full flex-col gap-2 text-gray-700">
          <div className="h-10 w-10">{icon}</div>
          <CardTitle>{title}</CardTitle>
          <CardDescription className="text-xs">{description}</CardDescription>
        </CardHeader>
        <CardContent className="flex w-full flex-col">{children}</CardContent>
      </Card>
    );
  },
);

StatusCard.displayName = "StatusCard";

export { StatusCard };
