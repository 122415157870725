import { useTheme } from "@/context/theme-provider";
import { ToggleGroup, ToggleGroupItem } from "@kino/ui";
import { MoonIcon, SunIcon } from "@radix-ui/react-icons";

export const ThemeSwitcher = () => {
  const { theme, setTheme } = useTheme();
  return (
    <ToggleGroup type="single" value={theme} onValueChange={setTheme}>
      <ToggleGroupItem value="light" aria-label="Toggle light">
        <SunIcon className="h-4 w-4" />
      </ToggleGroupItem>
      <ToggleGroupItem value="dark" aria-label="Toggle dark">
        <MoonIcon className="h-4 w-4" />
      </ToggleGroupItem>
    </ToggleGroup>
  );
};
