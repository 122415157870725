import { SignOutButton } from "@clerk/clerk-react";
import { Button } from "@kino/ui";
import { useLocation } from "react-router-dom";

export const NoOrganizationPage = () => {
  const location = useLocation();
  const {
    reason = "You're not part of any organization",
    direction = "Please get added to one to use Kino for Enterprise.",
  } = location.state || {};

  return (
    <div className="flex h-screen items-center justify-center">
      <div className="rounded-lg p-8 text-center">
        <h1 className="mb-8 text-2xl font-semibold">{reason}</h1>
        <p className="mb-0 text-neutral-400">
          {direction}
          <br />
          <br />
          <strong>Reminder:</strong> Check your email for an invitation to join Kino AI.
          <br />
          <br />
          For assistance, please contact{" "}
          <a href="mailto:support@kino.ai">support@kino.ai</a>.
        </p>
        <br />
        <SignOutButton redirectUrl="/">
          <Button className="bg-indigo-600 text-white">Sign Out</Button>
        </SignOutButton>
      </div>
    </div>
  );
};
