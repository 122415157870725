import { useClient } from "@/hooks/useClient";
import { useDebuggerStore } from "@/store/debuggerStore";
import dummyAllMediaFilesResponse from "@/api/dummyData/v2/dummyAllMediaFilesResponse.json";
import { components } from "@/openapi-bindings/v2";
import { queryClient } from "@/hooks/useClient";
import { useOrganization } from "@clerk/clerk-react";
import { useMediaLoggingEnabled } from "@/hooks/useMediaLoggingEnabled";

export function useAllMedia() {
  const isMockMediaEndpoint = useDebuggerStore(
    (state) => state.isMockMediaEndpoint,
  );
  const { apiClient } = useClient();
  const enable_media_logging = useMediaLoggingEnabled();

  return apiClient.useQuery(
    "post",
    "/all_media",
    {
      body: { limit: 100, enable_media_logging },
    },
    {
      enabled: !isMockMediaEndpoint,
      placeholderData: isMockMediaEndpoint
        ? (dummyAllMediaFilesResponse as unknown as components["schemas"]["AllMediaResponse"])
        : undefined,
    },
    queryClient,
  );
}
