import { MIN_WINDOW_SECONDS } from "../constants";
import { usePlayerZoomController } from "../context/ZoomControllerContext";
import { useMediaState } from "@vidstack/react";
import { RefObject, useEffect } from "react";

export const useZoomGestures = (sliderRef: RefObject<HTMLElement>) => {
  const intrinsicDuration = useMediaState("intrinsicDuration");
  const { playerZoom, setPlayerZoom } = usePlayerZoomController();

  useEffect(() => {
    const slider = sliderRef.current;

    if (!slider) {
      return;
    }

    const moveHandles = (e: WheelEvent): void => {
      e.preventDefault();

      let newStart;
      let newEnd;

      if (
        Math.abs(e.deltaX) &&
        Math.abs(e.deltaY) &&
        Math.abs(e.deltaX) - Math.abs(e.deltaY) < 1
      ) {
        return;
      }

      if (Math.abs(e.deltaX) >= Math.abs(e.deltaY)) {
        const startWithDelta = playerZoom.start + e.deltaX;
        const clampedStartWithDelta = Math.max(0, startWithDelta);
        const clampedStartDelta = clampedStartWithDelta - playerZoom.start;

        const endWithDelta = playerZoom.end + e.deltaX;
        const clampedEndWithDelta = Math.min(endWithDelta, intrinsicDuration);
        const clampedEndDelta = clampedEndWithDelta - playerZoom.end;

        const clampedDeltaX =
          Math.abs(clampedStartDelta) > Math.abs(clampedEndDelta)
            ? clampedEndDelta
            : clampedStartDelta;

        newStart = playerZoom.start + clampedDeltaX;
        newEnd = playerZoom.end + clampedDeltaX;
      } else {
        newStart = Math.max(0, playerZoom.start - e.deltaY / 2);
        newEnd = Math.min(playerZoom.end + e.deltaY / 2, intrinsicDuration);
      }

      if (newEnd - newStart < MIN_WINDOW_SECONDS) {
        return;
      }

      if (playerZoom.start <= 0 && Math.sign(e.deltaX) === -1) {
        return;
      }

      if (playerZoom.end >= intrinsicDuration && Math.sign(e.deltaX) === 1) {
        return;
      }

      setPlayerZoom({ start: newStart, end: newEnd });
    };

    slider.addEventListener("wheel", moveHandles);

    return () => {
      if (slider) {
        slider.removeEventListener("wheel", moveHandles);
      }
    };
  }, [
    sliderRef,
    playerZoom.start,
    playerZoom.end,
    setPlayerZoom,
    intrinsicDuration,
  ]);
};
