import React, { useMemo } from "react";
import TagsList from "./TagsList";
import { Button, CommandGroup } from "@kino/ui";
import { useSecondaryFilterPanelStore } from "@/store/filter/secondaryFilterPanelStore";
import { components } from "@/openapi-bindings/v2";
import { formatTagTypeLabel } from "@/utils/constants/tags";

interface TagsFilterSectionProps {
  tags: components["schemas"]["Tag"][];
  tagType: string;
  search: string;
  label: string;
}

const TagsFilterSection: React.FC<TagsFilterSectionProps> = ({
  tags,
  tagType,
  search,
}) => {
  const { openPanel } = useSecondaryFilterPanelStore();

  const filteredTags = useMemo(() => {
    if (!search) return tags.slice(0, 3);
    return tags.filter((tag) =>
      tag.value.toLowerCase().includes(search.toLowerCase()),
    );
  }, [tags, search]);

  const handleOpenPanel = () => {
    openPanel({ key: "tags", tagType: tagType });
  };

  return (
    <CommandGroup>
      <div className="flex items-center justify-between">
        <div className="py-2 text-xs text-neutral-500">
          {formatTagTypeLabel(tagType)}
        </div>
        <div className="flex items-center gap-2">
          <Button
            variant="link"
            size="sm"
            className={`text-xs font-normal text-indigo-500`}
            onClick={handleOpenPanel}
          >
            All ({tags.length})
          </Button>
        </div>
      </div>
      <TagsList tags={filteredTags} />
    </CommandGroup>
  );
};

export default TagsFilterSection;
