import {
  ContextMenu as RadixContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuSeparator,
  ContextMenuShortcut,
  ContextMenuSub,
  ContextMenuSubContent,
  ContextMenuSubTrigger,
  ContextMenuTrigger,
} from "@kino/ui";
import { type ReactNode } from "react";
import { ReloadIcon, MixerHorizontalIcon } from "@radix-ui/react-icons";

export interface MenuItem {
  label: string;
  onClick?: (e: React.MouseEvent) => void;
  icon?: React.ReactNode;
  // Determines what shortcut keys should be displayed to the right of the label
  shortcut?: string;
  // Determines whether to add a separator after an item or not
  addSeparator?: boolean;
  // Whether this item should be hidden, used for custom logic
  hidden?: boolean;
  submenu?: MenuItem[];
}

interface ContextMenuProps {
  children?: ReactNode;
  customMenuItems?: MenuItem[];
}

const RecursiveMenuItem: React.FC<{ item: MenuItem; index: number }> = ({
  item,
  index,
}) => {
  return (
    <div key={index}>
      {item.submenu ? (
        <ContextMenuSub>
          <ContextMenuSubTrigger
            inset
            onClick={(e) => {
              e.stopPropagation();
              item.onClick?.(e);
            }}
          >
            {item.icon && (
              <div className="absolute left-0 inline-flex w-[25px] items-center justify-center">
                {item.icon}
              </div>
            )}
            {item.label}
            {item.shortcut && (
              <ContextMenuShortcut>{item.shortcut}</ContextMenuShortcut>
            )}
          </ContextMenuSubTrigger>
          <ContextMenuSubContent>
            {item.submenu.map((subItem, subIndex) => (
              <RecursiveMenuItem
                key={`submenuItem${subIndex}`}
                item={subItem}
                index={subIndex}
              />
            ))}
          </ContextMenuSubContent>
        </ContextMenuSub>
      ) : (
        <ContextMenuItem
          inset
          onClick={(e) => {
            e.stopPropagation();
            item.onClick?.(e);
          }}
          key={`customMenuItem${index}`}
        >
          {item.icon && <div>{item.icon}</div>}
          {item.label}
          {item.shortcut && (
            <ContextMenuShortcut>{item.shortcut}</ContextMenuShortcut>
          )}
        </ContextMenuItem>
      )}
      {item.addSeparator && <ContextMenuSeparator />}
    </div>
  );
};

const ContextMenu: React.FC<ContextMenuProps> = ({
  children,
  customMenuItems = [],
}) => {
  const handleReload = () => {
    window.location.reload();
  };

  const isDevelopment =
    import.meta.env.DEV ||
    import.meta.env.VITE_VERCEL_ENV === "preview" ||
    import.meta.env.VITE_VERCEL_ENV === "development";

  const defaultMenuItems = (
    <>
      <ContextMenuItem inset onClick={handleReload}>
        <ReloadIcon />
        Reload
        <ContextMenuShortcut>⌘R</ContextMenuShortcut>
      </ContextMenuItem>
      {isDevelopment && (
        <ContextMenuItem inset onClick={() => {}}>
          <MixerHorizontalIcon />
          Open Dev Tools via shortcut
          <ContextMenuShortcut>⌘⌥I</ContextMenuShortcut>
        </ContextMenuItem>
      )}
    </>
  );

  return (
    <RadixContextMenu>
      <ContextMenuTrigger asChild>{children}</ContextMenuTrigger>
      <ContextMenuContent className="w-64 border border-neutral-800 bg-neutral-900 bg-opacity-80 text-neutral-400 shadow-lg shadow-black/30 backdrop-blur-lg">
        {customMenuItems.length === 0 ? (
          defaultMenuItems
        ) : (
          <>
            {customMenuItems.map(
              (item, index) =>
                !item.hidden && (
                  <RecursiveMenuItem
                    key={`customMenuItem${index}`}
                    item={item}
                    index={index}
                  />
                ),
            )}
            {defaultMenuItems}
          </>
        )}
      </ContextMenuContent>
    </RadixContextMenu>
  );
};

export default ContextMenu;
