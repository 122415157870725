import { Spinner } from "./spinner"; // Make sure to import the Spinner component
import {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
  TooltipProvider,
} from "./tooltip";
import { forwardRef, type MouseEventHandler } from "react";
import { cn } from "../utils/tailwind";

export type ClickableIconProps = {
  Icon: React.ComponentType<any>;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  name: string;
  tooltip?: string;
  cursor?: "cursor-pointer" | "cursor-default";
  className?: string;
  delayDuration?: number;
  disabled?: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  loading?: boolean; // New prop for loading state
};

const ClickableIcon = forwardRef<HTMLButtonElement, ClickableIconProps>(
  (
    {
      Icon,
      onClick,
      name,
      tooltip,
      cursor = "cursor-pointer",
      className,
      delayDuration,
      disabled,
      onMouseEnter,
      onMouseLeave,
      loading = false,
    },
    ref,
  ) => (
    <TooltipProvider>
      <Tooltip delayDuration={delayDuration}>
        <TooltipTrigger asChild>
          <button
            ref={ref}
            disabled={disabled}
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            className={cn(
              "flex items-center justify-center",
              onClick && !cursor ? "cursor-pointer" : "cursor-default",
              cursor,
              className,
            )}
          >
            {loading ? (
              <Spinner variant="puff" className="h-6 w-6" />
            ) : (
              <Icon className="h-4 w-4" />
            )}
            <span className="sr-only">{name}</span>
          </button>
        </TooltipTrigger>
        {tooltip && <TooltipContent>{tooltip}</TooltipContent>}
      </Tooltip>
    </TooltipProvider>
  ),
);

ClickableIcon.displayName = "ClickableIcon";

export { ClickableIcon };
