import { useOrganization } from "@clerk/clerk-react";

export function useMediaLoggingEnabled() {
  const { organization } = useOrganization();
  const organizationName = organization?.id;
  let enableMediaLogging = false;

  if (
    organizationName === "org_2oGoNNJUjWrVqLXHImgSi3pbs0u" ||
    organizationName === "org_2oGRFt8QEIXnjYItS6exCHonrsk"
  ) {
    enableMediaLogging = true;
  }
  return enableMediaLogging;
}
