import {
  useMediaPlayer,
  useMediaStore,
  useMediaState,
  type MediaSeekRequestEvent,
  MediaPlayEvent,
} from "@vidstack/react";
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

export interface PlayerZoom {
  start: number;
  end: number;
}

export interface IPlayerZoomControllerContext {
  playerZoom: PlayerZoom;
  setPlayerZoom: (playerZoom: PlayerZoom) => void;
}

const PlayerZoomControllerContext = createContext<
  IPlayerZoomControllerContext | undefined
>(undefined);

interface PlayerZoomControlProps extends PropsWithChildren {
  initialZoom?: PlayerZoom;
  trackCurrentTime?: boolean;
}

const MINIMUM_WINDOW = 3;
export const PlayerZoomControllerProvider: React.FC<PlayerZoomControlProps> = ({
  children,
  initialZoom = { start: 0, end: Infinity },
  trackCurrentTime = false,
}) => {
  const player = useMediaPlayer();
  const { intrinsicDuration, clipStartTime } = useMediaStore();

  const usingDefaultZoom = useRef<boolean>(true);
  const [playerZoom, setPlayerZoom] = useState<PlayerZoom>(initialZoom);
  const [isTrackingCurrentTime, setIsTrackingCurrentTime] =
    useState<boolean>(trackCurrentTime);

  const currentTime = useMediaState("realCurrentTime");

  const zoomDuration = playerZoom.end - playerZoom.start;

  useEffect(() => {
    if (!intrinsicDuration) return;
    if (!usingDefaultZoom.current) return;

    setPlayerZoom((prev) => {
      if (Number.isFinite(prev.end)) return prev;

      return { ...prev, end: intrinsicDuration };
    });
  }, [intrinsicDuration]);

  useEffect(() => {
    if (!usingDefaultZoom.current) {
      return;
    }

    setPlayerZoom({
      start: initialZoom.start,
      end: initialZoom.end,
    });
  }, [initialZoom.start, initialZoom.end]);

  const makeClampedZoom = useCallback(
    (start: number, end?: number) => {
      const clampedStart = Math.max(0, start);
      const clampedEnd = Math.min(
        intrinsicDuration,
        end || start + zoomDuration,
      );

      const adjustedEnd = clampedEnd + (clampedStart - start);

      return {
        start: clampedStart,
        end: adjustedEnd,
      };
    },
    [intrinsicDuration, zoomDuration],
  );

  useEffect(() => {
    if (!player) {
      return;
    }

    const handleSeekRequest = (e: MediaSeekRequestEvent) => {
      if (!e.triggers.hasType(ZOOM_EVENT_TYPE)) {
        return;
      }

      setPlayerZoom(makeClampedZoom(e.detail - 1));
    };

    player.addEventListener(
      "media-seek-request",
      handleSeekRequest as EventListenerOrEventListenerObject,
    );

    return () => {
      player.removeEventListener(
        "media-seek-request",
        handleSeekRequest as EventListenerOrEventListenerObject,
      );
    };
  }, [player, makeClampedZoom]);

  const handlePlay = useCallback(
    (e: MediaPlayEvent) => {
      const currentTime = e.target.currentTime + (clipStartTime || 0);

      setIsTrackingCurrentTime(trackCurrentTime);

      if (playerZoom.start < currentTime && currentTime < playerZoom.end) {
        return;
      }

      setPlayerZoom(makeClampedZoom(currentTime));
    },
    [playerZoom, makeClampedZoom, trackCurrentTime, clipStartTime],
  );

  useEffect(() => {
    if (!player) {
      return;
    }

    player.addEventListener(
      "play",
      handlePlay as EventListenerOrEventListenerObject,
    );

    return () =>
      player.removeEventListener(
        "play",
        handlePlay as EventListenerOrEventListenerObject,
      );
  }, [player, handlePlay]);

  useEffect(() => {
    if (!isTrackingCurrentTime) {
      return;
    }

    if (currentTime >= playerZoom.end) {
      setPlayerZoom({
        start: playerZoom.end,
        end: playerZoom.end + (playerZoom.end - playerZoom.start),
      });
    }
  }, [
    trackCurrentTime,
    isTrackingCurrentTime,
    currentTime,
    playerZoom.start,
    playerZoom.end,
    player,
  ]);

  const value = {
    playerZoom,
    setPlayerZoom: (zoom: PlayerZoom) => {
      usingDefaultZoom.current = false;

      const start = Math.min(
        Math.max(zoom.start, 0),
        Math.max(0, intrinsicDuration - MINIMUM_WINDOW),
      );
      const end = Math.max(
        Math.min(zoom.end, intrinsicDuration),
        Math.min(intrinsicDuration, 0 + MINIMUM_WINDOW),
      );

      setPlayerZoom({
        start,
        end,
      });

      setIsTrackingCurrentTime(
        trackCurrentTime && start < currentTime && currentTime < end,
      );
    },
  };

  return (
    <PlayerZoomControllerContext.Provider value={value}>
      {children}
    </PlayerZoomControllerContext.Provider>
  );
};

export const useCurrentZoom = (): PlayerZoom => {
  const context = useContext(PlayerZoomControllerContext);

  if (!context) {
    throw new Error(
      "usePlayerZoom must be used within a PlayerZoomControllerContext",
    );
  }

  return context.playerZoom;
};

export const usePlayerZoomController = (): IPlayerZoomControllerContext => {
  const context = useContext(PlayerZoomControllerContext);

  if (!context) {
    throw new Error(
      "usePlayerZoomController must be used within a PlayerZoomControllerContext",
    );
  }

  return context;
};

export const ZOOM_EVENT_TYPE = "zoom-change";
