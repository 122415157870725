import React from "react";
import {
  Command,
  CommandInput,
  CommandList,
  CommandEmpty,
  CommandGroup,
} from "@kino/ui";
import { SecondaryFilterPanelContent } from "../SecondaryFilterPanel";
import { useFilterOptionsStore } from "@/store/filter/filterOptionsStore";
import { FocusedSecondaryPanelFilter } from "@/store/filter/secondaryFilterPanelStore";
import FilterArrayList from "./FilterArrayList";
import { isArrayFilterKey } from "@/utils/filter/filterTypeGuards";

interface ArraySecondaryFilterPanelContentProps {
  focusedFilter: FocusedSecondaryPanelFilter;
  onClose: () => void;
}

const ArraySecondaryFilterPanelContent: React.FC<
  ArraySecondaryFilterPanelContentProps
> = ({ focusedFilter }) => {
  const { filterOptions } = useFilterOptionsStore();

  if (!isArrayFilterKey(focusedFilter.key)) return null;

  return (
    <SecondaryFilterPanelContent>
      <Command className="flex flex-col gap-2">
        <CommandInput
          searchLoading={false}
          placeholder={`Find a ${filterOptions[focusedFilter.key].singularName}`}
          className="h-7 rounded-md border border-neutral-800 px-1"
        />
        <CommandList className="flex h-full max-h-none flex-col">
          <CommandEmpty>
            No {filterOptions[focusedFilter.key].pluralName} found
          </CommandEmpty>
          <CommandGroup className="flex h-full flex-col pb-2">
            <FilterArrayList
              filterKey={focusedFilter.key}
              filterOptions={filterOptions[focusedFilter.key].criteria || []}
            />
          </CommandGroup>
        </CommandList>
      </Command>
    </SecondaryFilterPanelContent>
  );
};

export default ArraySecondaryFilterPanelContent;
