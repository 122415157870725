import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "../utils/tailwind";

const badgeVariants = cva(
  "cursor-default inline-flex items-center rounded-md border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
  {
    variants: {
      variant: {
        default:
          "border-transparent bg-gray-500 text-primary-foreground shadow hover:bg-primary/80",
        secondary:
          "border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80",
        destructive:
          "border-transparent bg-destructive text-destructive-foreground shadow hover:bg-destructive/80",
        outline: "text-foreground",
        tag: "border-[0.5px] bg-white border-gray-300 text-gray-500 font-normal rounded-full",
        tagResult:
          "w-fit bg-black bg-opacity-30 text-[8px] text-white rounded border-[0.5px] font-normal h-4",
      },
      size: {
        default: "h-9 px-4 py-2",
        defaultCircle: "h-9 px-4 py-2 rounded-full",
        xsm: "h-7 w-7 rounded-md  text-xs",
        sm: "h-8 rounded-md px-3 text-xs",
        lg: "h-10 rounded-md px-8",
        icon: "h-9 w-9",
        footer: "h-6 px-4 py-2 rounded-sm",
        tag: "h-6 px-2",
        tagResult: "px-1 py-[2px]",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, size, ...props }: BadgeProps) {
  return (
    <div
      className={cn(badgeVariants({ variant, size }), className)}
      {...props}
    />
  );
}

export { Badge, badgeVariants };
