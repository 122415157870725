import React, { useState, useEffect, useRef } from "react";
import styles from "./ResultsGrid.module.css";

interface ResultsGridProps {
  children: React.ReactNode;
  truncate?: boolean;
}

const ResultsGrid = ({ children, truncate = false }: ResultsGridProps) => {
  const [columnCount, setColumnCount] = useState(1);
  const gridRef = useRef<HTMLDivElement>(null);

  // We are using this instead of media queries because we're dealing with panels.
  useEffect(() => {
    const updateColumnCount = () => {
      if (!gridRef.current) return;

      const gridWidth = gridRef.current.offsetWidth;

      if (gridWidth >= 1920) setColumnCount(6);
      else if (gridWidth >= 1280)
        setColumnCount(5); // xl
      else if (gridWidth >= 1024)
        setColumnCount(4); // lg
      else if (gridWidth >= 768)
        setColumnCount(3); // md
      else if (gridWidth >= 640)
        setColumnCount(3); // sm
      else if (gridWidth >= 400)
        setColumnCount(2); // xsm
      else setColumnCount(1); // xs
    };

    updateColumnCount();

    const resizeObserver = new ResizeObserver(updateColumnCount);
    if (gridRef.current) {
      resizeObserver.observe(gridRef.current);
    }

    return () => {
      if (gridRef.current) {
        resizeObserver.unobserve(gridRef.current);
      }
    };
  }, []);

  const visibleChildren = truncate
    ? React.Children.toArray(children).slice(0, columnCount)
    : React.Children.toArray(children);

  return (
    <div
      ref={gridRef}
      className={styles.resultsGrid}
      style={{ "--column-count": columnCount } as React.CSSProperties}
    >
      {visibleChildren}
    </div>
  );
};

export default ResultsGrid;
