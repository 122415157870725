import { Gesture } from "@vidstack/react";

export const DefaultGestures = () => (
  <>
    <PlayPauseGesture />
    <FullScreenGesture />
  </>
);

export const PlayPauseGesture = () => (
  <Gesture
    className="absolute inset-0 z-0 block h-full w-full"
    event="pointerup"
    action="toggle:paused"
  />
);

export const FullScreenGesture = () => (
  <Gesture
    className="absolute inset-0 z-0 block h-full w-full"
    event="dblpointerup"
    action="toggle:fullscreen"
  />
);
