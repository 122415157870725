import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ContextMenu, { type MenuItem } from "@/components/ContextMenu";
import { CopyIcon } from "@radix-ui/react-icons";
import { toast } from "sonner";
import { useVirtualizer } from "@tanstack/react-virtual";
import useSmoothScroll from "@/hooks/useSmoothScroll";
import TranscriptSegmentTextArea from "@/components/transcript/TranscriptSegmentTextArea";
import { useMediaStore, usePrimaryViewportContext } from "@kino/player";
import { components } from "@/openapi-bindings/v2";
import { useActiveTranscriptStore } from "@/store/transcript/activeTranscriptStore";
import { isInViewport } from "@/utils/domUtils";
import { Button } from "@kino/ui";
import processSegmentsWithGaps, { ProcessedSegment } from "@/utils/transcript";
import { SelectedMediaItem } from "@/store/selectedMediaStore";

const VIRTUALIZER_OVERSCAN_AMOUNT = 5;
const VIRTUALIZER_SIZE_ESTIMATE = 90;

interface TranscriptTextEditorProps {
  activeInspectorItem: SelectedMediaItem;
  viewportRef: React.RefObject<HTMLDivElement>;
}

export const TranscriptTextEditor = ({
  activeInspectorItem,
  viewportRef,
}: TranscriptTextEditorProps) => {
  const customEditorContextMenuItems = (fullText: string): MenuItem[] => [
    {
      label: "Copy segment to clipboard",
      onClick: async () => {
        await navigator.clipboard.writeText(fullText);
        toast.success("Copied transcript segment to clipboard", {
          duration: 1000,
        });
      },
      icon: <CopyIcon />,
      addSeparator: true,
    },
  ];

  const mediaItem = activeInspectorItem.mediaItem;
  const moment = activeInspectorItem?.activeInspectorMoment?.moment;
  const id = activeInspectorItem.id;

  const allTranscriptsLoading = false;
  const { currentSegmentIndex, setCurrentSegmentIndex } =
    useActiveTranscriptStore();
  const { playerRef } = usePrimaryViewportContext();
  const { currentTime, clipStartTime } = useMediaStore(playerRef);
  const absoluteCurrentTime = currentTime + clipStartTime;
  const initialEditedSegments: components["schemas"]["Segment"][] =
    mediaItem.transcript?.segments ?? [];
  const filledSegments: ProcessedSegment[] = processSegmentsWithGaps(
    initialEditedSegments,
    Number(mediaItem.ffprobe_data?.format?.duration),
  );
  const fullText =
    mediaItem.transcript?.segments.map((segment) => segment.text).join("\n") ??
    "";
  const isTranscriptEmpty = initialEditedSegments.length === 0;

  const [rightClickedIndex, setRightClickedIndex] = useState<number | null>(
    null,
  );
  const [isAutoScroll, setIsAutoScroll] = useState(false);
  const contextMenuItems = customEditorContextMenuItems(fullText);
  const parentRef = useRef<HTMLDivElement | null>(null);

  // The virtualizer
  const rowVirtualizer = useVirtualizer({
    count: filledSegments.length,
    getScrollElement: useCallback(() => viewportRef.current, [viewportRef]),
    estimateSize: useCallback(() => VIRTUALIZER_SIZE_ESTIMATE, []),
    overscan: VIRTUALIZER_OVERSCAN_AMOUNT,
  });

  useEffect(() => {
    const newIndex = filledSegments.findIndex((segment) => {
      const startInSeconds = segment.start;
      const endInSeconds = segment.end;
      return (
        absoluteCurrentTime >= startInSeconds &&
        absoluteCurrentTime < endInSeconds
      );
    });

    if (newIndex !== currentSegmentIndex) {
      setCurrentSegmentIndex(newIndex);
    }
  }, [
    filledSegments,
    absoluteCurrentTime,
    currentSegmentIndex,
    setCurrentSegmentIndex,
  ]);

  const smoothScrollToIndex = useSmoothScroll(rowVirtualizer, viewportRef);
  const [isSegmentInViewport, setIsSegmentInViewport] = useState(false);

  useEffect(() => {
    // prevent autoscroll when typing in the contenteditable span
    const shouldScrollToActiveSegment = () => {
      if (!isAutoScroll) return;
      const activeElement = document.activeElement;
      const segmentElement = document.querySelector(
        `#segment-${currentSegmentIndex}`,
      );
      const isInViewportResult = isInViewport(segmentElement, viewportRef);

      if (
        activeElement instanceof HTMLInputElement ||
        (activeElement instanceof HTMLSpanElement &&
          activeElement.isContentEditable) ||
        isInViewportResult
      ) {
        return false;
      }
      return true;
    };

    const scrollToActiveSegment = () => {
      if (currentSegmentIndex !== -1 && shouldScrollToActiveSegment()) {
        smoothScrollToIndex(currentSegmentIndex, {
          align: "start",
          duration: 100,
        });
      }
    };

    scrollToActiveSegment();
  }, [
    rowVirtualizer,
    currentSegmentIndex,
    isInViewport,
    smoothScrollToIndex,
    isAutoScroll,
  ]);

  const virtualItems = rowVirtualizer.getVirtualItems();

  // Measure virtual item on size change
  useEffect(() => {
    rowVirtualizer.measure();
  }, [rowVirtualizer]);

  // Force recalculate range
  // https://github.com/TanStack/virtual/issues/485
  useMemo(() => {
    rowVirtualizer.calculateRange();

    return null;
  }, [rowVirtualizer]);

  // const { speakerDiarization } = useBackgroundProcesses();

  useEffect(() => {
    const handleWheel = (event: WheelEvent) => {
      if (event.deltaMode === WheelEvent.DOM_DELTA_PIXEL) {
        setIsAutoScroll(false);
      }
    };

    const scrollElement = viewportRef.current;
    if (scrollElement) {
      scrollElement.addEventListener("wheel", handleWheel, { passive: true });
    }

    return () => {
      if (scrollElement) {
        scrollElement.removeEventListener("wheel", handleWheel);
      }
    };
  }, [viewportRef]);

  useEffect(() => {
    setIsAutoScroll(true);
  }, [id]);

  return (
    <>
      {isTranscriptEmpty && !allTranscriptsLoading && (
        <div className="p-2 text-xs text-neutral-600">
          No transcript detected
        </div>
      )}
      {isTranscriptEmpty && allTranscriptsLoading && (
        <div className="p-2 text-xs text-neutral-600">
          Loading transcript...
        </div>
      )}
      <div ref={parentRef}>
        <ContextMenu customMenuItems={contextMenuItems}>
          <div style={{ height: `${rowVirtualizer.getTotalSize()}px` }}>
            {!isTranscriptEmpty && mediaItem && (
              <div
                style={{
                  transform: `translateY(${
                    virtualItems[0] && virtualItems[0].start
                  }px)`,
                }}
                className="divide-y"
              >
                {virtualItems.map((virtualItem) => {
                  const segment = filledSegments[virtualItem.index];
                  if (!segment) return null;
                  const { isFiller, ...segmentWithoutFiller } = segment;
                  return (
                    <div
                      className="relative"
                      ref={rowVirtualizer.measureElement}
                      key={virtualItem.key}
                      data-index={virtualItem.index}
                      onContextMenu={() => {
                        setRightClickedIndex(virtualItem.index);
                      }}
                    >
                      <TranscriptSegmentTextArea
                        editedSegment={segmentWithoutFiller}
                        index={virtualItem.index}
                        key={`${id}-${virtualItem.index}`}
                        isSelected={
                          absoluteCurrentTime >= segmentWithoutFiller.start &&
                          absoluteCurrentTime < segmentWithoutFiller.end
                        }
                        isHighlighted={
                          !!moment?.start &&
                          moment.start >= segmentWithoutFiller.start &&
                          moment.start < segmentWithoutFiller.end
                        }
                        setSelected={() => {}}
                        showOptions={false}
                        isFiller={isFiller}
                      />
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </ContextMenu>
      </div>
      {!isAutoScroll && (
        <Button
          onClick={() => setIsAutoScroll(true)}
          variant="outline"
          size="sm"
          className="absolute bottom-4 right-4"
        >
          Autoscroll
        </Button>
      )}
    </>
  );
};
