import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "@kino/ui/dist/output.css";
import "@kino/player/main.css";
import "@glideapps/glide-data-grid/dist/index.css";
import "@/index.css";

import KinoWrapper from "@/layouts/KinoWrapper";
import RootLayout from "@/layouts/Root";

import MediaPage from "@/routes/media";
import OmnisearchPage from "@/routes/omnisearch";
import SignInPage from "@/routes/sign-in";
import IndexPage from "@/routes/welcome";

import RouterErrorBoundary from "@components/ErrorBoundary";
import { NoOrganizationPage } from "./routes/no-organization";
import ProtectedRoute from "./layouts/ProtectedRoute";

const router = createBrowserRouter([
  {
    element: <RootLayout />,
    errorElement: <RouterErrorBoundary />,
    children: [
      { path: "/", element: <IndexPage /> },
      { path: "/sign-in/*", element: <SignInPage /> },
      { path: "/no-access", element: <NoOrganizationPage /> },
      {
        element: (
          <ProtectedRoute>
            <KinoWrapper />
          </ProtectedRoute>
        ),
        path: "search",
        children: [{ path: "/search", element: <OmnisearchPage /> }],
      },
      {
        element: (
          <ProtectedRoute>
            <KinoWrapper />
          </ProtectedRoute>
        ),
        path: "media",
        children: [{ path: "/media", element: <MediaPage /> }],
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);
