import { create } from "zustand";
import { persist } from "zustand/middleware";

export type ImportStatus = "importing" | "imported" | "failed";

interface AVBFile {
  id: string;
  filePath: string;
  status: ImportStatus;
  date: string; // ISO string format
}

interface AVBFileStore {
  importedAVBFiles: AVBFile[];
  setImportedAVBFiles: (files: AVBFile[]) => void;
  addImportedAVBFile: (filePath: string, status: ImportStatus) => string;
  updateImportedAVBFile: (id: string, status: ImportStatus) => void;
}

export const useAVBFileStore = create<AVBFileStore>()(
  persist(
    (set) => ({
      importedAVBFiles: [],
      setImportedAVBFiles: (files) => {
        set({ importedAVBFiles: files });
      },
      addImportedAVBFile: (filePath, status) => {
        const id = Date.now().toString(); // Simple unique id generation
        set((state) => ({
          importedAVBFiles: [
            ...state.importedAVBFiles,
            {
              id,
              filePath,
              status,
              date: new Date().toISOString(),
            },
          ],
        }));
        return id;
      },
      updateImportedAVBFile: (id, status) => {
        set((state) => ({
          importedAVBFiles: state.importedAVBFiles.map((file) =>
            file.id === id ? { ...file, status } : file,
          ),
        }));
      },
    }),
    {
      name: "imported-avb-file-storage",
      version: 1,
    },
  ),
);
