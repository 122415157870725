import { useAuth, useOrganization } from "@clerk/clerk-react";
import { ServerWorkspace } from "@/types/workspace";

type OrganizationWorkspaces = {
  isLoaded: boolean;
  hasWorkspaces: boolean;
  workspaces: ServerWorkspace[];
  getServerIp: () => string | null;
};
export const useOrganizationWorkspaces = (): OrganizationWorkspaces => {
  const { isSignedIn } = useAuth();
  const { isLoaded, organization } = useOrganization();

  const organizationServers = (organization?.publicMetadata.servers ||
    []) as ServerWorkspace[];

  return {
    isLoaded: Boolean(isSignedIn && isLoaded),
    hasWorkspaces: Boolean(
      organization && Object.hasOwn(organization.publicMetadata, "servers"),
    ),
    workspaces: organization
      ? (organization.publicMetadata.servers as ServerWorkspace[]) || []
      : [],
    getServerIp: () => organizationServers[0]?.ip || null,
  };
};
