export const secondsToTimestamp = (
  duration: number,
  padZero = true,
  separator = ":",
) => {
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration % 3600) / 60);
  const seconds = Math.floor(duration % 60);

  const pad = (num: number, size: number) => {
    let s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  };

  let formattedTime = pad(minutes, 2) + separator + pad(seconds, 2);
  if (hours > 0 || padZero) {
    formattedTime = pad(hours, 2) + separator + formattedTime;
  }

  return formattedTime;
};

export const getTimeAgo = (pastDate: Date, abbreviated = false) => {
  const currentDate = new Date();
  const difference = Math.floor(
    (currentDate.getTime() - pastDate.getTime()) / 1000,
  );

  let output = ``;
  if (difference < 60) {
    output = abbreviated ? "<1m" : "Just now";
  } else if (difference < 3600) {
    const minutes = Math.floor(difference / 60);
    output = abbreviated
      ? `${minutes}m`
      : minutes === 1
        ? "1 minute ago"
        : `${minutes} minutes ago`;
  } else if (difference < 86400) {
    const hours = Math.floor(difference / 3600);
    output = abbreviated
      ? `${hours}h`
      : hours === 1
        ? "1 hour ago"
        : `${hours} hours ago`;
  } else if (difference < 2620800) {
    const days = Math.floor(difference / 86400);
    output = abbreviated
      ? `${days}d`
      : days === 1
        ? "1 day ago"
        : `${days} days ago`;
  } else if (difference < 31449600) {
    const months = Math.floor(difference / 2620800);
    output = abbreviated
      ? `${months}mo`
      : months === 1
        ? "1 month ago"
        : `${months} months ago`;
  } else {
    const years = Math.floor(difference / 31449600);
    output = abbreviated
      ? `${years}y`
      : years === 1
        ? "1 year ago"
        : `${years} years ago`;
  }
  return output;
};
