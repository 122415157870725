import { Navigate } from "react-router-dom";
import { useAuth } from "@clerk/clerk-react";
import Loading from "@/layouts/Loading";
import { useMediaLoggingEnabled } from "@/hooks/useMediaLoggingEnabled";
import SignInPage from "./sign-in";

export default function IndexPage() {
  const { isSignedIn, isLoaded } = useAuth();
  const enableMediaLogging = useMediaLoggingEnabled();

  if (!isLoaded) {
    return <Loading className="h-screen" />;
  } else if (!isSignedIn) {
    return <SignInPage />;
  } else {
    return <Navigate to={enableMediaLogging ? "/media" : "/search"} replace />;
  }
}
