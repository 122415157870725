import {
  type BaseFilterStateKeys,
  EMPTY_FILTER_STATE,
  EMPTY_SEARCH_RESULTS_FILTER_STATE,
  type BaseFilterState,
  type SearchResultsFilterState,
  type SearchResultsFilterKeys,
  MediaFilter,
  FilterToUrlParamMap,
} from "@/types/filters";
import { FilterUpdateFacade } from "@utils/filter/filterUpdateFacade";
import { countNonEmptyFilters } from "@/utils/filter/filterUtils";
import { type components } from "@/openapi-bindings/v2";
import { useCallback, useMemo } from "react";
import { isEqual } from "lodash";
import { useGetTagsQuery } from "@/hooks/useGetTagsQuery";
import { useSearchParams } from "react-router-dom";
import { useURLSearchParams } from "./useURLSearchParams";
import { useGetPeopleQuery } from "./useGetPeopleQuery";

export const useFilterState = () => {
  const { data: tags } = useGetTagsQuery();
  const { data: people } = useGetPeopleQuery();
  const { encodeSearchToURLSearchParams, decodeURLSearchParams } =
    useURLSearchParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const decodedSearchParams = useMemo(
    () => decodeURLSearchParams(searchParams),
    [searchParams, tags, people],
  );

  const performSearch = useCallback(
    (filterState: BaseFilterState) => {
      const newSearchParams = encodeSearchToURLSearchParams(
        searchParams.get("search") ?? undefined,
        filterState,
        (searchParams.get("tab_type") as components["schemas"]["SearchMode"]) ||
          "omni",
      );
      setSearchParams(newSearchParams, { replace: true });
    },
    [encodeSearchToURLSearchParams, setSearchParams],
  );

  const updateFilterState = useCallback(
    <K extends keyof BaseFilterState>(
      filterStateKey: K,
      value: BaseFilterState[K],
      overwrite = false,
    ) => {
      if (value === undefined) {
        console.error("updateFilterState: value is undefined");
        return;
      }

      const filterUpdateFacade = new FilterUpdateFacade();

      const performUpdate = (currentState: any) => {
        const newState = filterUpdateFacade.updateFilter(
          filterStateKey,
          currentState,
          value,
          overwrite,
        );
        performSearch(newState);
      };

      const filterState = decodeURLSearchParams(searchParams).filterState;
      performUpdate(filterState);
    },
    [],
  );

  const clearFilterStateFilterKey = useCallback(
    (filterStateKey: keyof BaseFilterState) => {
      const urlSearchParam = FilterToUrlParamMap[filterStateKey];

      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.delete(urlSearchParam);
      setSearchParams(newSearchParams, { replace: true });
    },
    [],
  );

  const resetFilterState = useCallback(() => {
    const newSearchParams = new URLSearchParams(searchParams);
    for (const key of Object.keys(FilterToUrlParamMap)) {
      newSearchParams.delete(FilterToUrlParamMap[key as BaseFilterStateKeys]);
    }
    setSearchParams(newSearchParams, { replace: true });
  }, [setSearchParams]);

  const getNumNonEmptyFilters = useCallback(() => {
    const filterState = decodeURLSearchParams(searchParams).filterState;
    return countNonEmptyFilters(filterState);
  }, [searchParams]);

  const filterStateToMediaFilterArray = useCallback(
    (filterState: BaseFilterState) => {
      return Object.entries(filterState)
        .filter(([key, value]) => {
          return !isEqual(
            EMPTY_FILTER_STATE[key as keyof typeof EMPTY_FILTER_STATE],
            value,
          );
        })
        .filter(([key, _]) => {
          const typedKey: BaseFilterStateKeys = key as BaseFilterStateKeys;
          return typedKey !== "similarImages";
        })
        .map(([key, value]) => {
          return {
            [key]: value,
          } as MediaFilter; // TODO: the unknown casting might be bad
        });
    },
    [],
  );

  const getNonEmptySearchFilterKeys = useCallback(() => {
    const filterState = decodeURLSearchParams(searchParams).filterState;
    return Object.entries(filterState)
      .filter(([key, value]) => {
        return (
          EMPTY_FILTER_STATE[key as keyof typeof EMPTY_FILTER_STATE] !== value
        );
      })
      .map(([key, _]) => key as SearchResultsFilterKeys);
  }, []);

  const isFilterEmpty = useCallback((filterStateKey: keyof BaseFilterState) => {
    const searchParamValue = searchParams.get(
      FilterToUrlParamMap[filterStateKey as BaseFilterStateKeys],
    );
    return searchParamValue === null;
  }, []);

  const createFilterStateFromSearchRequest = (
    searchRequest: components["schemas"]["SearchRequest"],
  ): SearchResultsFilterState => {
    // This is highly redundant and hard to maintain, but a stopgap from the Old Way.
    const mediaFilter: MediaFilter = {
      people: searchRequest.people,
      tags: searchRequest.tags,
      timecode_range: searchRequest.timecode_range,
    };
    if (!mediaFilter) {
      return EMPTY_SEARCH_RESULTS_FILTER_STATE;
    }
    const newSearchResultsFilterState = {
      ...EMPTY_SEARCH_RESULTS_FILTER_STATE,
      ...mediaFilter,
    };
    return newSearchResultsFilterState;
  };

  const clearTagByTagType = useCallback(
    (tagType: string) => {
      const filterUpdateFacade = new FilterUpdateFacade();
      const filterState = decodeURLSearchParams(searchParams).filterState;
      const newState = filterUpdateFacade.clearTagByTagType(
        tags ?? [],
        filterState,
        tagType,
      );
      const newSearchParams = encodeSearchToURLSearchParams(
        searchParams.get("search") ?? undefined,
        newState,
        searchParams.get("tab_type") as components["schemas"]["SearchMode"],
      );
      setSearchParams(newSearchParams, { replace: true });
    },
    [tags],
  );

  const getNumAppliedFilters = useCallback(() => {
    const filterState = decodeURLSearchParams(searchParams).filterState;
    let numAppliedFilters = 0;
    numAppliedFilters += filterState.people.length;
    numAppliedFilters += filterState.tags.length;
    numAppliedFilters +=
      filterState.timecode_range === EMPTY_FILTER_STATE.timecode_range ? 0 : 1;
    return numAppliedFilters;
  }, [searchParams]);

  return {
    updateFilterState,
    clearFilterStateFilterKey,
    resetFilterState,
    getNumNonEmptyFilters,
    filterStateToMediaFilterArray,
    getNonEmptySearchFilterKeys,
    isFilterEmpty,
    createFilterStateFromSearchRequest,
    clearTagByTagType,
    getNumAppliedFilters,
    searchState: decodedSearchParams,
  };
};
