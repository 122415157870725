import React from "react";
import { Cross2Icon } from "@radix-ui/react-icons";
import { cn } from "@kino/ui";
import { cva, type VariantProps } from "class-variance-authority";
import { TailwindColor } from "@/types/tailwind";

export const filterBadgeSkeletonVariants = cva(
  "flex max-h-6 min-h-6 h-6 flex-nowrap whitespace-nowrap cursor-default items-center gap-2 shadow drop-shadow-md text-xs border rounded-md px-2 py-1",
  {
    variants: {
      variant: {
        default: "cursor-pointer",
        displayOnly: "cursor-default",
      },
      focus: {
        unfocused: "bg-none border-neutral-300 drop-shadow-none shadow-none",
        focused: "border",
      },
    },
    defaultVariants: {
      variant: "default",
      focus: "focused",
    },
  },
);

export type FilterBadgeSkeletonProps = {
  children: React.ReactNode;
  popoverContent?: React.ReactNode;
  classNamePopover?: string;
  onClose?: () => void;
  className?: string;
  baseColor?: TailwindColor;
} & VariantProps<typeof filterBadgeSkeletonVariants>;

const FilterBadgeSkeleton: React.FC<FilterBadgeSkeletonProps> = ({
  children,
  // popoverContent,
  // classNamePopover,
  onClose,
  variant = "default",
  focus = "focused",
  className,
  baseColor,
}) => {
  const textColor = baseColor ? `text-${baseColor}-200` : "text-neutral-300";
  const borderColor = baseColor
    ? `border-${baseColor}-400/20`
    : "border-neutral-700";
  return (
    <div
      className={cn(
        filterBadgeSkeletonVariants({
          variant,
          focus,
        }),
        borderColor,
        className,
      )}
    >
      <div className={cn("flex items-center gap-1", textColor)}>{children}</div>
      {variant === "default" && (
        <Cross2Icon
          className={cn(
            "h-3 min-h-3 w-3 min-w-3 hover:cursor-pointer",
            textColor,
          )}
          onClick={(e) => {
            e.stopPropagation();
            onClose?.();
          }}
        />
      )}
    </div>
  );
};

export { FilterBadgeSkeleton };
