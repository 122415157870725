import { OrganizationSwitcher as ClerkOrganizationSwitcher } from "@clerk/clerk-react";
interface OrganizationSwitcherProps {
  isMinified?: boolean;
}

const OrganizationSwitcher = ({ isMinified }: OrganizationSwitcherProps) => {
  return (
    <ClerkOrganizationSwitcher
      hidePersonal={true}
      appearance={{
        elements: {
          organizationSwitcherTriggerIcon: isMinified ? "hidden" : "visible",
          organizationSwitcherTrigger: isMinified ? "minified" : "",
        },
      }}
    />
  );
};

export { OrganizationSwitcher };
