import { queryClient, useClient } from "@/hooks/useClient";
import { useDebuggerStore } from "@/store/debuggerStore";
import dummyPeopleResponse from "@/api/dummyData/v1/people/dummyPeopleResponse.json";
import { components } from "@/openapi-bindings/v2";

const dummyPeople =
  dummyPeopleResponse as unknown as components["schemas"]["Person"][];

export const useGetPeopleQuery = () => {
  const { apiClient } = useClient();
  const isMockPeopleEndpoint = useDebuggerStore(
    (state) => state.isMockPeopleEndpoint,
  );

  return apiClient.useQuery(
    "get",
    "/people",
    undefined,
    {
      placeholderData: isMockPeopleEndpoint ? dummyPeople : undefined,
      enabled: !isMockPeopleEndpoint,
    },
    queryClient,
  );
};
