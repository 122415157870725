import { type HTMLAttributes } from "react";
import { cn, Badge, type badgeVariants, TextWithTooltip } from "@kino/ui";
import { type VariantProps } from "class-variance-authority";
import React from "react";
import { Tag } from "@/types/filters";
import { SparklesIcon, TagIcon } from "@heroicons/react/20/solid";

interface TagBadgeProps
  extends HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {
  tag: Tag;
  hideIcon?: boolean;
  showCustomColor?: boolean;
}

export const getColor = (
  tagType: string,
  isLightMode: boolean,
  showCustomColor: boolean,
): string => {
  const lightSuffix = "-600";
  const darkSuffix = "-100";
  const suffix = isLightMode ? lightSuffix : darkSuffix;
  if (!showCustomColor) {
    return `text-gray${suffix}`;
  }

  switch (tagType) {
    case "USER_DEFINED":
      return `text-gray${suffix}`;
    default:
      return `text-gray${suffix}`;
  }
};

export const getTagIcon = (tagType: string) => {
  const iconMap: Record<string, JSX.Element> = {
    USER_DEFINED: <TagIcon className="h-4 w-4" />,
    GENERATED: <SparklesIcon className="h-4 w-4" />,
  };

  const icon = iconMap[tagType] || <TagIcon />;

  return React.cloneElement(icon, {
    className: cn("h-4 w-4", icon.props.className),
  });
};

const TagBadge = ({
  tag,
  hideIcon,
  className,
  variant = "tag",
  size = "tag",
  showCustomColor = true,
  ...props
}: TagBadgeProps) => {
  if (tag.value === "Unknown") return null;
  return (
    <TextWithTooltip
      tooltipText={tag.value}
      key={tag.id}
      position="top"
      className="p-1.5"
      delayDuration={200}
    >
      <div>
        <Badge
          variant={variant}
          size={size}
          className={cn(
            "w-fit select-none items-center gap-1 overflow-hidden",
            className,
          )}
          {...props}
        >
          {!hideIcon && (
            <div
              className={cn(
                "flex h-3 w-3 items-center justify-center",
                getColor(
                  tag.tag_type,
                  variant !== "tagResult",
                  showCustomColor,
                ),
              )}
            >
              {getTagIcon(tag.tag_type)}
            </div>
          )}
          <span
            className={cn(
              "text-xsm pointer-events-none truncate text-ellipsis whitespace-nowrap",
            )}
          >
            {tag.value}
          </span>
        </Badge>
      </div>
    </TextWithTooltip>
  );
};

export default TagBadge;
