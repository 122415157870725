import { forwardRef } from "react";

const JumpToMomentIcon = forwardRef<
  SVGSVGElement,
  React.SVGAttributes<SVGElement>
>((props, forwardedRef) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    ref={forwardedRef}
    {...props}
  >
    <rect x="3" y="26.6429" width="34" height="7.28571" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.4286 14.5H26.3097L20 25.4286L13.6904 14.5H17.5715L17.5715 6H22.4286L22.4286 14.5Z"
      fill="currentColor"
    />
  </svg>
));

export default JumpToMomentIcon;
