import { useFilterState } from "@/hooks/useFilterState";
import { FunnelIcon } from "@heroicons/react/24/outline";
import { Button, ButtonProps, cn } from "@kino/ui";
import IconWithIndicator from "../shared/IconWithIndicator";

interface FilterButtonProps extends ButtonProps {}

const FilterButton = ({ onClick, className, ...props }: FilterButtonProps) => {
  const { getNumNonEmptyFilters } = useFilterState();
  const numNonEmptyFilters = getNumNonEmptyFilters();
  return (
    <Button
      variant={"outline"}
      size={"xsm"}
      className={cn(
        "flex h-7 items-center gap-1 border-neutral-800 text-xs font-normal text-neutral-300 hover:text-neutral-300",
        className,
      )}
      onClick={onClick}
      {...props}
    >
      <IconWithIndicator
        icon={FunnelIcon}
        showIndicator={numNonEmptyFilters > 0}
      />
      <div className="text-xs font-normal">Filter</div>
    </Button>
  );
};

export { FilterButton };
