import BaseView from "@/components/omniSearch/searchViews/BaseView";

const SearchDefaultView = () => {
  return (
    <BaseView>
      Welcome to OmniSearch! Please enter a search query to get started.
    </BaseView>
  );
};

export default SearchDefaultView;
