import React from "react";
import { useClient, queryClient } from "@/hooks/useClient";
import dummySeasonStatsResponse from "@/api/dummyData/v1/dummySeasonStatsResponse.json";
import { useDebuggerStore } from "@/store/debuggerStore";
import { ScrollArea, cn, Gauge, TextWithTooltip } from "@kino/ui";
import { formatStringPlurality } from "@/utils/stringUtils";
import { BarChartIcon, CheckIcon } from "@radix-ui/react-icons";
import Loading from "@/layouts/Loading";

interface SeasonStatsResponse {
  [key: string]: {
    num_videos: number;
    processed_videos: number;
    process_status: string;
  };
}

const SeasonStats: React.FC = () => {
  const { apiClient } = useClient();
  const isMockSeasonStats = useDebuggerStore(
    (state) => state.isMockSeasonStats,
  );
  const { data: seasonStats, isLoading } = apiClient.useQuery(
    "get",
    "/season_stats",
    undefined,
    {
      enabled: !isMockSeasonStats,
      placeholderData: isMockSeasonStats
        ? (dummySeasonStatsResponse as unknown as SeasonStatsResponse)
        : undefined,
    },
    queryClient,
  );

  return (
    <div className="flex h-full w-full flex-col divide-y border-b">
      <div className="flex w-full flex-row items-center justify-between gap-2 p-2">
        <div className="flex flex-row items-center gap-1">
          <BarChartIcon className="h-3 w-3 text-neutral-500" />
          <div className="text-xs text-neutral-500">Media Processing Stats</div>
        </div>
      </div>
      <div className="flex h-full w-full flex-col gap-2 overflow-hidden">
        {isLoading && <Loading />}
        {!isLoading && (
          <ScrollArea className="h-full w-full">
            <div className="p-2 text-xs text-neutral-600">
              {Object.keys(seasonStats || {}).length}{" "}
              {formatStringPlurality(
                Object.keys(seasonStats || {}).length,
                "day",
                "days",
              )}{" "}
            </div>
            <div className="flex flex-col gap-2 px-2">
              {Object.entries(seasonStats || {}).map(([day, stats]) => (
                <div
                  key={day}
                  className={cn(
                    "neutral-800 flex w-full cursor-default items-center justify-between gap-2 rounded-md border p-2 text-xs text-neutral-400",
                  )}
                >
                  <div className="flex flex-row items-center gap-2 overflow-hidden">
                    <span className="flex-1 truncate text-ellipsis whitespace-nowrap">
                      Day {day}
                    </span>
                  </div>
                  <div className="flex items-center gap-3">
                    <TextWithTooltip
                      tooltipText={`${stats.processed_videos} of ${stats.num_videos} files processed`}
                      position="top"
                    >
                      <div className="text-neutral-600">
                        {stats.processed_videos}/{stats.num_videos}
                      </div>
                    </TextWithTooltip>
                    <span className="flex w-5 items-center justify-center text-neutral-400">
                      {Math.round(
                        (stats.processed_videos / stats.num_videos) * 100,
                      )}
                      %
                    </span>
                    <div className="flex w-4 items-center justify-center">
                      {stats.process_status === "in progress" ? (
                        <Gauge
                          value={stats.processed_videos}
                          max={stats.num_videos}
                          size="xsmall"
                          showValue={false}
                          classNameProgress="text-indigo-500"
                        />
                      ) : (
                        <CheckIcon className="text-emerald-400" />
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </ScrollArea>
        )}
      </div>
    </div>
  );
};

export { SeasonStats };
