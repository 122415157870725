import { create } from "zustand";
import { BaseFilterStateKeys } from "@/types/filters";

type BaseSecondaryPanelFilter = { key: string };

type SecondaryPanelFilterTypes = {
  tags: BaseSecondaryPanelFilter & { key: "tags"; tagType: string | null };
} & {
  [K in BaseFilterStateKeys]: BaseSecondaryPanelFilter & { key: K };
};

export type FocusedSecondaryPanelFilter =
  SecondaryPanelFilterTypes[keyof SecondaryPanelFilterTypes];

interface SecondaryFilterPanelState {
  isOpen: boolean;
  focusedFilter: FocusedSecondaryPanelFilter | null;
  openPanel: (filter: FocusedSecondaryPanelFilter) => void;
  closePanel: () => void;
  setFilterState: (
    state: Partial<Omit<SecondaryFilterPanelState, "isOpen">>,
  ) => void;
}

export const useSecondaryFilterPanelStore = create<SecondaryFilterPanelState>(
  (set) => ({
    isOpen: false,
    focusedFilter: null,
    openPanel: (filter) => set({ isOpen: true, focusedFilter: filter }),
    closePanel: () => set({ isOpen: false, focusedFilter: null }),
    setFilterState: (state) => set((prevState) => ({ ...prevState, ...state })),
  }),
);
