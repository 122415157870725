import { forwardRef } from "react";

const GrabberIcon = forwardRef<SVGSVGElement, React.SVGAttributes<SVGElement>>(
  (props, forwardedRef) => (
    <svg viewBox="0 0 7 7" fill="none" {...props} ref={forwardedRef}>
      <g clipPath="url(#clip0_366_21752)">
        <path
          d="M3.18985 2.90875C3.32821 2.90875 3.44037 2.79659 3.44037 2.65823C3.44037 2.51988 3.32821 2.40771 3.18985 2.40771C3.05149 2.40771 2.93933 2.51988 2.93933 2.65823C2.93933 2.79659 3.05149 2.90875 3.18985 2.90875Z"
          stroke="currentColor"
          strokeWidth="0.501038"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.94351 2.90875C5.08187 2.90875 5.19403 2.79659 5.19403 2.65823C5.19403 2.51988 5.08187 2.40771 4.94351 2.40771C4.80515 2.40771 4.69299 2.51988 4.69299 2.65823C4.69299 2.79659 4.80515 2.90875 4.94351 2.90875Z"
          stroke="currentColor"
          strokeWidth="0.501038"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.43619 2.90875C1.57455 2.90875 1.68671 2.79659 1.68671 2.65823C1.68671 2.51988 1.57455 2.40771 1.43619 2.40771C1.29783 2.40771 1.18567 2.51988 1.18567 2.65823C1.18567 2.79659 1.29783 2.90875 1.43619 2.90875Z"
          stroke="currentColor"
          strokeWidth="0.501038"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.18985 4.41193C3.32821 4.41193 3.44037 4.29977 3.44037 4.16141C3.44037 4.02305 3.32821 3.91089 3.18985 3.91089C3.05149 3.91089 2.93933 4.02305 2.93933 4.16141C2.93933 4.29977 3.05149 4.41193 3.18985 4.41193Z"
          stroke="currentColor"
          strokeWidth="0.501038"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.94351 4.41193C5.08187 4.41193 5.19403 4.29977 5.19403 4.16141C5.19403 4.02305 5.08187 3.91089 4.94351 3.91089C4.80515 3.91089 4.69299 4.02305 4.69299 4.16141C4.69299 4.29977 4.80515 4.41193 4.94351 4.41193Z"
          stroke="currentColor"
          strokeWidth="0.501038"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.43619 4.41193C1.57455 4.41193 1.68671 4.29977 1.68671 4.16141C1.68671 4.02305 1.57455 3.91089 1.43619 3.91089C1.29783 3.91089 1.18567 4.02305 1.18567 4.16141C1.18567 4.29977 1.29783 4.41193 1.43619 4.41193Z"
          stroke="currentColor"
          strokeWidth="0.501038"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_366_21752">
          <rect
            width="6.01246"
            height="6.01246"
            fill="white"
            transform="translate(0.183594 0.403564)"
          />
        </clipPath>
      </defs>
    </svg>
  ),
);

export default GrabberIcon;
