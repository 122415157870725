import { useSecondaryFilterPanelStore } from "@/store/filter/secondaryFilterPanelStore";
import { ClickableIcon, cn } from "@kino/ui";
import { Cross2Icon } from "@radix-ui/react-icons";
import React from "react";

interface SecondaryFilterPanelProps
  extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

const SecondaryFilterPanel: React.FC<SecondaryFilterPanelProps> = ({
  children,
}) => {
  const { isOpen } = useSecondaryFilterPanelStore();
  if (!isOpen) return null;

  return (
    <div
      className="absolute left-full top-0 z-50 flex h-full w-72 min-w-72 max-w-72 flex-col divide-y border-x bg-neutral-900"
      style={{
        boxShadow: "20px 0px 34.1px 0px rgba(0, 0, 0, 0.38)",
      }}
    >
      {children}
    </div>
  );
};

interface SecondaryFilterPanelHeaderProps
  extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

export const SecondaryFilterPanelHeader = ({
  children,
  className,
  ...props
}: SecondaryFilterPanelHeaderProps) => {
  const { closePanel } = useSecondaryFilterPanelStore();
  return (
    <div
      className={cn(
        "flex h-11 items-center justify-between p-0 px-2 text-xs",
        className,
      )}
      {...props}
    >
      {children}
      <ClickableIcon
        name="close-secondary-filter-panel"
        Icon={Cross2Icon}
        onClick={() => {
          closePanel();
        }}
        className="text-gray-500 hover:text-gray-400"
      />
    </div>
  );
};

interface SecondaryFilterPanelContentProps
  extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

export const SecondaryFilterPanelContent = ({
  children,
  className,
  ...props
}: SecondaryFilterPanelContentProps) => {
  return (
    <div className={cn("h-full overflow-y-auto", className)} {...props}>
      {children}
    </div>
  );
};

export default SecondaryFilterPanel;
