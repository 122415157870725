import {
  Button,
  cn,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Switch,
} from "@kino/ui";
import ViewTypeToggles from "./ViewTypeToggles";
import { GroupBySelect } from "./GroupBySelect";
import { MixerHorizontalIcon } from "@radix-ui/react-icons";
import IconWithIndicator from "@/components/shared/IconWithIndicator";
import { useSearchDisplayStore } from "@/store/search/searchDisplayStore";
import useGetSearchMode from "@/hooks/useGetSearchMode";

interface SettingProps extends React.HTMLAttributes<HTMLDivElement> {
  label?: string;
}

interface SettingSectionProps extends React.HTMLAttributes<HTMLDivElement> {}

const SettingSection = ({
  children,
  className,
  ...props
}: SettingSectionProps) => {
  return (
    <div className={cn("flex flex-col gap-3", className)} {...props}>
      {children}
    </div>
  );
};

const Setting = ({ className, children, label, ...props }: SettingProps) => {
  return (
    <div
      className={cn("flex items-center justify-between gap-1", className)}
      {...props}
    >
      {label && <span className="w-24 text-xs font-normal">{label}</span>}
      {children}
    </div>
  );
};

export const SearchResultsDisplayPopover = () => {
  const tabType = useGetSearchMode();
  const { propertyVisibility, togglePropertyVisibility } =
    useSearchDisplayStore();
  return (
    <Popover modal={true}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          size="xsm"
          className="flex h-7 items-center gap-1 border-neutral-800 text-xs font-normal text-neutral-300 hover:text-neutral-300"
        >
          <IconWithIndicator icon={MixerHorizontalIcon} />
          Display
        </Button>
      </PopoverTrigger>
      <PopoverContent className="flex w-72 flex-col divide-y p-2 text-xs *:py-2 first:*:pt-0 last:*:pb-0">
        <SettingSection>
          <Setting label="View">
            <ViewTypeToggles />
          </Setting>
        </SettingSection>
        {tabType !== "omni" && (
          <SettingSection>
            <Setting label="Group by">
              <GroupBySelect />
            </Setting>
          </SettingSection>
        )}
        <SettingSection>
          {Object.keys(propertyVisibility).map((label) => (
            <Setting key={label} label={"Show " + label}>
              <Switch
                checked={propertyVisibility[label]}
                onCheckedChange={() => togglePropertyVisibility(label)}
              />
            </Setting>
          ))}
        </SettingSection>
      </PopoverContent>
    </Popover>
  );
};
