import React, { HTMLAttributes, useState } from "react";
import { secondsToTimestamp } from "@/utils/time";
import { useActiveBucketStore } from "@/store/search/activeBucketStore";
import { cn } from "@/utils/tailwind";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@kino/ui";

interface Segment {
  percentage: number;
  resultId: string;
}

interface Marker extends HTMLAttributes<HTMLDivElement> {
  percentage: number;
}

export interface RelativeTimeline {
  segments: Segment[];
  markers: Marker[];
}

interface OmniSearchCardRelativeTimelineProps {
  segments?: Segment[];
  markers?: Marker[];
  duration?: number;
  showTimestamps?: boolean;
}

interface SegmentProps {
  percentage: number;
  resultId: string;
  disabled?: boolean;
}

const containerClassName = "flex min-h-5 items-center gap-1 px-2";
const timelineContainerClassName =
  "relative flex h-1.5 w-full overflow-hidden rounded-md bg-white";

const Segment = ({ percentage, resultId, disabled }: SegmentProps) => {
  const { setActiveSegmentId } = useActiveBucketStore();

  return (
    <div
      className={`h-1.5 bg-gray-200/60 ${disabled ? "" : "cursor-pointer hover:bg-gray-200"}`}
      style={{ width: `${percentage}%` }}
      onClick={disabled ? undefined : () => setActiveSegmentId(resultId)}
      onMouseEnter={disabled ? undefined : () => setActiveSegmentId(resultId)}
      onMouseLeave={disabled ? undefined : () => setActiveSegmentId(null)}
    />
  );
};

interface MarkerProps extends React.HTMLAttributes<HTMLDivElement> {
  percentage: number;
  tooltipText?: string;
  isHighlighted?: boolean;
  isActive?: boolean;
}

const Marker = ({
  percentage,
  isHighlighted,
  isActive,
  tooltipText,
  className,
  ...props
}: MarkerProps) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const baseClasses = "absolute h-1.5 w-1.5 transition-colors";

  const stateClasses = cn({
    // Active and highlighted
    "z-50 border border-indigo-600 border-opacity-100 bg-indigo-600":
      isActive && isHighlighted,

    // Active but not highlighted
    "border border-indigo-600 border-opacity-100 bg-indigo-600 bg-opacity-30":
      isActive && !isHighlighted,

    // Highlighted but not active
    "z-50 bg-indigo-600": !isActive && isHighlighted,

    // Neither active nor highlighted
    "bg-indigo-300 bg-opacity-10": !isActive && !isHighlighted,
  });

  return (
    <TooltipProvider>
      <Tooltip
        delayDuration={0}
        open={isTooltipOpen || isHighlighted}
        onOpenChange={setIsTooltipOpen}
      >
        <TooltipTrigger asChild disabled={!tooltipText}>
          <div
            className={cn(baseClasses, stateClasses, className)}
            style={{ left: `calc(${percentage}%)` }}
            {...props}
          />
        </TooltipTrigger>
        <TooltipContent>{tooltipText}</TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

const Timestamp = ({ timestamp }: { timestamp?: number }) => {
  return (
    <div className="cursor-default select-none whitespace-nowrap text-[10px] font-light leading-none text-neutral-500">
      {timestamp !== undefined ? secondsToTimestamp(timestamp) : "--:--:--"}
    </div>
  );
};

interface TimelineProps {
  segments: Segment[];
  markers: Marker[];
}

const Timeline = ({ segments, markers }: TimelineProps) => {
  return (
    <div className="relative flex h-1.5 w-full overflow-hidden rounded-md bg-white">
      {!segments || segments.length === 0 ? (
        <div className="h-full bg-neutral-800" style={{ width: "100%" }} />
      ) : (
        segments.map((segment) => (
          <Segment
            key={segment.resultId}
            resultId={segment.resultId}
            percentage={segment.percentage}
          />
        ))
      )}
      {markers.map((marker, index) => (
        <Marker key={index} {...marker} />
      ))}
    </div>
  );
};

const NoTimelineState = () => {
  return (
    <div className={containerClassName}>
      <Timestamp />
      <div className={timelineContainerClassName}>
        <Segment percentage={100} disabled resultId="disabled" />
      </div>
      <Timestamp />
    </div>
  );
};

const OmniSearchCardRelativeTimeline: React.FC<
  OmniSearchCardRelativeTimelineProps
> = ({ segments, markers, duration, showTimestamps = true }) => {
  if (!duration || !segments || !markers) return <NoTimelineState />;
  const singlePointStartTime = markers[0]?.percentage
    ? (duration * markers[0].percentage) / 100
    : 0;

  return (
    <div className="flex min-h-5 items-center gap-1 px-2">
      {showTimestamps && markers.length === 1 && (
        <Timestamp timestamp={singlePointStartTime} />
      )}
      <Timeline segments={segments} markers={markers} />
      {showTimestamps && <Timestamp timestamp={duration} />}
    </div>
  );
};

export default OmniSearchCardRelativeTimeline;
