import React, { useMemo } from "react";
import { ArrayFilterKeys, Filter } from "@/types/filters";
import { CommandGroup } from "@kino/ui";
import FilterArrayList from "./FilterArrayList";

interface ArrayFilterSectionProps {
  search: string;
  filterKey: ArrayFilterKeys;
  filter: Filter<ArrayFilterKeys>;
}

const ArrayFilterSection: React.FC<ArrayFilterSectionProps> = ({
  search,
  filterKey,
  filter,
}) => {
  const filterOptions = filter.criteria;
  const filteredPeople = useMemo(() => {
    if (!search) return filterOptions;
    return filterOptions?.filter((person) =>
      person.value.toLowerCase().includes(search.toLowerCase()),
    );
  }, [filterOptions, search]);

  return (
    <CommandGroup className="h-full pt-2">
      {/* <div className="flex items-center justify-between">
        <div className="py-2 text-xs text-neutral-500">{filter.label}</div>
        <div className="flex items-center gap-2">
          <Button
            variant="link"
            size="sm"
            className="text-xs font-normal text-indigo-500"
            onClick={handleOpenPanel}
          >
            All ({filterOptions?.length || 0})
          </Button>
        </div>
      </div> */}
      <FilterArrayList
        filterOptions={filteredPeople || []}
        filterKey={filterKey}
      />
    </CommandGroup>
  );
};

export default ArrayFilterSection;
