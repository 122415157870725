import { CommandItem } from "@kino/ui";
import { ClockIcon } from "@radix-ui/react-icons";
import { COMMAND_ITEM_VALUE_DELIMITER } from "./CommandMenu";
import { useEffect, useMemo, useState } from "react";
import path from "path";
import { useFilterState } from "../../../hooks/useFilterState";
import { getTimeAgo } from "@/utils/time";
import { parseSearchQuery } from "@/utils/search";
import { type RecentSearch } from "@/types/search";

const escapeRegExp = (string: string) => {
  return string.replace(/[.*+?^${}()|[\]\\\-/"]/g, "\\$&"); // $& means the whole matched string
};

const highlight = (str: string, kw: string, regex?: RegExp) => {
  if (regex) {
    const escapedKw = escapeRegExp(kw);
    const highlightRegex = new RegExp(`(${escapedKw})`, "ig");
    return str.replace(
      highlightRegex,
      '<span class="font-bold text-indigo-500">$1</span>',
    );
  }
  return str;
};

const highlightSearchResult = (
  text: string,
  pathStr: string,
  kw: string,
  regex?: RegExp,
) => {
  return {
    highlightedText: highlight(text, kw, regex),
    highlightedPath: highlight(path.basename(pathStr), kw, regex),
  };
};

export const formatCommandItemValueString = (
  id: string,
  searchTerm: string,
) => {
  // ie: transcript_happy-days_are_here -> id: transcript, searchTerm: happy-days_are_here
  return `${id}${COMMAND_ITEM_VALUE_DELIMITER}${searchTerm}`;
};

interface SearchCommandItemProps {
  onSelect: () => void;
  searchTerm: string;
}

export const SearchCommandItem: React.FC<SearchCommandItemProps> = ({
  onSelect,
  searchTerm,
}) => {
  const parsedSearchQuery = parseSearchQuery(searchTerm);
  const kw = parsedSearchQuery.queryString;
  //   const { data: frames } = useQuery([
  //     "framesByIds",
  //     parsedSearchQuery?.frames.map((frame) => frame.id) ?? [],
  //   ]);

  //   const [base64ImgSrcFrames] = useBase64Images(frames);
  return (
    <CommandItem
      className="flex h-8 items-center gap-2"
      onSelect={onSelect}
      value={formatCommandItemValueString(
        `search`,
        encodeURIComponent(kw ?? ""),
      )}
    >
      <div className="flex items-center gap-1">
        {/* <ActiveFiltersRow
          filterState={draftSearchResultsFilterState}
          displayOnly={true}
          focused={true}
        /> */}
        Search
        <span className="font-bold text-indigo-500">{kw}</span>
      </div>
      {/* {base64ImgSrcFrames && (
        <div className="flex h-6 items-center gap-1 rounded-md border bg-white px-2">
          <ViewfinderCircleIcon className="h-4 w-4 text-gray-400" />
          <div className="flex items-center">
            {base64ImgSrcFrames.slice(0, 3).map((base64ImgSrc, index) => {
              return (
                <div key={index} className={cn("h-4", index !== 0 && "-ml-2")}>
                  <Image
                    src={base64ImgSrc}
                    alt="frame"
                    className="h-4 w-auto rounded-sm border"
                    width={400}
                    height={400}
                  />
                </div>
              );
            })}
          </div>
          <div className="text-xs text-gray-400">
            {formatImageCountText(base64ImgSrcFrames.length)}
          </div>
        </div>
      )} */}
    </CommandItem>
  );
};

interface TranscriptCommandItemProps {
  onSelect: () => void;
  path: string;
  suggestionStr: string;
  kw: string;
  regex?: RegExp;
  index: number;
}

export const TranscriptSuggestionItem: React.FC<TranscriptCommandItemProps> = ({
  suggestionStr,
  path,
  kw,
  regex,
  index,
  onSelect,
}) => {
  const { highlightedText, highlightedPath } = highlightSearchResult(
    suggestionStr,
    path,
    kw,
    regex,
  );

  return (
    <CommandItem
      className="h-8"
      value={formatCommandItemValueString(
        `transcript-suggestion-${suggestionStr}-${path}-${index}`,
        encodeURIComponent(kw), // Mark as literal search
      )}
      onSelect={onSelect}
    >
      <div className="flex items-center">
        <span
          className={`truncate font-normal`}
          dangerouslySetInnerHTML={{ __html: highlightedText }}
        />
        <span
          className={`ml-2 text-xs text-gray-500`}
          dangerouslySetInnerHTML={{ __html: highlightedPath }}
        />
      </div>
    </CommandItem>
  );
};

// const formatImageCountText = (totalFrames: number): string => {
//   if (totalFrames <= 3) {
//     return "";
//   } else {
//     return `+${totalFrames - 3}`;
//   }
// };
interface RecentSearchCommandItemProps {
  recentSearch: RecentSearch;
  onSelect: () => void;
}

export const RecentSearchCommandItem = ({
  recentSearch,
  onSelect,
}: RecentSearchCommandItemProps) => {
  const { date: dateStr, id, searchRequest } = recentSearch;
  const parsedSearchQuery = parseSearchQuery(searchRequest.text);
  const kw = parsedSearchQuery.queryString;
  const date = useMemo(() => new Date(dateStr), [dateStr]);

  const { createFilterStateFromSearchRequest } = useFilterState();
  const filterState = createFilterStateFromSearchRequest(searchRequest);
  const [relativeTime, setRelativeTime] = useState(getTimeAgo(date));
  //   const { data: frames } = useQuery([
  //     "framesByIds",
  //     parsedSearchQuery?.frames.map((frame) => frame.id) ?? [],
  //   ]);
  //   const [base64ImgSrcFrames] = useBase64Images(frames);

  useEffect(() => {
    const interval = setInterval(() => {
      setRelativeTime(getTimeAgo(date));
    }, 1000); // Update every second

    return () => clearInterval(interval);
  }, [date]);

  return (
    <CommandItem
      value={formatCommandItemValueString(
        `recent-search-${recentSearch.id}`,
        encodeURIComponent(searchRequest.text ?? ""),
      )}
      key={id}
      className="flex h-8 w-full items-center justify-between gap-2"
      onSelect={onSelect}
      onClick={onSelect}
    >
      <div className="flex items-center gap-2">
        <div className="flex items-center gap-2">
          <ClockIcon className="h-4 w-4 text-gray-400" />
          <div className="flex items-center gap-1">
            <span>{kw}</span>
          </div>
        </div>
        {/* {base64ImgSrcFrames && (
          <div className="flex h-6 items-center gap-1 rounded-md border bg-white px-2">
            <ViewfinderCircleIcon className="h-4 w-4 text-gray-400" />
            <div className="flex items-center">
              {base64ImgSrcFrames.slice(0, 3).map((base64ImgSrc, index) => {
                return (
                  <div
                    key={index}
                    className={cn("h-4", index !== 0 && "-ml-2")}
                  >
                    <Image
                      src={base64ImgSrc}
                      alt="frame"
                      className="h-4 w-auto rounded-sm border"
                      width={400}
                      height={400}
                    />
                  </div>
                );
              })}
            </div>
            <div className="text-xs text-gray-400">
              {formatImageCountText(base64ImgSrcFrames.length)}
            </div>
          </div>
        )} */}
      </div>
      <span className="text-gray-400">{relativeTime}</span>
    </CommandItem>
  );
};
