import { create } from "zustand";

interface ActiveTranscriptState {
  currentSegmentIndex: number;
  setCurrentSegmentIndex: (index: number) => void;
}

export const useActiveTranscriptStore = create<ActiveTranscriptState>(
  (set) => ({
    currentSegmentIndex: -1,
    setCurrentSegmentIndex: (index) => set({ currentSegmentIndex: index }),
  }),
);
