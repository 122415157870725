"use client";

import * as CollapsiblePrimitive from "@radix-ui/react-collapsible";
import { type ComponentPropsWithoutRef } from "@radix-ui/react-primitive";

const Collapsible = CollapsiblePrimitive.Root;

type CollapsibleTriggerProps = ComponentPropsWithoutRef<
  typeof CollapsiblePrimitive.CollapsibleTrigger
>;

const CollapsibleTrigger: React.FC<CollapsibleTriggerProps> = (props) => {
  const { className = "", ...otherProps } = props;

  return (
    <CollapsiblePrimitive.CollapsibleTrigger
      className={`appearance-none ${className}`}
      {...otherProps}
    />
  );
};

const CollapsibleContent = CollapsiblePrimitive.CollapsibleContent;

export { Collapsible, CollapsibleTrigger, CollapsibleContent };
