import React, { useEffect, useRef, useState } from "react";
import { cn, CommandEmpty, CommandInput, CommandList } from "@kino/ui";
import { useHotkeys } from "react-hotkeys-hook";
import { useSearch } from "@/hooks/useSearch";
import { stringifyParsedQuery } from "@utils/search";
import { useCommandMenuStore } from "../../../store/commandMenuStore";
import { useSearchRequestStore } from "@/store/search/searchRequestStore";
import { SearchCommandItem } from "@components/omniSearch/commandMenu/CommandMenuItem";
import { useOmniSearch } from "@/hooks/useOmniSearch";
import { useFilterState } from "@/hooks/useFilterState";

export enum CommandMenuVariant {
  default = "default",
  outline = "outline",
}

interface CommandMenuListProps {
  isDialog?: boolean;
  focused: boolean;
  setFocused: (focused: boolean) => void;
  variant?: CommandMenuVariant;
  maxListWidth?: number;
  focusByDefault?: boolean;
}

const CommandMenuList = ({
  isDialog,
  focused,
  setFocused,
  variant,
  maxListWidth,
  focusByDefault,
}: CommandMenuListProps) => {
  const [isMouseOverList, setIsMouseOverList] = useState(false);
  const { parsedSearchQuery } = useSearchRequestStore((state) => ({
    parsedSearchQuery: state.parsedSearchQuery,
  }));
  const { searchState } = useFilterState();
  const filterState = searchState.filterState;
  const { searchRequest } = useOmniSearch();

  const { activeSearchEntity, setActiveSearchEntity } = useCommandMenuStore(
    (state) => ({
      activeSearchEntity: state.activeSearchEntity,
      setActiveSearchEntity: state.setActiveSearchEntity,
    }),
  );

  // Responsive searchbar
  const inputRef = useRef<HTMLInputElement>(null);

  const { handleSearch } = useSearch();

  const [kw, setKw] = useState<string>(searchRequest.text ?? "");

  useEffect(() => {
    focusByDefault && setFocused(true);
  }, [focusByDefault, setFocused]);

  useEffect(() => {
    if (focused) {
      inputRef.current?.focus();
    } else {
      setActiveSearchEntity(null);
      inputRef.current?.blur();
    }
  }, [focused, setActiveSearchEntity]);

  const onFocusShortcut = () => {
    setFocused(true);
    inputRef.current?.select();
  };

  // single key
  useHotkeys("escape", () => setFocused(false));

  // Cmd + some key
  useHotkeys("meta+l", onFocusShortcut);

  return (
    <>
      <CommandInput
        ref={inputRef}
        placeholder={"Search..."}
        onBlur={() => {
          if (!isMouseOverList) {
            setFocused(false);
          }
        }}
        onFocus={() => setFocused(true)}
        onValueChange={(value: string) => {
          setFocused(true);
          setKw(value);
        }}
        onInput={(e: React.FormEvent<HTMLInputElement>) => {
          const input = e.currentTarget as HTMLInputElement;
          const value = input.value;
          const cursorPos = input.selectionStart;

          if (value[cursorPos! - 1] === "@") {
            setActiveSearchEntity({
              atSymbolCursorPosition: cursorPos || 0,
              text: "",
            });
          } else if (activeSearchEntity) {
            const start = activeSearchEntity.atSymbolCursorPosition; // Exclude the '@' symbol
            const end = cursorPos || 0;
            setActiveSearchEntity({
              ...activeSearchEntity,
              text: value.slice(start, end),
            });
          }
        }}
        value={kw}
        autoComplete="off"
        spellCheck="false"
        searchLoading={false}
        className={cn(
          "text-xs",
          variant === CommandMenuVariant.outline
            ? "h-7 rounded-md border"
            : "h-7 rounded border-none bg-black/30",
        )}
      >
        {/* <ActiveFiltersRow
          filterState={draftSearchResultsFilterState}
          displayOnly={true}
          focused={focused}
        /> */}
      </CommandInput>
      {focused && (
        <CommandList
          className={cn(
            !isDialog &&
              "absolute top-full z-[1000] flex flex-col justify-start rounded-b-md shadow-md",
            variant === CommandMenuVariant.default &&
              !isDialog &&
              "left-3 right-3",
            variant === CommandMenuVariant.outline &&
              !isDialog &&
              "left-0 right-0",
            maxListWidth && `max-w-[${maxListWidth}px] w-[${maxListWidth}px]`,
          )}
          onMouseEnter={() => setIsMouseOverList(true)}
          onMouseLeave={() => setIsMouseOverList(false)}
        >
          <CommandEmpty> No results found.</CommandEmpty>
          {kw !== "" && (
            <SearchCommandItem
              onSelect={() => {
                const newKw = stringifyParsedQuery({
                  ...parsedSearchQuery,
                  queryString: kw,
                  frames: parsedSearchQuery?.frames || [],
                });
                handleSearch(newKw, filterState);
                setFocused(false);
              }}
              searchTerm={kw}
            />
          )}
        </CommandList>
      )}
    </>
  );
};

export { CommandMenuList };
