export const formatBitRate = (bitRate: number) => {
  if (bitRate === null || bitRate === undefined) {
    return "-";
  }
  return `${(bitRate / 1000).toFixed(2)} Kbps`;
};

export const formatSize = (size: number) => {
  if (size === null || size === undefined) {
    return "-";
  }
  const i = Math.floor(Math.log(size) / Math.log(1024));
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  return `${(size / Math.pow(1024, i)).toFixed(2)} ${sizes[i]}`;
};

export const formatFrameRate = (secondsPerFrame: number) => {
  const fps = Math.round((1 / secondsPerFrame) * 100) / 100;

  if (Math.abs(fps - 29.97) < 0.01) return "29.97fps (NTSC)";
  if (Math.abs(fps - 25) < 0.01) return "25fps (PAL)";
  if (Math.abs(fps - 24) < 0.01) return "24fps (Film)";
  if (Math.abs(fps - 30) < 0.01) return "30fps";
  if (Math.abs(fps - 60) < 0.01) return "60fps";
  if (Math.abs(fps - 50) < 0.01) return "50fps";

  return `${fps}fps`;
};
