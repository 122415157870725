import { Panel, type PanelProps } from "react-resizable-panels";
import { PrimaryViewportProvider } from "@kino/player";
import { InspectorTabs } from "./tabs/InspectorTabs";
import { ViewportPanel } from "../viewport/ViewportPanel";
import { useSelectedMediaStore } from "@/store/selectedMediaStore";
import { useMemo } from "react";

const InspectorPanel: React.FC<PanelProps> = (props) => {
  const selectedMedia = useSelectedMediaStore(
    (state) => state.currentSelection,
  );
  const viewportKey = useMemo(() => {
    if (!selectedMedia || selectedMedia.length === 0) return "no-media";
    return (
      selectedMedia.map((item) => item.id).join("-") +
      `-${selectedMedia.length}`
    );
  }, [selectedMedia]);
  return (
    <PrimaryViewportProvider key={viewportKey}>
      <Panel
        minSize={20}
        maxSize={40}
        defaultSize={20}
        id="inspector-panel"
        order={3}
        {...props}
        className="flex h-full flex-col divide-y-2 divide-black"
      >
        <ViewportPanel />
        <InspectorTabs />
      </Panel>
    </PrimaryViewportProvider>
  );
};

export { InspectorPanel };
