import React, { useMemo } from "react";
import {
  arrayFilterKeys,
  type ArrayFilterKeys,
  type BaseFilters,
  type BaseFilterStateKeys,
} from "@/types/filters";
import FilterBadgeVisuallySimilarFrames from "@/components/filter/FilterBadgeVisuallySimilarFrames";
import { FilterBadgeTag } from "../components/filter/filterPanel/tags/FilterBadgeTag";
import { FilterBadgeArray } from "../components/filter/filterPanel/array/FilterBadgeArray";
import { useSearchParams } from "react-router-dom";
import { useURLSearchParams } from "@/hooks/useURLSearchParams";
import { useGetTagsQuery } from "@/hooks/useGetTagsQuery";

const useRenderFilterBadges = () => {
  const [searchParams] = useSearchParams();
  const { decodeURLSearchParams } = useURLSearchParams();
  const filterState = decodeURLSearchParams(searchParams).filterState;
  const { data: tags } = useGetTagsQuery();

  const hydratedTags = useMemo(() => {
    if (filterState.tags && tags) {
      return filterState.tags.map((tagId) =>
        tags.find((tag) => tag.id === tagId),
      );
    }
    return [];
  }, [filterState.tags, tags]);

  const isArrayFilterKey = useMemo(
    () =>
      (key: any): key is ArrayFilterKeys =>
        arrayFilterKeys.includes(key),
    [],
  );

  const getAppliedFilterKeys = () => {
    return Object.keys(filterState).filter(
      (key) => !isFilterEmpty(key as BaseFilterStateKeys),
    ) as BaseFilterStateKeys[];
  };

  const isFilterEmpty = (key: BaseFilterStateKeys): boolean => {
    const value = filterState[key];
    if (Array.isArray(value)) {
      return value.length === 0;
    }
    return !value;
  };

  const getFilterBadges = (
    filters: BaseFilters,
    filterKey: BaseFilterStateKeys,
    displayOnly = false,
    focused = true,
  ) => {
    if (filterKey === "tags") {
      if (!tags) return null;

      return (
        <React.Fragment key={`tags-container-${filterState.tags.join("-")}`}>
          {hydratedTags.map((tag, index) => {
            if (!tag) return null;
            return (
              <FilterBadgeTag
                key={`tag-${tag.id}-${index}`}
                tag={tag}
                displayOnly={displayOnly}
                focused={focused}
              />
            );
          })}
        </React.Fragment>
      );
    }

    if (isArrayFilterKey(filterKey)) {
      const typedFilterState = filterState[filterKey];
      const typedFilter = filters[filterKey];

      if (!typedFilterState || !typedFilter) return null;

      if (filterKey === "similarImages") {
        return (
          <FilterBadgeVisuallySimilarFrames
            key={`similar-images-${displayOnly}-${focused}`}
            displayOnly={displayOnly}
            focused={focused}
          />
        );
      }

      return (
        <>
          {typedFilterState.map((id, index) => {
            const filterOption = typedFilter.criteria?.find(
              (option) => option.id === id,
            );

            if (!filterOption) return null;

            return (
              <FilterBadgeArray
                key={`array-${filterKey}-${id}-${index}`}
                filterKey={filterKey}
                filterOption={filterOption}
              />
            );
          })}
        </>
      );
    }

    return null;
  };

  return {
    getFilterBadges,
    getAppliedFilterKeys,
    filterState,
  };
};

export default useRenderFilterBadges;
