import { components } from "@/openapi-bindings/v2";
import { useLocation } from "react-router-dom";

const useGetSearchMode = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  return (searchParams.get("tab_type") ||
    "omni") as components["schemas"]["SearchMode"];
};

export default useGetSearchMode;
