import { MediaPlayerInstance } from "@vidstack/react";
import React, { createContext, useContext, useRef } from "react";

export interface IPrimaryViewportContext {
  playerRef: React.RefObject<MediaPlayerInstance>;
}

const PrimaryViewportContext = createContext<
  IPrimaryViewportContext | undefined
>(undefined);

export const PrimaryViewportProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const playerRef = useRef<MediaPlayerInstance>(null);

  return (
    <PrimaryViewportContext.Provider value={{ playerRef }}>
      {children}
    </PrimaryViewportContext.Provider>
  );
};

export const usePrimaryViewportContext = (): IPrimaryViewportContext => {
  const context = useContext(PrimaryViewportContext);
  if (!context) {
    throw new Error(
      "usePrimaryViewportContext must be used within a PrimaryViewportProvider",
    );
  }
  return context;
};
