import type { FuzzyFilenameParams } from "@/types/oldBindings";
import {
  type BaseFilterStateKeys,
  type BaseFilterState,
  EMPTY_FILTER_STATE,
  Tag,
} from "@/types/filters";

interface FilterUpdateStrategy<T> {
  updateFilter(currentValue: T, newValue: T): T;
}
// prettier-ignore
type StrategyType<T> =
  | T extends Array<string>
    ? ArrayFilterUpdateStrategy<string>
    : T extends Array<number>
    ? ArrayFilterUpdateStrategy<number>
    : T extends number
    ? NumberFilterUpdateStrategy
    : T extends boolean
    ? BooleanFilterUpdateStrategy
    : T extends [string, string]
    ? DateRangeFilterUpdateStrategy
    : T extends FuzzyFilenameParams
    ? SetFuzzyFilenameFilterUpdateStrategy
    : T extends string
    ? StringFilterUpdateStrategy
  : never;

// interface FilterStrategies
//   // eslint-disable-next-line @typescript-eslint/no-explicit-any
//   extends Map<BaseFilterStateKeys, FilterUpdateStrategy<any>> {
//   get<K extends BaseFilterStateKeys>(key: K): StrategyType<BaseFilterState[K]>;
//   set<K extends BaseFilterStateKeys>(
//     key: K,
//     value: StrategyType<BaseFilterState[K]>,
//   ): this;
// }

class StringFilterUpdateStrategy implements FilterUpdateStrategy<string> {
  updateFilter(currentValue: string, newValue: string): string {
    return newValue;
  }
}

class ArrayFilterUpdateStrategy<T> implements FilterUpdateStrategy<T[]> {
  updateFilter(currentArray: T[], newValues: T[]): T[] {
    const resultArray = currentArray.slice();
    newValues.forEach((value) => {
      const index = resultArray.indexOf(value);
      if (index > -1) {
        resultArray.splice(index, 1);
      } else {
        resultArray.push(value);
      }
    });
    return resultArray;
  }
}

class BooleanFilterUpdateStrategy implements FilterUpdateStrategy<boolean> {
  updateFilter(currentValue: boolean, newValue: boolean): boolean {
    return newValue;
  }
}

class NumberFilterUpdateStrategy implements FilterUpdateStrategy<number> {
  updateFilter(currentValue: number, newValue: number): number {
    return newValue;
  }
}

class DateRangeFilterUpdateStrategy
  implements FilterUpdateStrategy<[number, number]>
{
  updateFilter(
    currentValue: [number, number],
    newValue: [number, number],
  ): [number, number] {
    return newValue;
  }
}

class SetFuzzyFilenameFilterUpdateStrategy
  implements FilterUpdateStrategy<FuzzyFilenameParams>
{
  updateFilter(
    currentValue: FuzzyFilenameParams,
    newValue: FuzzyFilenameParams,
  ): FuzzyFilenameParams {
    return newValue;
  }
}

class ArrayTagFilterUpdateStrategy implements FilterUpdateStrategy<Tag[]> {
  updateFilter(currentTags: Tag[], newTags: Tag[]): Tag[] {
    const resultTags = currentTags.filter(
      (currentTag) =>
        !newTags.some((newTag) => this.areTagsEqual(currentTag, newTag)),
    );

    newTags.forEach((newTag) => {
      if (!currentTags.some((tag) => this.areTagsEqual(tag, newTag))) {
        resultTags.push(newTag);
      }
    });

    return resultTags;
  }

  private areTagsEqual(tag1: Tag, tag2: Tag): boolean {
    return tag1.id === tag2.id;
  }
}

// class MediaFilterTypeUpdateStrategy
//   implements
//     FilterUpdateStrategy<components["schemas"]["MediaFilter"]["media_type"]>
// {
//   updateFilter(
//     currentValue: components["schemas"]["MediaFilter"]["media_type"],
//     newValue: components["schemas"]["MediaFilter"]["media_type"],
//   ): components["schemas"]["MediaFilter"]["media_type"] {
//     return newValue;
//   }
// }

class ClearTagByTypeFilterUpdateStrategy
  implements FilterUpdateStrategy<string[]>
{
  constructor(
    private tagType: string,
    private allTags: Tag[],
  ) {}

  updateFilter(currentArray: string[]): string[] {
    const hydratedCurrentArray = currentArray.map((tag) =>
      this.allTags.find((t) => t.id === tag),
    );
    const resultTags = hydratedCurrentArray.filter(
      (tag) => tag?.tag_type !== this.tagType,
    );
    return resultTags.filter((tag) => tag !== undefined).map((tag) => tag?.id);
  }
}

// This function ensures that we include all the keys in the map
function createStrategiesMap(strategies: {
  [K in BaseFilterStateKeys]: StrategyType<BaseFilterState[K]>;
}): Map<BaseFilterStateKeys, FilterUpdateStrategy<any>> {
  return new Map<BaseFilterStateKeys, FilterUpdateStrategy<any>>(
    Object.entries(strategies) as [
      BaseFilterStateKeys,
      FilterUpdateStrategy<any>,
    ][],
  );
}

export class FilterUpdateFacade {
  private strategies = new Map<
    BaseFilterStateKeys,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    FilterUpdateStrategy<any>
  >();

  constructor() {
    this.strategies = createStrategiesMap({
      // MediaFilterType keys
      people: new ArrayFilterUpdateStrategy<string>(),
      tags: new ArrayFilterUpdateStrategy<string>(),
      timecode_range: new ArrayFilterUpdateStrategy<string>(),

      // Not part of MediaFilterType
      similarImages: new ArrayFilterUpdateStrategy<string>(),
    });
  }

  resetFilterKey<K extends keyof BaseFilterState>(
    filterKey: K,
    currentState: BaseFilterState,
  ): BaseFilterState {
    const newState = { ...currentState };
    newState[filterKey] = EMPTY_FILTER_STATE[filterKey];
    return newState;
  }

  clearTagByTagType(
    allTags: Tag[],
    currentState: BaseFilterState,
    tagType: string,
  ): BaseFilterState {
    const newState = { ...currentState };
    const strategy = new ClearTagByTypeFilterUpdateStrategy(tagType, allTags);
    newState["tags"] = strategy.updateFilter(newState["tags"] || []);
    return newState;
  }

  updateFilter<K extends keyof BaseFilterState>(
    filterKey: K,
    currentState: BaseFilterState,
    newValue: BaseFilterState[K],
    overwrite = false,
  ) {
    const newState = { ...currentState };
    const strategy = this.strategies.get(filterKey);
    if (strategy) {
      newState[filterKey] = strategy.updateFilter(
        overwrite ? EMPTY_FILTER_STATE[filterKey] : newState[filterKey],
        newValue,
      );
    }
    return newState;
  }
}
