import {
  ClickableIcon,
  cn,
  Popover,
  PopoverContent,
  PopoverTrigger,
  TooltipProvider,
} from "@kino/ui";
import { SeasonStats } from "../analytics/SeasonStats";
import { useState } from "react";
import { BarChartHorizontalIcon } from "lucide-react";

const SeasonStatsPopover = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <TooltipProvider>
      <Popover modal={true} open={isOpen} onOpenChange={setIsOpen}>
        <PopoverTrigger>
          <ClickableIcon
            Icon={BarChartHorizontalIcon}
            name="season-stats"
            tooltip="Season Stats"
            className={cn(
              "rounded-md p-1 text-neutral-500 hover:bg-neutral-800",
              isOpen ? "bg-neutral-800" : "",
            )}
          />
        </PopoverTrigger>
        <PopoverContent
          className="h-96 w-96 overflow-hidden p-0"
          onOpenAutoFocus={(event) => {
            event.preventDefault();
            if (event.target) {
              (event.target as HTMLElement).focus();
            }
          }}
        >
          <SeasonStats />
        </PopoverContent>
      </Popover>
    </TooltipProvider>
  );
};

export default SeasonStatsPopover;
