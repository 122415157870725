import { forwardRef } from "react";

const ClipOutIcon = forwardRef<SVGSVGElement, React.SVGAttributes<SVGElement>>(
  (props, forwardedRef) => (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      ref={forwardedRef}
      {...props}
    >
      <path
        d="M18 33.5582L18 24.5317L23.7412 19.7374L18.0002 14.9432L18.0002 6"
        stroke="currentColor"
        strokeWidth="3.44476"
      />
    </svg>
  ),
);

export default ClipOutIcon;
