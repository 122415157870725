// Without this, glide table overlays will not appear!
// https://docs.grid.glideapps.com/api/dataeditor#html-css-prerequisites

const GlideTablePortal = () => {
  return (
    <div
      id="portal"
      style={{ position: "fixed", left: 0, top: 0, zIndex: 9999 }}
    />
  );
};

export default GlideTablePortal;
