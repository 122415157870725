import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  type TooltipContentProps,
} from "./tooltip";

import { type ReactNode } from "react";
import KeyboardShortcutItem from "./keyboard-shortcut-item";

interface TooltipProps extends TooltipContentProps {
  tooltipText: string;
  shortcutKeys?: string[];
  children: ReactNode;
  arrowPointed?: boolean;
  position?: "top" | "right" | "bottom" | "left";
  size?: "xs" | "sm" | "md" | "lg";
  verticalOffset?: number;
  delayDuration?: number;
  disabled?: boolean;
  enabled?: boolean; // Added enabled prop
  asChild?: boolean;
}

const TextWithTooltip = ({
  children,
  tooltipText,
  className,
  shortcutKeys,
  arrowPointed,
  position,
  verticalOffset,
  delayDuration,
  disabled,
  asChild = true,
  enabled = true,
  ...props
}: TooltipProps) => {
  // const tooltipContentClass = `
  //     inline-flex items-center rounded-md px-2 py-2
  //     bg-gray-50
  //     text-black dark:text-gray-800
  //     fadeIn
  //     will-change-[transform,opacity]
  //     data-[state=closed]:animate-fadeOut
  //     forefront
  //   `;

  return (
    <TooltipProvider disableHoverableContent={true} delayDuration={50}>
      <Tooltip delayDuration={delayDuration}>
        <TooltipTrigger
          data-align="start"
          asChild={asChild}
          className="cursor-default select-none"
        >
          {children}
        </TooltipTrigger>
        {!disabled &&
          enabled && ( // Added enabled check
            <TooltipContent
              sideOffset={6}
              side={position || "left"}
              arrowPadding={6}
              hideWhenDetached={false}
              {...props}
            >
              {/* <TooltipArrow TODO: figure out how to get this styled and working again!
                width={7}
                height={7}
                // className="fill-gray-500"
                asChild={!arrowPointed || false}
              /> */}
              <div className="forefront ml-1 inline-flex items-center text-sm">
                <div className="text-md forefront block text-xs leading-none">
                  {tooltipText}
                </div>
                {shortcutKeys && (
                  <div className="forefront ml-3">
                    <KeyboardShortcutItem keys={shortcutKeys} />
                  </div>
                )}
              </div>
            </TooltipContent>
          )}
      </Tooltip>
    </TooltipProvider>
  );
};

export { TextWithTooltip };
