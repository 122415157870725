import { type PanelProps } from "react-resizable-panels";
import {
  KinoButtons,
  KinoSliders,
  KinoTimes,
  LocalMediaStorageWithoutTime,
  Player,
  PlayerMoment,
  useMediaRemote,
  usePrimaryViewportContext,
} from "@kino/player";
import { useEffect } from "react";
import { TooltipProvider } from "@kino/ui";
import { useActiveServerStore } from "@/store/activeServerStore";
import PanelHeader from "@/layouts/panels/PanelHeader";
import { useSelectedMediaStore } from "@/store/selectedMediaStore";
import { getFileName } from "@/utils/pathUtils";
import { PlayerMomentType } from "../../../../../packages/player/src/context/PlayerMomentsContext";

const storage = new LocalMediaStorageWithoutTime();

const ViewportPanelEmptyState: React.FC = () => {
  return (
    <div className="flex h-64 w-full items-center justify-center text-xs text-neutral-700">
      No Media
    </div>
  );
};

interface ViewportPanelMultipleMediaProps {
  numSelectedMedia: number;
}

const ViewportPanelMultipleMedia: React.FC<ViewportPanelMultipleMediaProps> = ({
  numSelectedMedia,
}) => {
  return (
    <div className="flex h-64 w-full items-center justify-center text-xs text-neutral-700">
      {numSelectedMedia} selected
    </div>
  );
};

const ViewportPanel: React.FC<PanelProps> = (props) => {
  const { playerRef } = usePrimaryViewportContext();
  const remote = useMediaRemote();
  const { selectedMedia } = useSelectedMediaStore((state) => ({
    selectedMedia: state.currentSelection,
  }));
  const { buildServerUrl } = useActiveServerStore();
  const numSelectedMedia = selectedMedia?.length ?? 0;
  const singleSelection =
    numSelectedMedia == 1 ? selectedMedia?.[0] : undefined;

  const { mediaItem, activeInspectorMoment } = singleSelection ?? {};
  const moment = activeInspectorMoment?.moment;

  useEffect(() => {
    if (moment) {
      remote.seek(moment?.start ?? 0);
    }
  }, [moment, remote]);

  return (
    <div className="flex flex-col">
      <PanelHeader className="flex items-center justify-between gap-2">
        <div className="flex items-center gap-1">Viewport</div>
        {singleSelection && (
          <span className="truncate text-ellipsis text-xs text-neutral-400">
            {getFileName(mediaItem?.ffprobe_data?.format.filename || "")}
          </span>
        )}
      </PanelHeader>

      {(!selectedMedia || numSelectedMedia < 1) && <ViewportPanelEmptyState />}
      {selectedMedia && numSelectedMedia > 1 && (
        <ViewportPanelMultipleMedia numSelectedMedia={numSelectedMedia} />
      )}
      {selectedMedia && singleSelection && mediaItem && (
        <Player
          key={"search-result-panel-player-" + singleSelection?.id}
          storage={storage}
          ref={playerRef}
          src={buildServerUrl(mediaItem.hls_manifest_path)}
          useDefaultControls={false}
          className="flex aspect-auto min-h-64 w-full flex-col items-center justify-center bg-black"
          currentTime={moment?.start ?? 0}
          duration={
            Number(mediaItem?.ffprobe_data?.format.duration) ?? undefined
          }
          frameRate={
            mediaItem?.ffprobe_data && [
              mediaItem.ffprobe_data.framerate_numerator || 30000,
              mediaItem.ffprobe_data.framerate_denominator || 1001,
            ]
          }
          zoom={
            moment?.start && moment?.end
              ? {
                  start: moment.start - 1,
                  end: moment.end + 1,
                }
              : undefined
          }
          shouldZoomTrackCurrentTime
          customControls={
            <div className="flex w-full flex-col border-b">
              <TooltipProvider>
                <KinoSliders.TimeWithMoments className="border-b" />
              </TooltipProvider>
              <KinoSliders.Moments className="border-b" />
              <KinoSliders.Window withMiniMap />
              {moment?.start != null && moment?.end != null && (
                <PlayerMoment
                  startTime={moment.start}
                  endTime={moment.end}
                  type={PlayerMomentType.Video}
                />
              )}
              <div className="flex w-full items-center gap-2 border-t bg-neutral-900 p-2">
                <TooltipProvider>
                  <KinoButtons.Play />
                  <div className="no-scrollbar flex shrink gap-2 overflow-x-scroll">
                    <KinoButtons.ClipIn />
                    <KinoButtons.ClipOut />
                    <KinoButtons.LoopClip />
                    <KinoButtons.JumpToClipIn />
                    <KinoButtons.JumpToClipOut />
                    <KinoButtons.ZoomToMoment />
                  </div>
                  <span className="grow" />
                  <KinoTimes.Times />
                  <KinoButtons.Fullscreen />
                </TooltipProvider>
              </div>
            </div>
          }
          autoPlay
        />
      )}
    </div>
  );
};

export { ViewportPanel };
