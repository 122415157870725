import { CopyButton } from "@components/CopyButton";
import React, { useState, useEffect, useRef } from "react";
import { Panel } from "react-resizable-panels";
import { SignedIn, UserButton, useUser } from "@clerk/clerk-react";
import { useActiveServerStore } from "@/store/activeServerStore";
import { useOrganizationWorkspaces } from "@/hooks/useWorkspaces";
import {
  Button,
  Checkbox,
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
  TextWithTooltip,
} from "@kino/ui";
import { ChevronDownIcon, InfoCircledIcon } from "@radix-ui/react-icons";
import ServerConnectionForm, {
  ServerConnectionFormRef,
} from "@/components/forms/ServerConnectionForm";
import { serverUrlToFormValues } from "@/utils/stringUtils";
import { useDebuggerStore } from "@/store/debuggerStore";
import { useTheme } from "@/context/theme-provider";
import { ThemeSwitcher } from "@/components/theme/ThemeSwitcher";
import { usePythonServerStatus } from "@/hooks/usePythonServerStatus";

interface DebuggerItemProps {
  label: string;
  contents: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  height?: number;
  view?: "json" | "text";
}

const DebuggerItem: React.FC<DebuggerItemProps> = ({
  label,
  contents,
  height,
}) => {
  const renderContents = () => {
    return (
      <textarea
        style={{ height: height && `${height}px` }}
        className="h-20 w-full whitespace-pre-wrap break-words border bg-neutral-800 py-1"
        readOnly
        value={JSON.stringify(contents, null, 2)}
      />
    );
  };

  const contentString = JSON.stringify(contents, null, 2);

  return (
    <div className="flex w-full flex-col py-2 text-xs text-gray-500">
      <div className="flex items-center justify-between">
        <div className="font-semibold">{label.toUpperCase()}:</div>
        {contentString && contentString.length > 0 && (
          <CopyButton
            className="ml-2"
            content={contentString}
            toastMessage="Contents copied to clipboard"
            tooltipText="Copy contents"
          />
        )}
      </div>
      {renderContents()}
    </div>
  );
};

const DebuggerPanel = () => {
  const { user } = useUser();
  const [isScrolling, setIsScrolling] = useState(false);
  const scrollAreaRef = useRef<HTMLDivElement>(null);
  const isPythonServerOnline = usePythonServerStatus();
  const { workspaces } = useOrganizationWorkspaces();
  const { theme, setTheme } = useTheme();
  const { activeServerUrl, serverOverrideUrl, setServerOverrideUrl } =
    useActiveServerStore();
  const formRef = useRef<ServerConnectionFormRef>(null);
  const isMockSearchResults = useDebuggerStore(
    (state) => state.isMockSearchResults,
  );
  const setIsMockSearchResults = useDebuggerStore(
    (state) => state.setIsMockSearchResults,
  );
  const isMockMediaFiles = useDebuggerStore(
    (state) => state.isMockMediaEndpoint,
  );
  const setIsMockMediaEndpoint = useDebuggerStore(
    (state) => state.setIsMockMediaEndpoint,
  );
  const isMockSeasonStats = useDebuggerStore(
    (state) => state.isMockSeasonStats,
  );
  const setIsMockSeasonStats = useDebuggerStore(
    (state) => state.setIsMockSeasonStats,
  );
  const isMockTagsEndpoint = useDebuggerStore(
    (state) => state.isMockTagsEndpoint,
  );
  const setIsMockTagsEndpoint = useDebuggerStore(
    (state) => state.setIsMockTagsEndpoint,
  );

  useEffect(() => {
    const scrollArea = scrollAreaRef.current;
    if (scrollArea) {
      const checkScroll = () => {
        setIsScrolling(scrollArea.scrollTop > 0);
      };
      scrollArea.addEventListener("scroll", checkScroll);
      return () => {
        scrollArea.removeEventListener("scroll", checkScroll);
      };
    }
  }, []);

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.submit();
    }
  };

  const userInfoBlock = (
    <SignedIn>
      <UserButton />
      <div className="flex flex-col gap-1 text-sm text-gray-800">
        <div>{user?.fullName}</div>
        <div className="text-xs text-gray-400">
          {user?.emailAddresses[0]?.emailAddress}
        </div>
      </div>
    </SignedIn>
  );
  return (
    <Panel
      minSize={20}
      maxSize={30}
      defaultSize={20}
      className="pt-2"
      id="debugger-panel"
      order={4}
    >
      <div className="flex h-full w-full flex-col gap-4 divide-y overflow-hidden">
        <div
          className={`flex items-center gap-1 ${isScrolling ? "border-b" : ""}`}
        >
          <div className="flex flex-col gap-1 px-3">
            <div className="text-sm font-medium text-gray-600">Debugger</div>
            <div className="text-xs text-gray-400">
              I only exist in dev mode!
            </div>
          </div>
        </div>
        <div className="flex items-center gap-2 px-3 pt-3">
          <ThemeSwitcher />
          <div className="light:bg-neutral-200 light:text-neutral-500 rounded-sm bg-neutral-800 p-2 text-xs">
            Theme: {theme}
          </div>
        </div>
        <div className="flex flex-col gap-2 px-3 pt-3">
          <div className="flex items-center gap-1">
            <Checkbox
              checked={isMockSearchResults}
              onCheckedChange={(checked) =>
                setIsMockSearchResults(checked as boolean)
              }
            />
            <div className="text-xs text-gray-400">Mock Search Results</div>
          </div>
          <div className="flex items-center gap-1">
            <Checkbox
              checked={isMockMediaFiles}
              onCheckedChange={(checked) =>
                setIsMockMediaEndpoint(checked as boolean)
              }
            />
            <div className="text-xs text-gray-400">Mock Media Files</div>
          </div>
          <div className="flex items-center gap-1">
            <Checkbox
              checked={isMockSeasonStats}
              onCheckedChange={(checked) =>
                setIsMockSeasonStats(checked as boolean)
              }
            />
            <div className="text-xs text-gray-400">Mock Season Stats</div>
          </div>
          <div className="flex items-center gap-1">
            <Checkbox
              checked={isMockTagsEndpoint}
              onCheckedChange={(checked) =>
                setIsMockTagsEndpoint(checked as boolean)
              }
            />
            <div className="text-xs text-gray-400">Mock Tags Endpoint</div>
          </div>
        </div>
        <div className="flex flex-col gap-3 p-3 text-xs text-gray-500">
          <div>
            <div className="text-gray-400">Active Server URL</div>
            <div className="text-gray-600">
              {serverOverrideUrl ? (
                <>
                  <span className="line-through">{activeServerUrl}</span>
                  <span className="ml-2 text-green-600">
                    {serverOverrideUrl}
                  </span>
                </>
              ) : (
                activeServerUrl
              )}
            </div>
          </div>
          <div>
            <div className="flex items-center gap-1">
              <div className="text-gray-400">Org Workspaces</div>
              <TextWithTooltip tooltipText="These values are entirely defined in the org's Clerk publicMetadata">
                <InfoCircledIcon className="h-3 w-3" />
              </TextWithTooltip>
            </div>
            <div className="text-gray-600">
              {workspaces &&
                workspaces.length > 0 &&
                workspaces?.map((workspace) => (
                  <div className="text-xs text-gray-600" key={workspace.id}>
                    {workspace.name} — {workspace.ip}:{workspace.port}
                  </div>
                ))}
              {(!workspaces || workspaces.length === 0) && (
                <div className="text-xs text-gray-600">No workspaces found</div>
              )}
            </div>
          </div>
          <div>
            <Collapsible defaultOpen={!!serverOverrideUrl}>
              <CollapsibleTrigger className="flex items-center justify-between gap-1 py-1">
                <div className="flex items-center gap-1">
                  <div className="text-gray-400">Server Override</div>
                  <TextWithTooltip tooltipText="This overrides the Clerk-defined server URL">
                    <InfoCircledIcon className="h-3 w-3" />
                  </TextWithTooltip>
                </div>
                <ChevronDownIcon className="h-3 w-3" />
              </CollapsibleTrigger>
              <CollapsibleContent className="flex flex-col gap-2">
                <div className="flex flex-col gap-2">
                  <ServerConnectionForm
                    ref={formRef}
                    defaultValues={
                      serverOverrideUrl
                        ? serverUrlToFormValues(serverOverrideUrl)
                        : undefined
                    }
                  />
                  <div className="flex items-center justify-between">
                    <Button onClick={handleSubmit} size="xsm" variant="outline">
                      Submit
                    </Button>
                    <Button
                      onClick={() => setServerOverrideUrl(null)}
                      variant="minimal"
                      size="xsm"
                      disabled={!serverOverrideUrl}
                    >
                      Clear
                    </Button>
                  </div>
                </div>
              </CollapsibleContent>
            </Collapsible>
          </div>
        </div>
        <div
          ref={scrollAreaRef}
          className={`flex w-full flex-grow flex-col items-start gap-1 overflow-y-scroll px-3 py-3`}
        >
          {userInfoBlock}
          <div className="flex w-full flex-col gap-2 text-xs text-gray-500">
            <DebuggerItem
              label="Python Server Status"
              contents={
                isPythonServerOnline === null
                  ? "Checking..."
                  : isPythonServerOnline
                    ? "Online"
                    : "Offline"
              }
            />
          </div>
        </div>
      </div>
    </Panel>
  );
};

export { DebuggerPanel };
