import React from "react";
import {
  Command,
  CommandInput,
  CommandList,
  CommandEmpty,
  CommandGroup,
} from "@kino/ui";
import { SecondaryFilterPanelContent } from "../SecondaryFilterPanel";
import TagsList from "./TagsList";
import { useFilterOptionsStore } from "@/store/filter/filterOptionsStore";
import { FocusedSecondaryPanelFilter } from "@/store/filter/secondaryFilterPanelStore";

interface TagsSecondaryFilterPanelContentProps {
  focusedFilter: FocusedSecondaryPanelFilter;
  onClose: () => void;
}

const TagsSecondaryFilterPanelContent: React.FC<
  TagsSecondaryFilterPanelContentProps
> = ({ focusedFilter }) => {
  const { filterOptions } = useFilterOptionsStore();

  if (focusedFilter.key !== "tags" || !focusedFilter.tagType) return null;

  return (
    <SecondaryFilterPanelContent>
      <Command className="flex flex-col gap-2">
        <CommandInput
          searchLoading={false}
          placeholder="Search tags"
          className="h-7 rounded-md border border-neutral-800 px-1"
        />
        <CommandList className="flex h-full max-h-none flex-col">
          <CommandEmpty>No tags found</CommandEmpty>
          <CommandGroup className="flex h-full flex-col pb-2">
            <TagsList
              tags={
                filterOptions.tags?.criteria?.[focusedFilter.tagType]?.tags ??
                []
              }
            />
          </CommandGroup>
        </CommandList>
      </Command>
    </SecondaryFilterPanelContent>
  );
};

export default TagsSecondaryFilterPanelContent;
