import { ScrollArea } from "@kino/ui";
import useGetSearchMode from "@/hooks/useGetSearchMode";
import AllResultsView from "./AllResultsView";
import SearchNoResultsView from "./SearchNoResultsView";
import BucketResultView from "./BucketResultView";
import { useEffect } from "react";
import { useSelectedMediaStore } from "@/store/selectedMediaStore";
import { components } from "@/openapi-bindings/v2";
import { useOmniSearch } from "@/hooks/useOmniSearch";

const getResultsView = (
  searchMode: components["schemas"]["SearchMode"],
  searchResponse?: components["schemas"]["SearchResponse"],
) => {
  if (!searchResponse) {
    return null;
  }

  if (searchMode === "omni") {
    return <AllResultsView />;
  }

  return <BucketResultView searchMode={searchMode} />;
};

const SearchResultsView = () => {
  const tabType = useGetSearchMode();
  const { searchResponse } = useOmniSearch();
  const { setSelectedMedia } = useSelectedMediaStore((state) => ({
    setSelectedMedia: state.setCurrentSelection,
  }));

  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setSelectedMedia([]);
      }
    };

    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, []);

  const searchView = getResultsView(tabType, searchResponse);

  return (
    <>
      {searchView && (
        <ScrollArea>
          <div className="flex h-full flex-col gap-8">{searchView}</div>
        </ScrollArea>
      )}
      {!searchView && <SearchNoResultsView />}
    </>
  );
};

export default SearchResultsView;
