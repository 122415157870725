// import { useEffect, useRef, useState } from "react";
// import { cn, Button, ScrollArea } from "@kino/ui";
// import { FilterBadgeSkeleton } from "./FilterBadgeSkeleton";
// import { MinusIcon } from "@radix-ui/react-icons";
// import { useFilter } from "../../../../contexts/FilterContext";
// import { ViewfinderCircleIcon } from "@heroicons/react/24/outline";
// import { useSearchResultsFilterStateStore } from "../../../../store/filter/searchResultFilterStateStore";
// import JustifiedLayout from "@/layouts/JustifiedLayout";
// import { useSearchQueryStore } from "@/store/search/searchQueryStore";

// interface FilterBadgeImageProps {
//   frameId?: string;
//   src: string;
//   onClick?: () => void;
// }

// const FilterBadgeImage: React.FC<FilterBadgeImageProps> = ({
//   src,
//   onClick,
//   frameId,
// }) => {
//   const [isHovering, setIsHovering] = useState(false);
//   return (
//     <div
//       key={frameId}
//       onMouseEnter={() => setIsHovering(true)}
//       onMouseLeave={() => setIsHovering(false)}
//       className={`relative flex cursor-pointer flex-col-reverse items-center justify-center bg-gray-100`}
//     >
//       <div className="absolute bottom-0 left-0 right-0 top-0 p-2">
//         <div className="flex flex-row-reverse justify-between">
//           <div
//             className={cn(
//               "absolute inset-0 bg-gradient-to-b from-black to-transparent opacity-0 transition-opacity",
//               isHovering && "opacity-70",
//             )}
//           />
//           <div
//             onClick={onClick}
//             className={cn(
//               "z-10 flex items-center justify-center rounded-full p-1 opacity-0 transition-all hover:bg-white hover:bg-opacity-20 hover:backdrop-blur-md",
//               isHovering && "visible opacity-100",
//             )}
//           >
//             <MinusIcon className="h-4 w-4 text-white" />
//           </div>
//         </div>
//       </div>
//       <div>
//         <img src={src} alt="frame" width={1000} height={1000} />
//       </div>
//     </div>
//   );
// };

// const FilterBadgeVisuallySimilarFramesPopoverContent = () => {
//   const base64ImgSrcFrames = useSearchResultsFilterStateStore(
//     (state) => state.searchResultsFilterState.similarImages,
//   );
//   const parsedSearchQuery = useSearchQueryStore(
//     (state) => state.parsedSearchQuery,
//   );
//   const { removeSimilarFrameByIds } = useFilter();
//   const [aspectRatioArray, setAspectRatioArray] = useState<
//     { width: number; height: number }[] | undefined
//   >(undefined);
//   const justifiedLayoutRef = useRef<HTMLDivElement>(null);

//   useEffect(() => {
//     if (base64ImgSrcFrames.length > 0) {
//       const aspectRatioArray = base64ImgSrcFrames.map(async (src) => {
//         return new Promise<{ width: number; height: number }>((resolve) => {
//           const img = new Image();
//           img.onload = () => {
//             resolve({ width: img.naturalWidth, height: img.naturalHeight });
//           };
//           img.src = src;
//         });
//       });
//       Promise.all(aspectRatioArray).then(setAspectRatioArray);
//     }
//   }, [base64ImgSrcFrames]);

//   if (!parsedSearchQuery) return null;

//   return (
//     <div className="h-[400px] w-[400px] text-xs">
//       <div className="flex items-center justify-between border-b p-3">
//         <div>
//           Visual similarity search for {parsedSearchQuery.frames.length}{" "}
//           {parsedSearchQuery.frames.length > 1 ? "frames" : "frame"}
//         </div>
//         <Button
//           variant="minimal"
//           onClick={() =>
//             removeSimilarFrameByIds(
//               parsedSearchQuery.frames.map((frame) => frame.id),
//             )
//           }
//           className="h-auto p-0 text-xs text-gray-300 hover:bg-transparent hover:text-indigo-600"
//         >
//           Clear all
//         </Button>
//       </div>
//       {aspectRatioArray && base64ImgSrcFrames && (
//         <ScrollArea className="h-full max-h-[400px] w-[400px] overflow-x-auto overflow-y-scroll px-3 py-3">
//           <div>
//             <JustifiedLayout
//               ref={justifiedLayoutRef}
//               aspectRatios={aspectRatioArray}
//               config={{ targetRowHeight: 120, boxSpacing: 10 }}
//             >
//               {base64ImgSrcFrames.map((base64ImgSrc, index) => {
//                 return (
//                   <FilterBadgeImage
//                     key={index}
//                     src={base64ImgSrc}
//                     onClick={() => {
//                       const frameId = parsedSearchQuery.frames[index]?.id;
//                       if (frameId) removeSimilarFrameByIds([frameId]);
//                     }}
//                   />
//                 );
//               })}
//             </JustifiedLayout>
//           </div>
//         </ScrollArea>
//       )}
//     </div>
//   );
// };

interface FilterBadgeVisuallySimilarFramesProps {
  displayOnly?: boolean;
  focused?: boolean;
}

// const FilterBadgeVisuallySimilarFrames = ({
//   displayOnly = false,
//   focused = true,
// }: FilterBadgeVisuallySimilarFramesProps) => {
//   const { parsedSearchQuery, removeSimilarFrameByIds } = useFilter();
//   const base64ImgSrcFrames = useSearchResultsFilterStateStore(
//     (state) => state.searchResultsFilterState.similarImages,
//   );
//   if (!parsedSearchQuery || base64ImgSrcFrames.length === 0) return null;

//   return (
//     <FilterBadgeSkeleton
//       variant={displayOnly ? "displayOnly" : "default"}
//       focus={focused ? "focused" : "unfocused"}
//       popoverContent={<FilterBadgeVisuallySimilarFramesPopoverContent />}
//       classNamePopover="w-[400px] h-[400px]"
//       onClose={() =>
//         removeSimilarFrameByIds(
//           parsedSearchQuery.frames.map((frame) => frame.id),
//         )
//       }
//     >
//       <ViewfinderCircleIcon className="h-4 w-4" />
//       visually similar to
//       <div className="flex items-center">
//         {base64ImgSrcFrames.slice(0, 3).map((base64ImgSrc, index) => {
//           return (
//             <div key={index} className={cn("h-4", index !== 0 && "-ml-2")}>
//               <img
//                 src={base64ImgSrc}
//                 alt="frame"
//                 className="h-4 w-auto rounded-sm border"
//                 width={400}
//                 height={400}
//               />
//             </div>
//           );
//         })}
//       </div>
//       {base64ImgSrcFrames.length}{" "}
//       {base64ImgSrcFrames.length > 1 ? "frames" : "frame"}
//     </FilterBadgeSkeleton>
//   );
// };

// export default FilterBadgeVisuallySimilarFrames;

const FilterBadgeVisuallySimilarFrames = ({
  displayOnly = false,
  focused = true,
}: FilterBadgeVisuallySimilarFramesProps) => {
  return <div>FilterBadgeVisuallySimilarFrames</div>;
};

export default FilterBadgeVisuallySimilarFrames;
