import { cn, Skeleton } from "@kino/ui";
import React from "react";

interface LoadingProps extends React.HTMLAttributes<HTMLDivElement> {}

const Loading = ({ className }: LoadingProps) => {
  return (
    <div
      className={cn(
        "flex h-full w-full flex-col items-center justify-center",
        className,
      )}
    >
      <div className="flex items-center gap-2">
        <Skeleton>
          <div className="h-2 w-2 rounded-full bg-indigo-500"></div>
        </Skeleton>
        <span className="text-xs text-neutral-500">Loading...</span>
      </div>
    </div>
  );
};

export default Loading;
