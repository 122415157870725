// React Query
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { ClerkProvider } from "@clerk/clerk-react";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { useCommandMenuStore } from "@/store/commandMenuStore";
import { useEffect } from "react";
import { queryClient } from "@/hooks/useClient";
import { ThemeProvider } from "@/context/theme-provider";
import { dark } from "@clerk/themes";
import { Toaster } from "sonner";

posthog.init(import.meta.env.VITE_APP_PUBLIC_POSTHOG_KEY, {
  api_host: import.meta.env.VITE_APP_PUBLIC_POSTHOG_HOST,
  person_profiles: "identified_only",
});

const PUBLISHABLE_KEY = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY;

if (!PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key");
}

export default function RootLayout() {
  const navigate = useNavigate();

  const location = useLocation();
  const { reset } = useCommandMenuStore();

  useEffect(() => {
    reset();
  }, [location]);

  return (
    <ClerkProvider
      routerPush={(to: any) => navigate(to)}
      routerReplace={(to: any) => navigate(to)}
      publishableKey={PUBLISHABLE_KEY}
      appearance={{
        baseTheme: dark,
      }}
    >
      <QueryClientProvider client={queryClient}>
        <PostHogProvider client={posthog}>
          <ThemeProvider>
            <main>
              <Outlet />
            </main>
            <Toaster />
          </ThemeProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </PostHogProvider>
      </QueryClientProvider>
    </ClerkProvider>
  );
}
