import { useCallback } from "react";
import { useClient } from "@/hooks/useClient";
import { toast } from "sonner";
import { ImportStatus, useAVBFileStore } from "@/store/avbFileStore";
import { formatStringPlurality } from "@/utils/stringUtils";
import { Spinner } from "@kino/ui";
import { CheckIcon, ExclamationTriangleIcon } from "@radix-ui/react-icons";

export const getStatusIcon = (status: ImportStatus) => {
  switch (status) {
    case "importing":
      return <Spinner />;
    case "imported":
      return <CheckIcon />;
    case "failed":
      return <ExclamationTriangleIcon />;
  }
};

export const getStatusColor = (status: ImportStatus) => {
  switch (status) {
    case "importing":
      return "text-neutral-500";
    case "imported":
      return "text-green-500";
    case "failed":
      return "text-red-500";
  }
};

export const useAVBUpload = () => {
  const { fetchClient } = useClient();
  const { addImportedAVBFile, updateImportedAVBFile } = useAVBFileStore();
  const handleUpload = useCallback(
    async (filesToUpload: File[]) => {
      console.error("Need to implement upload");
      // if (filesToUpload.length === 0) return;

      // const formData = new FormData();
      // const timestamp = new Date()
      //   .toISOString()
      //   .replace(/[:T]/g, "-")
      //   .split(".")[0];

      // const fileIds = filesToUpload.map((file) => {
      //   const uniqueFileName = `${timestamp}_${file.name}`;
      //   const id = addImportedAVBFile(uniqueFileName, "importing");
      //   return { id, file, uniqueFileName };
      // });

      // fileIds.forEach(({ file, uniqueFileName }) => {
      //   formData.append("data", file, uniqueFileName);
      // });

      // try {
      //   const res = await fetchClient.POST("/upload_avb", {
      //     body: formData as unknown as { data: string[] }, // this sucks, the types are
      //   });

      //   if (res.response.status === 200) {
      //     const responseData = res.data as Record<string, string>;

      //     let successCount = 0;
      //     fileIds.forEach(({ id, uniqueFileName }) => {
      //       const fileResponse = responseData[uniqueFileName];
      //       if (
      //         fileResponse &&
      //         fileResponse.includes("uploaded successfully")
      //       ) {
      //         updateImportedAVBFile(id, "imported");
      //         successCount++;
      //       } else {
      //         updateImportedAVBFile(id, "failed");
      //         toast.error(
      //           `Issue with ${uniqueFileName}: ${fileResponse || "Unknown error"}`,
      //         );
      //       }
      //     });

      //     if (successCount > 0) {
      //       toast.success(
      //         `Successfully imported ${successCount} ${formatStringPlurality(successCount, "file", "files")}`,
      //       );
      //     }
      //   } else {
      //     console.error("Upload failed", res);
      //     fileIds.forEach(({ id }) => updateImportedAVBFile(id, "failed"));

      //     let errorMessage = `Upload failed with status: ${res.response.status}`;
      //     if (res.data && typeof res.data === "object" && "error" in res.data) {
      //       errorMessage = `Upload failed: ${res.data.error}`;
      //     }

      //     toast.error(errorMessage);
      //   }
      // } catch (error) {
      //   console.error("Error uploading files", error);
      //   fileIds.forEach(({ id }) => updateImportedAVBFile(id, "failed"));
      //   toast.error(
      //     `Failed to import files: ${error instanceof Error ? error.message : "Unknown error"}`,
      //   );
      // }
    },
    [fetchClient, addImportedAVBFile, updateImportedAVBFile],
  );

  return { handleUpload };
};
