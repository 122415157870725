import { type components } from "@/openapi-bindings/v2";

export type RecentSearch = {
  searchRequest: components["schemas"]["SearchRequest"];
  date: Date;
  id: string;
};

export interface ParsedQuery {
  frames: { id: string; isPositive: boolean }[];
  queryString?: string;
}

export type HydratedBucket = {
  key: string;
  moments: components["schemas"]["Moment"][];
};

export enum ViewType {
  Grid = "grid",
  List = "list",
}
