import { type FormEvent, useState, useRef, useCallback } from "react";
import { Checkbox, cn } from "@kino/ui";
import { components } from "@/openapi-bindings/v1";
import { CopyButton } from "../CopyButton";
import {
  useMediaRemote,
  useMediaStore,
  usePrimaryViewportContext,
  ZOOM_EVENT_TYPE,
} from "@kino/player";
import { secondsToTimestamp } from "@/utils/time";
import { BorderDashedIcon } from "@radix-ui/react-icons";

interface TranscriptSegmentTextAreaProps {
  editedSegment: components["schemas"]["Segment"];
  index: number;
  isSelected: boolean;
  setSelected: (index: number) => void;
  showOptions: boolean;
  videoGlobalTimecode?: number;
  isHighlighted?: boolean;
  isFiller?: boolean;
}

const TranscriptSegmentTextArea = ({
  editedSegment,
  index,
  isSelected,
  setSelected,
  showOptions,
  isHighlighted,
  isFiller,
}: TranscriptSegmentTextAreaProps) => {
  const segmentId = `segment-${index}`;
  const { playerRef } = usePrimaryViewportContext();
  const { currentTime, clipStartTime } = useMediaStore(playerRef);
  const remote = useMediaRemote(playerRef);

  const [value, setValue] = useState<string>(editedSegment.text);
  const editableSpanRef = useRef<HTMLSpanElement>(null);
  const transcriptSegmentRef = useRef<HTMLDivElement>(null);

  const handleOnInput = (e: FormEvent<HTMLSpanElement>) => {
    if (e.currentTarget.textContent !== null)
      setValue(e.currentTarget.textContent);
    if (editableSpanRef.current && e.currentTarget.textContent === "")
      editableSpanRef.current.textContent = null;
  };

  const currentTimeInRange =
    currentTime >= editedSegment.start && currentTime < editedSegment.end;

  const handleOnFocus = () => {
    remote.seek(
      editedSegment.start - (clipStartTime || 0),
      new Event(ZOOM_EVENT_TYPE),
    );
  };

  const handleMouseDown = useCallback(
    (event: React.MouseEvent) => {
      if (event.button === 2) {
        // Right mouse button
        setSelected(index);
      }
    },
    [index, setSelected],
  );

  const handleJumpToSegment = () => {
    remote.seek(
      editedSegment.start - (clipStartTime || 0),
      new Event(ZOOM_EVENT_TYPE),
    );
  };

  const progressPercentage =
    ((currentTime - editedSegment.start) /
      (editedSegment.end - editedSegment.start)) *
    100;

  return (
    <div
      className={cn(
        "group relative flex w-full p-2 pl-0 hover:bg-neutral-800/60",
        `${isSelected ? "bg-neutral-800/60" : ""}`,
        currentTimeInRange || isSelected ? "bg-neutral-800/60" : "bg-none",
        isHighlighted && "bg-indigo-500/20 hover:bg-indigo-500/30",
        isSelected && isHighlighted && "bg-indigo-500/30",
      )}
      onClick={handleJumpToSegment}
    >
      {!isFiller && (
        <CopyButton
          className="absolute right-2 top-1 opacity-0 group-hover:opacity-100"
          content={value}
          toastMessage="Copied segment to clipboard"
          tooltipText="Copy to clipboard"
        />
      )}
      <div className="flex w-5 min-w-5 max-w-5 justify-center">
        {/* TODO: Show again when we enable selection */}
        {false && showOptions && !isFiller && (
          <Checkbox
            checked={isSelected}
            onCheckedChange={() => setSelected(index)}
            onClick={(e) => e.stopPropagation()}
            className="h-3 w-3 rounded-sm border-neutral-600 transition-opacity hover:border-neutral-400 data-[state=checked]:border-indigo-600 data-[state=checked]:bg-indigo-600"
          />
        )}
      </div>
      <div
        ref={transcriptSegmentRef}
        id={segmentId}
        className="peer"
        onClick={() => {
          showOptions && setSelected(index);
        }}
        onMouseDown={handleMouseDown}
      >
        <div
          onClick={(e) => {
            e.stopPropagation();
            handleOnFocus();
          }}
          className="flex flex-col gap-1"
        >
          <div className="flex items-center gap-2">
            <div className="text-xsm cursor-default select-none text-neutral-500">
              {secondsToTimestamp(editedSegment.start)}
            </div>
          </div>
          {!isFiller && (
            <div>
              <p
                className={cn(
                  "block text-neutral-400",
                  isSelected && "text-neutral-300",
                )}
              >
                <span
                  ref={editableSpanRef}
                  onInput={handleOnInput}
                  className={`textarea w-full text-xs font-light ${value === "" && "empty"}`}
                  style={{ wordBreak: "break-word" }}
                  role="textbox"
                  // contentEditable
                  dangerouslySetInnerHTML={{ __html: editedSegment.text || "" }}
                  onMouseDown={() => editableSpanRef.current?.focus()}
                />
              </p>
            </div>
          )}
          {isFiller && (
            <div className="flex h-fit w-fit items-center text-xs text-neutral-600">
              <BorderDashedIcon className="h-3 w-3" />
            </div>
          )}
        </div>
      </div>
      {isHighlighted && (
        <div className="absolute bottom-0 left-0 top-0 w-0.5 bg-indigo-600"></div>
      )}
      {currentTimeInRange && (
        <div
          className="absolute left-0 w-1.5 bg-indigo-400"
          style={{ top: `${progressPercentage}%`, height: "1px" }}
        />
      )}
    </div>
  );
};

export default TranscriptSegmentTextArea;
