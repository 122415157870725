import { cn } from "@/utils/tailwind";
import { HTMLAttributes } from "react";

interface PanelHeaderProps extends HTMLAttributes<HTMLDivElement> {}

const PanelHeader: React.FC<PanelHeaderProps> = ({
  children,
  className,
  ...props
}) => {
  return (
    <div
      className={cn(
        "flex h-9 max-h-9 min-h-9 w-full items-center border-b p-2 text-xs text-white",
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
};

export default PanelHeader;
