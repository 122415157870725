import { Popover, PopoverTrigger, PopoverContent } from "@kino/ui";

import React from "react";
import { toast } from "sonner";
import { ClickableIcon } from "@kino/ui";
import {
  HandThumbUpIcon,
  HandThumbDownIcon,
  EllipsisVerticalIcon,
} from "@heroicons/react/24/outline";
import posthog from "posthog-js";
import { components } from "@/openapi-bindings/v2";
import { useSearchRequestStore } from "@/store/search/searchRequestStore";

const iconClassName =
  "p-0 text-xs font-normal text-neutral-700 hover:text-neutral-400";

interface OmniSearchCardPopoverProps {
  moment: components["schemas"]["Moment"];
}

const OmniSearchCardPopover = ({ moment }: OmniSearchCardPopoverProps) => {
  const searchRequest = useSearchRequestStore((state) => state.searchRequest);

  const handleReview = (isGood: boolean) => {
    posthog.capture("search_result_review", {
      review: isGood,
      moment,
      search_request: searchRequest,
    });
    toast.success("Your feedback has been received.");
  };

  return (
    <Popover>
      <PopoverTrigger className="flex items-center" asChild>
        <ClickableIcon
          Icon={EllipsisVerticalIcon}
          name="More"
          tooltip="More"
          className="text-neutral-600 hover:text-neutral-400"
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      </PopoverTrigger>
      <PopoverContent className="flex w-fit items-center gap-2 p-2">
        <ClickableIcon
          Icon={HandThumbUpIcon}
          name="Good result"
          tooltip="Good result"
          className={iconClassName}
          onClick={(e) => {
            e.stopPropagation();
            handleReview(true);
          }}
        />
        <ClickableIcon
          Icon={HandThumbDownIcon}
          name="Bad result"
          tooltip="Bad result"
          className={iconClassName}
          onClick={(e) => {
            e.stopPropagation();
            handleReview(false);
          }}
        />
      </PopoverContent>
    </Popover>
  );
};

export default OmniSearchCardPopover;
