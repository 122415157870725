import { twMerge } from "tailwind-merge";
import { clsx, type ClassValue } from "clsx";
import { TailwindColor } from "@/types/tailwind";
import colors from "tailwindcss/colors";

/** shadcn's version of classNames(), well-suited for Tailwind CSS merging
 */
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function hexToRGBA(hex: string, opacity: number): string {
  // Remove the hash if it exists
  hex = hex.replace("#", "");

  // Parse the hex values
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // Return rgba string
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

export function getTailwindColorWithOpacity(
  color: TailwindColor,
  shade: string,
  opacity: number,
): string {
  const colorValue =
    colors[color][shade as keyof (typeof colors)[TailwindColor]];
  return hexToRGBA(colorValue, opacity);
}
