interface BaseViewProps {
  children: React.ReactNode;
}

const BaseView = ({ children }: BaseViewProps) => {
  return (
    <div className="flex h-full w-full flex-col items-center justify-center gap-4 text-sm text-neutral-500">
      {children}
    </div>
  );
};

export default BaseView;
