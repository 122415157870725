import { useEffect, useRef, useState } from "react";
import { BaseFilters } from "@/types/filters";
import { useFilterState } from "@/hooks/useFilterState";
import useRenderFilterBadges from "@/hooks/useRenderFilterBadges";
import { InspectorCollapsiblePanelSection } from "@/components/inspector/collapsibleSection/InspectorCollapsiblePanelSection";
import { Button, cn } from "@kino/ui";
import { Cross2Icon } from "@radix-ui/react-icons";

interface AppliedFiltersPoolProps {
  filters: BaseFilters;
}

const AppliedFiltersPool: React.FC<AppliedFiltersPoolProps> = ({ filters }) => {
  const { resetFilterState, getNumAppliedFilters } = useFilterState();
  const { getFilterBadges, getAppliedFilterKeys } = useRenderFilterBadges();
  const [hasOverflow, setHasOverflow] = useState(false);
  const [scrollPercentage, setScrollPercentage] = useState(0);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const numAppliedFilters = getNumAppliedFilters();

  const checkOverflow = () => {
    if (scrollContainerRef.current) {
      const { scrollHeight, clientHeight } = scrollContainerRef.current;
      setHasOverflow(scrollHeight > clientHeight);
    }
  };

  const handleScroll = () => {
    if (scrollContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        scrollContainerRef.current;
      const scrollPercentage =
        (scrollTop / (scrollHeight - clientHeight)) * 100;
      setScrollPercentage(Math.min(scrollPercentage, 100));
    }
  };

  useEffect(() => {
    checkOverflow();
    handleScroll();

    const resizeObserver = new ResizeObserver(() => {
      checkOverflow();
      handleScroll();
    });

    if (scrollContainerRef.current) {
      resizeObserver.observe(scrollContainerRef.current);
      scrollContainerRef.current.addEventListener("scroll", handleScroll);
    }

    window.addEventListener("resize", checkOverflow);

    return () => {
      if (scrollContainerRef.current) {
        resizeObserver.unobserve(scrollContainerRef.current);
        scrollContainerRef.current.removeEventListener("scroll", handleScroll);
      }
      resizeObserver.disconnect();
      window.removeEventListener("resize", checkOverflow);
    };
  }, []);

  // Get applied filter keys from the hook
  const appliedFilterKeys = getAppliedFilterKeys();

  return (
    <InspectorCollapsiblePanelSection
      label={`Applied filters (${numAppliedFilters})`}
      name="appliedFilters"
      defaultOpen
      triggerRightContent={
        numAppliedFilters > 0 && (
          <Button
            variant="ghost"
            size="xsm"
            onClick={(e) => {
              e.stopPropagation();
              resetFilterState();
            }}
            className="flex h-5 items-center gap-1 rounded-full border border-neutral-700 px-1 text-xs text-neutral-500"
          >
            Clear all
            <Cross2Icon className="h-3 w-3 text-neutral-500" />
          </Button>
        )
      }
    >
      <div className="relative flex h-full w-full">
        {hasOverflow && (
          <div
            className="pointer-events-none absolute bottom-0 left-0 right-0 z-10 h-[50px] bg-gradient-to-t from-black/20 to-transparent transition-opacity duration-75"
            style={{ opacity: Math.max(0, 1 - scrollPercentage / 100) }}
          />
        )}
        <div
          ref={scrollContainerRef}
          className={cn(
            "h-24 min-h-24 w-full overflow-auto transition-all duration-150",
          )}
        >
          {numAppliedFilters === 0 && (
            <div className="flex h-full w-full items-center justify-center p-2 text-xs text-neutral-500">
              No filters applied
            </div>
          )}
          {numAppliedFilters > 0 && (
            <div className="flex flex-wrap items-start justify-start gap-2 p-2 text-xs">
              {appliedFilterKeys.map((key) => getFilterBadges(filters, key))}
            </div>
          )}
        </div>
      </div>
    </InspectorCollapsiblePanelSection>
  );
};

export default AppliedFiltersPool;
