import { ClockIcon, FaceIcon } from "@radix-ui/react-icons";
import type { FuzzyFilenameParams, OpAtomFilter } from "@/types/oldBindings";
import { TagIcon } from "@heroicons/react/24/outline";
import { type components } from "@/openapi-bindings/v2";

export type MediaFilter = {
  // Must be maintained, bad solution.
  people: string[];
  tags: string[];
  timecode_range?: [string, string] | null;
};
export type Tag = components["schemas"]["Tag"];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (
  k: infer I,
) => void
  ? I
  : never;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ExtractKeys<T> = T extends { [key: string]: any } ? keyof T : never;
type MediaFilterObjectKeys = ExtractKeys<MediaFilter>;
type ExcludeStringLiterals<T> = T extends string ? never : T;
type MediaFilterWithoutNone = ExcludeStringLiterals<MediaFilter>;
type MediaFilterIntersection = UnionToIntersection<MediaFilterWithoutNone>;
type MediaFilterType = MediaFilterIntersection;

// We're using exclude keys so typescript can get mad at you when you add a new filter but don't adjust for it everywhere
export const EXCLUDE_KEYS_NAVIGATION = ["similarImages"] as const;

// All possible rust defined filter keys
type MediaFilterKey = Exclude<MediaFilterObjectKeys, "None">;

// USE THIS: All possible filter keys including frontend defined ones
export type BaseFilterStateKeys = MediaFilterKey | "similarImages";

export type NavigationFilterKeys = Exclude<
  BaseFilterStateKeys,
  (typeof EXCLUDE_KEYS_NAVIGATION)[number]
>;

export type SearchResultsFilterKeys = BaseFilterStateKeys;

export const FilterToUrlParamMap: {
  [K in SearchResultsFilterKeys]: string;
} = {
  people: "people",
  tags: "tags",
  timecode_range: "timecode_range",
  similarImages: "similar_images",
} as const;

// Has every single possible filter key value pair as optional
export type BaseFilterState = Partial<
  Pick<MediaFilterType, MediaFilterKey> & {
    similarImages: string[];
  }
>;

export type FilterContextType = "Navigation" | "SearchResults";

export type NavigationFilterState = Required<
  Pick<BaseFilterState, NavigationFilterKeys>
>;

export type SearchResultsFilterState = Required<
  Pick<BaseFilterState, SearchResultsFilterKeys>
>;

export const EMPTY_FILTER_STATE: Required<BaseFilterState> = {
  timecode_range: null,
  people: [],
  tags: [],
  similarImages: [],
};

/**
 * Create a partial pick of the base filter state with the given exclude keys
 * @param excludeKeys The keys to exclude from the base filter state
 * @returns A partial pick of the base filter state with the given exclude keys
 */
function createFilteredState<T extends keyof BaseFilterState>(
  excludeKeys: ReadonlyArray<T>,
): Partial<Pick<BaseFilterState, T>> {
  return Object.fromEntries(
    (Object.keys(EMPTY_FILTER_STATE) as Array<T>)
      .filter((key) => !excludeKeys.includes(key))
      .map((key) => [key, EMPTY_FILTER_STATE[key]]),
  ) as Partial<Pick<BaseFilterState, T>>;
}

export const EMPTY_NAVIGATION_FILTER_STATE: NavigationFilterState =
  createFilteredState(EXCLUDE_KEYS_NAVIGATION) as NavigationFilterState;

export const EMPTY_SEARCH_RESULTS_FILTER_STATE: SearchResultsFilterState =
  createFilteredState([]) as SearchResultsFilterState;

export type FilterOption<T> = {
  id: string;
  value: T;
  label: string;
  icon?: React.ReactNode;
  absPath?: string;
  numResults?: number;
};

export type FilterOptions<T> = FilterOption<T>[];

export type FilterValueType<K extends keyof BaseFilterState> =
  BaseFilterState[K];

export type FilterKey = keyof BaseFilterState;

export type DateRange = [number, number]; // would be nice for this to come from backend

// These represent all the possible filter types
export type FilterCriteriaArray<T> = FilterOption<T>[];
export type FilterCriteriaNumber = FilterOption<number>;
export type FilterCriteriaString = FilterOption<string>;
export type FilterCriteriaBoolean = FilterOption<boolean>;
export type FilterCriteriaDateRange = FilterOption<{
  start: number;
  end: number;
}>;

export type FilterCriteriaFuzzyFilename = FuzzyFilenameParams & {
  icon?: React.ReactNode;
};

export type FilterCriteriaOpAtom = OpAtomFilter;

export type FilterCriteriaTagType = {
  [tagType in Tag["tag_type"]]: {
    tags: Tag[];
    icon?: React.ReactNode;
    label?: string; // for when we want to display a different label than the tag type
  };
};

// prettier-ignore
export type FilterCriteriaMapping = {
  [K in keyof BaseFilterState]: 
    K extends 'timecode_range' ? FilterCriteriaDateRange
    : K extends 'people' ? FilterCriteriaArray<string>
    : K extends 'tags' ? FilterCriteriaTagType
    : K extends 'similarImages' ? FilterCriteriaArray<string>
    : never;
};

export type FilterConditionType =
  | "equality"
  | "inclusion"
  | "exclusion"
  | "range"
  | "inSet"
  | "comparison";

export type Filter<K extends keyof FilterCriteriaMapping> = {
  label: string;
  criteria: FilterCriteriaMapping[K];
  filterConditionType: FilterConditionType;
  icon?: React.ReactNode;
  pluralName?: string;
  singularName?: string;
  hiddenFromUI?: boolean;
};

type FilterOptionsMapping = {
  [K in keyof Required<BaseFilterState>]: Filter<K>;
};

type RequiredBaseFilters = {
  [K in keyof Required<BaseFilterState>]: FilterOptionsMapping[K];
};

export type BaseFilters = Partial<RequiredBaseFilters>;

export type NavigationFilters = {
  [K in NavigationFilterKeys]: FilterOptionsMapping[K];
};

export type SearchResultsFilters = {
  [K in SearchResultsFilterKeys]: FilterOptionsMapping[K];
};

/**
 * Create a partial pick of the base filter options with the given exclude keys
 * @param excludeKeys The keys to exclude from the base filter options
 * @returns A partial pick of the base filter options with the given exclude keys
 */
export function createFilteredOptions<T extends keyof RequiredBaseFilters>(
  filterOptions: RequiredBaseFilters,
  excludeKeys: ReadonlyArray<T>,
): Pick<RequiredBaseFilters, Exclude<keyof RequiredBaseFilters, T>> {
  return Object.fromEntries(
    (Object.keys(filterOptions) as Array<keyof RequiredBaseFilters>)
      .filter((key) => !excludeKeys.includes(key as T))
      .map((key) => [key, filterOptions[key]]),
  ) as Pick<RequiredBaseFilters, Exclude<keyof RequiredBaseFilters, T>>;
}

export const EMPTY_BASE_FILTERS: RequiredBaseFilters = {
  similarImages: {
    label: "Visually Similar Frames",
    criteria: [],
    filterConditionType: "inclusion",
    pluralName: "visually similar frames",
    singularName: "visually similar frame",
    hiddenFromUI: true,
  },
  timecode_range: {
    label: "Timecode Range",
    criteria: {
      id: "",
      value: { start: 0, end: 0 },
      label: "",
    },
    filterConditionType: "range",
    icon: <ClockIcon className="h-4 w-4" />,
  },
  people: {
    label: "Faces",
    criteria: [],
    filterConditionType: "inclusion",
    icon: <FaceIcon className="h-4 w-4" />,
    pluralName: "faces",
    singularName: "face",
  },
  tags: {
    label: "Tags",
    criteria: {},
    filterConditionType: "inclusion",
    icon: <TagIcon className="h-4 w-4" />,
  },
};

export const EMPTY_SEARCH_RESULTS_FILTER_OPTIONS: SearchResultsFilters =
  createFilteredOptions(EMPTY_BASE_FILTERS, []);

export const EMPTY_NAVIGATION_FILTER_OPTIONS: NavigationFilters =
  createFilteredOptions(EMPTY_BASE_FILTERS, EXCLUDE_KEYS_NAVIGATION);

type SpecialTypeKeys = "timecode_range" | "tags";

type UnfilteredArrayFilterKeys = {
  [K in keyof BaseFilterState]: NonNullable<
    BaseFilterState[K]
  > extends Array<any>
    ? Exclude<K, SpecialTypeKeys> // Exclude specific keys manually
    : never;
}[keyof BaseFilterState];

export type ArrayFilterKeys = Exclude<
  UnfilteredArrayFilterKeys,
  SpecialTypeKeys | undefined
>;

type UnfilteredBooleanFilterKeys = {
  [K in keyof BaseFilterState]: NonNullable<BaseFilterState[K]> extends boolean
    ? K
    : never;
}[keyof BaseFilterState];

export type BooleanFilterKeys = Exclude<
  UnfilteredBooleanFilterKeys,
  SpecialTypeKeys | undefined
>;

type NumberFilterKeysUnfiltered = {
  [K in keyof BaseFilterState]: NonNullable<BaseFilterState[K]> extends number
    ? K
    : never;
}[keyof BaseFilterState];

export type NumberFilterKeys = Exclude<
  NumberFilterKeysUnfiltered,
  SpecialTypeKeys | undefined
>;

const getArrayFilterKeys = (sample: Partial<BaseFilterState>): string[] => {
  return Object.keys(sample).filter((key) =>
    Array.isArray(sample[key as keyof BaseFilterState]),
  );
};

export const arrayFilterKeys = getArrayFilterKeys(
  EMPTY_FILTER_STATE,
) as ArrayFilterKeys[];

export const booleanFilterKeys = Object.keys(EMPTY_FILTER_STATE).filter(
  (key) =>
    typeof EMPTY_FILTER_STATE[key as keyof BaseFilterState] === "boolean",
) as BooleanFilterKeys[];

export const numberFilterKeys = Object.keys(EMPTY_FILTER_STATE).filter(
  (key) => typeof EMPTY_FILTER_STATE[key as keyof BaseFilterState] === "number",
) as NumberFilterKeys[];

export type ArrayFilter = ArrayFilterKeys extends infer K
  ? K extends BaseFilterStateKeys
    ? Filter<K>
    : never
  : never;

export type BooleanFilter = BooleanFilterKeys extends infer K
  ? K extends BaseFilterStateKeys
    ? Filter<K>
    : never
  : never;

export type NumberFilter = NumberFilterKeys extends infer K
  ? K extends BaseFilterStateKeys
    ? Filter<K>
    : never
  : never;
